import DashboardPageLayout from "../../components/layout/DashboardPageLayout";
import { Usuario } from "../../model/entities/Usuario";
import HomePage from "../../pages/home/HomePage";

import ListagemServicoPage from "../../pages/servico/ListagemServicoPage";
import NovoServicoPage from "../../pages/servico/NovoServicoPage";
import { RouteType } from "../config";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const grupoServico: RouteType = {
  path: "/servico",
  element: <DashboardPageLayout />,
  state: "servico",
  sidebarProps: {
    displayText: "Serviços",
    icon: <ManageAccountsIcon />,
  },

  child: [
    {
      index: true,
      element: <HomePage />,
      state: "servico.index",
    },
    {
      path: "/servico/cadastro",
      element: <NovoServicoPage />,
      state: "servico.cadastro",
      verificarPermissao: (usuario: Usuario | null) => {
        return usuario ? usuario.perfil.cadastraServico : false;
      },
      sidebarProps: {
        displayText: "Cadastrar serviço",
      },
    },

    {
      path: "/servico/listagem",
      element: <ListagemServicoPage />,
      state: "servico.listagem",
      sidebarProps: {
        displayText: "Lista de serviços",
      },
    },
  ],
  //   {
  //     path: "/encaminhamentos/cadastro",
  //     element: <NovoEncaminhamentoPage />,
  //     state: "encaminhamentos.cadastro",
  //     sidebarProps: {
  //       displayText: "Novo Encaminhamento"
  //     }
  //   },

  //   {
  //     path: "/encaminhamentos/:id",
  //     element: <ConsultaPage />,
  //     state: "encaminhamentos.id"
  //   }
  // ]
};

export default grupoServico;
