export const drawerWidth = 300;
export const TIPOS_ENDERECOS = [
  { codigo: 0, descricao: "Endereço de cobrança" },
  { codigo: 1, descricao: "Endereço de obra" },
  { codigo: 2, descricao: "Endereço residencial" },
];

export const LISTA_SEXOS = [
  { decricao: "Masculino" },
  { decricao: "Feminino" },
  { decricao: "Outro" },
];

export const STATUS_ORCAMENTO_EM_ANALISE = { id: 0, decricao: "Em analise" };
export const STATUS_ORCAMENTO_NAO_APROVADO = {
  id: 1,
  decricao: "Não aprovado",
};
export const STATUS_ORCAMENTO_PENDENTE = { id: 2, decricao: "Pendente" };
export const STATUS_ORCAMENTO_CONCUIDO = { id: 3, decricao: "Aprovado" };

export const STATUS_ORCAMENTO = [
  STATUS_ORCAMENTO_EM_ANALISE,
  STATUS_ORCAMENTO_NAO_APROVADO,
  STATUS_ORCAMENTO_PENDENTE,
  STATUS_ORCAMENTO_CONCUIDO,
];

export const STATUS_SERVICO_OS_PENDENTE = { id: 0, decricao: "Pendente" };
export const STATUS_SERVICO_OS_EM_ANDAMENTO = {
  id: 1,
  decricao: "Em adamento",
};
export const STATUS_SERVICO_OS_CONCLUIDO = { id: 2, decricao: "Concluído" };

export const STATUS_SERVICO_OS = [
  STATUS_SERVICO_OS_PENDENTE,
  STATUS_SERVICO_OS_EM_ANDAMENTO,
  STATUS_SERVICO_OS_CONCLUIDO,
];

export const ACESSO_ORCAMENTO_VINCULADO = {
  id: 0,
  decricao: "VINCULADO",
  descricaoEnum: "ORCAMENTO_PROPIO",
};

export const ACESSO_ORCAMENTO_TODOS = {
  id: 1,
  decricao: "Todos",
  descricaoEnum: "ORCAMENTO_GERAL",
};

export const ACESSO_ORCAMENTO = [
  ACESSO_ORCAMENTO_VINCULADO,
  ACESSO_ORCAMENTO_TODOS,
];

export const ACESSO_OS_VINCULADA = {
  id: 0,
  decricao: "VINCULADO",
  descricaoEnum: "OS_PROPIA",
};

export const ACESSO_OS_TODAS = {
  id: 1,
  decricao: "Todos",
  descricaoEnum: "OS_GERAL",
};

export const ACESSO_OS = [ACESSO_OS_VINCULADA, ACESSO_OS_TODAS];

export const TIPO_LANCAMENTO_CAIXA_DEBITO = 0;
export const TIPO_LANCAMENTO_CAIXA_CREDITO = 1;

export const LISTA_TIPO_TRANSACAO = [
  { codigo: TIPO_LANCAMENTO_CAIXA_DEBITO, descricao: "Débito" },
  { codigo: TIPO_LANCAMENTO_CAIXA_CREDITO, descricao: "Crédito" },
];

export const DIA_DA_SEMANA_DOMINGO = "0";
export const DIA_DA_SEMANA_SEGUNDA = "1";
export const DIA_DA_SEMANA_TERCA = "2";
export const DIA_DA_SEMANA_QUARTA = "3";
export const DIA_DA_SEMANA_QUINTA = "4";
export const DIA_DA_SEMANA_SEXTA = "5";
export const DIA_DA_SEMANA_SABADO = "6";

export const PAGAMENTO_EM_ABERTO = 0;
export const PAGAMENTO_PARCIAL = 1;
export const PAGAMENTO_TOTAL = 2;

export const STATUS_PAGAMENTO = [
  {
    codigo: PAGAMENTO_EM_ABERTO,
    descricao: "Em aberto",
    descricaoEnum: "PAGAMENTO_EM_ABERTO",
    cor: "#2196f3",
  },
  {
    codigo: PAGAMENTO_PARCIAL,
    descricao: "Pago parcial",
    descricaoEnum: "PAGAMENTO_PARCIAL",
    cor: "#fdd835",
  },
  {
    codigo: PAGAMENTO_TOTAL,
    descricao: "Pago",
    descricaoEnum: "PAGAMENTO_TOTAL",
    cor: "#4caf50",
  },
];

export const LISTA_HORAS = [
  { hora: "00:00" },
  { hora: "00:15" },
  { hora: "00:30" },
  { hora: "00:45" },
  { hora: "01:00" },
  { hora: "01:15" },
  { hora: "01:30" },
  { hora: "01:45" },
  { hora: "02:00" },
  { hora: "02:15" },
  { hora: "02:30" },
  { hora: "02:45" },
  { hora: "03:00" },
  { hora: "03:15" },
  { hora: "03:30" },
  { hora: "03:45" },
  { hora: "04:00" },
  { hora: "04:15" },
  { hora: "04:30" },
  { hora: "04:45" },
  { hora: "05:00" },
  { hora: "05:15" },
  { hora: "05:30" },
  { hora: "05:45" },
  { hora: "06:00" },
  { hora: "06:15" },
  { hora: "06:30" },
  { hora: "06:45" },
  { hora: "07:00" },
  { hora: "07:15" },
  { hora: "07:30" },
  { hora: "07:45" },
  { hora: "08:00" },
  { hora: "08:15" },
  { hora: "08:30" },
  { hora: "08:45" },
  { hora: "09:00" },
  { hora: "09:15" },
  { hora: "09:30" },
  { hora: "09:45" },
  { hora: "10:00" },
  { hora: "10:15" },
  { hora: "10:30" },
  { hora: "10:45" },
  { hora: "11:00" },
  { hora: "11:15" },
  { hora: "11:30" },
  { hora: "11:45" },
  { hora: "12:00" },
  { hora: "12:15" },
  { hora: "12:30" },
  { hora: "12:45" },
  { hora: "13:00" },
  { hora: "13:15" },
  { hora: "13:30" },
  { hora: "13:45" },
  { hora: "14:00" },
  { hora: "14:15" },
  { hora: "14:30" },
  { hora: "14:45" },
  { hora: "15:00" },
  { hora: "15:15" },
  { hora: "15:30" },
  { hora: "15:45" },
  { hora: "16:00" },
  { hora: "16:15" },
  { hora: "16:30" },
  { hora: "16:45" },
  { hora: "17:00" },
  { hora: "17:15" },
  { hora: "17:30" },
  { hora: "17:45" },
  { hora: "18:00" },
  { hora: "18:15" },
  { hora: "18:30" },
  { hora: "18:45" },
  { hora: "19:00" },
  { hora: "19:15" },
  { hora: "19:30" },
  { hora: "19:45" },
  { hora: "20:00" },
  { hora: "20:15" },
  { hora: "20:30" },
  { hora: "20:45" },
  { hora: "21:00" },
  { hora: "21:15" },
  { hora: "21:30" },
  { hora: "21:45" },
  { hora: "22:00" },
  { hora: "22:15" },
  { hora: "22:30" },
  { hora: "22:45" },
  { hora: "23:00" },
  { hora: "23:15" },
  { hora: "23:30" },
  { hora: "23:45" },
];

export const PRIORIDADE_AGENDAMENTO_BAIXA = 0;
export const PRIORIDADE_AGENDAMENTO_MEDIA = 1;
export const PRIORIDADE_AGENDAMENTO_ALTA = 2;

export const LISTA_PRIORIDADE_AGENDAMENTO = [
  { codigo: PRIORIDADE_AGENDAMENTO_BAIXA, descricao: "Baixa" },
  { codigo: PRIORIDADE_AGENDAMENTO_MEDIA, descricao: "Média" },
  { codigo: PRIORIDADE_AGENDAMENTO_ALTA, descricao: "Alta" },
];
