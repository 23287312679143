import { Button } from "@material-ui/core";

type Props = {
  label: string;
  loading?: boolean;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  variant?: "text" | "outlined" | "contained";
  style?: React.CSSProperties;
  type?: "button" | "reset" | "submit";
  ref?: (instance: HTMLButtonElement | null) => void;
};

const ButtonGeneric: React.FC<Props> = (props) => {
  const {
    label,
    loading,
    onClick,
    endIcon,
    variant,
    style,
    startIcon,
    type,
    ref,
  } = props;

  return (
    <>
      <Button
        variant={variant ?? "contained"}
        color={"primary"}
        onClick={(e) => onClick(e)}
        endIcon={endIcon}
        style={style}
        startIcon={startIcon}
        type={type ?? "button"}
        ref={ref}
      >
        {label}
      </Button>
    </>
  );
};

export default ButtonGeneric;
