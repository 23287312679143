import axios from "axios";
import store from "../redux/store";
import { logoutAction } from "../redux/sessao/sessaoActions";
import { analytics } from "../firebaseConfig";

const api = axios.create({ timeout: 150000 });

api.interceptors.request.use(async (config) => {
  const state = store.getState();

  const currentURL = window.location.href;
  const urlPartida = currentURL
    .split(".projete.app")[0]
    .replace("https://", "");

  config.baseURL = `https://api.projete.app.br/${urlPartida.replace(
    "https://",
    ""
  )}`;

  console.log("url partida", urlPartida.replace("https://", ""));
  if (urlPartida.includes("localhost")) {
    config.baseURL = "http://localhost:2500";
  }

  const token = state.sessao.tokenJWT;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers["X-User-Id"] = state.sessao.usuario?.id;
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log("yyy", api.request);
    analytics.logEvent("api-exception", { description: error, fatal: true });
    if (
      error &&
      error.response.data === "Usuário inexistente ou senha inválida"
    ) {
      return Promise.reject(new Error(error.response.data));
    } else if (error.response.data === "Token JWT invalido ou expirado!") {
      console.log("aqui estou");
      // mostrarSnack("Ops); O seu login expirou !!");
      console.log("unauthorized, logging out ...");
      const action = logoutAction();
      store.dispatch(action);

      return Promise.reject(
        new Error("Seu login expirou. Por favor, faça login novamente.")
      );
    }
    console.log("yyyy error", error.response);

    if (!error.response) {
      console.log("ERRO!!! Não foi possível conectar com servidor.");

      return Promise.reject(
        new Error("Não foi possível estabelecer a conexão com o servidor.")
      );
    }

    if (!error.response.data) {
      return Promise.reject(
        new Error("Não foi possível estabelecer a conexão com o servidor.")
      );
    }

    return Promise.reject(error.response);
  }
);

export default api;
