import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  AppBar,
  Toolbar,
  Link,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@material-ui/core";
import Copyright from "../../components/layout/Copyrigth";
import { useSnack } from "../../redux/snack/snackHooks";
import portifolioApi from "../../api/portifolioApi";
import { DadosPortifolio } from "../../model/entities/DadosPortifolio";
import Logo from "../../assets/Projete.jpeg";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#f4f4f4",
  },
  header: {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
    padding: theme.spacing(2),
  },
  navLinks: {
    listStyle: "none",
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },
  navLinkItem: {
    margin: theme.spacing(0, 2),
  },
  section: {
    padding: theme.spacing(4),
    maxWidth: 1200,
    margin: "auto",
    textAlign: "center",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 8,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    margin: theme.spacing(2, 0),
    padding: theme.spacing(3),
    textAlign: "center",
  },
  cardImage: {
    maxWidth: "100%",
    borderRadius: 8,
  },
  contactForm: {
    width: "100%",
    "& > *": {
      margin: theme.spacing(1, 0),
    },
  },
  footer: {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
    padding: theme.spacing(2),
    marginTop: "auto",
  },
}));

const Portfolio: React.FC = () => {
  const classes = useStyles();

  const mostrarSnack = useSnack();
  const [loading, setLoading] = useState(false);

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [telefone, setTelefone] = useState("");

  const [dadosPortifolio, setDadosPortifolio] = useState<DadosPortifolio>(
    new DadosPortifolio()
  );

  useEffect(() => {
    getDados();
  }, []);

  const getDados = async () => {
    try {
      const res = await portifolioApi.get();
      if (res) setDadosPortifolio(res);
    } catch (erro: any) {
      mostrarSnack(
        "Não foi possível encontrar os dados do portfólio ",
        "error"
      );
      console.log("Não foi possível encontrar os dados do portifolio", erro);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <nav>
            <ul className={classes.navLinks}>
              <li className={classes.navLinkItem}>
                <Link href="#sobre" color="inherit">
                  Sobre Mim
                </Link>
              </li>
              <li className={classes.navLinkItem}>
                <Link href="#projetos" color="inherit">
                  Projetos
                </Link>
              </li>
              <li className={classes.navLinkItem}>
                <Link href="#contato" color="inherit">
                  Contato
                </Link>
              </li>
            </ul>
          </nav>
        </Toolbar>
      </AppBar>

      <Container id="sobre" className={classes.section}>
        <Typography variant="h3" gutterBottom>
          Sobre Mim
        </Typography>

        <Card className={classes.card}>
          <CardMedia
            component="img"
            alt="Foto de Perfil"
            image={"https://via.placeholder.com/600x400"}
            className={classes.cardImage}
          />
          <CardContent>
            <Typography variant="h2">
              {dadosPortifolio.nome ? dadosPortifolio.nome : "[Seu Nome]"}
            </Typography>

            {dadosPortifolio.fraseApresentação ? (
              <Typography variant="body1">
                {dadosPortifolio.fraseApresentação}
              </Typography>
            ) : (
              <Typography variant="body1">
                Sou um profissional apaixonado por{" "}
                {dadosPortifolio.areaAtuacao
                  ? dadosPortifolio.areaAtuacao
                  : "[Sua Área de Atuação]"}
                , com experiência em{" "}
                {dadosPortifolio.areaEspecializacao
                  ? dadosPortifolio.areaEspecializacao
                  : "[Áreas de Especialização]"}
                . Meu objetivo é criar soluções inovadoras e impactantes para
                empresas e clientes.
              </Typography>
            )}
          </CardContent>

          <CardContent>
            <Typography variant="body1">
              {dadosPortifolio.contato && (
                <div style={{ textAlign: "center", display: "flex" }}>
                  <WhatsAppIcon style={{ marginRight: "5px" }} />
                  {dadosPortifolio.contato}
                </div>
              )}

              {dadosPortifolio.email && (
                <div style={{ textAlign: "center", display: "flex" }}>
                  <EmailIcon style={{ marginRight: "5px" }} />
                  {dadosPortifolio.email}
                </div>
              )}
            </Typography>
          </CardContent>
        </Card>
      </Container>

      <Container id="projetos" className={classes.section}>
        <Typography variant="h1" gutterBottom>
          Projetos
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h2">Projeto 1</Typography>
                <CardMedia
                  component="img"
                  alt="Projeto 1"
                  image="https://via.placeholder.com/600x400"
                  className={classes.cardImage}
                />
                <Typography variant="body1">
                  Descrição breve do Projeto 1. Inclua detalhes sobre as
                  tecnologias utilizadas e os resultados alcançados.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h2">Projeto 2</Typography>
                <CardMedia
                  component="img"
                  alt="Projeto 2"
                  image="https://via.placeholder.com/600x400"
                  className={classes.cardImage}
                />
                <Typography variant="body1">
                  Descrição breve do Projeto 2. Inclua detalhes sobre as
                  tecnologias utilizadas e os resultados alcançados.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Adicione mais projetos conforme necessário */}
        </Grid>
      </Container>

      <Container id="contato" className={classes.section}>
        <Card className={`${classes.card} ${classes.contactForm}`}>
          <Grid item xs={12}>
            <CardContent>
              <Typography variant="h2">Entre em Contato</Typography>
              <form
                action="mailto:seuemail@exemplo.com"
                method="post"
                encType="text/plain"
              >
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <TextFieldGeneric
                      type="text"
                      placeholder="Seu Nome"
                      fullWidth
                      label={"Seu Nome"}
                      value={nome}
                      variant="standard"
                      onChange={(value) => {
                        setNome(value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldGeneric
                      type="text"
                      placeholder="Seu Telefone"
                      mask="(99) 999999999"
                      fullWidth
                      label={"Telefone"}
                      value={telefone}
                      variant="standard"
                      onChange={(value) => {
                        setTelefone(value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldGeneric
                      type="email"
                      placeholder="Seu Email"
                      fullWidth
                      label={"Seu Email"}
                      value={email}
                      variant="standard"
                      onChange={(value) => {
                        setEmail(value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldGeneric
                      placeholder="Sua Mensagem"
                      fullWidth
                      label={"Sua Mensagem"}
                      value={mensagem}
                      variant="standard"
                      minRows={5}
                      onChange={(value) => {
                        setMensagem(value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                      Enviar Mensagem
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Grid>
        </Card>
      </Container>

      <footer className={classes.footer}>
        <Copyright textColor={"#fb6f24"} />
      </footer>
    </div>
  );
};

export default Portfolio;
