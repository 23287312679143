import { createReducer } from "@reduxjs/toolkit";
import { hideSnackAction, showSnackAction } from "./snackActions";
import { SnackState } from "./snackTypes";

export const initialState: SnackState = {
  mensagem: "",
  tipo: "info",
  show: false,
};

const snackReducer = createReducer(initialState, {
  [showSnackAction.toString()]: (state, action) => {
    if (showSnackAction.match(action)) {
      return { ...state, ...action.payload };
    }
    return { ...state };
  },
  [hideSnackAction.toString()]: (state, action) => {
    return { ...state, show: false };
  },
});

export default snackReducer;
