import { DadosPortifolio } from "../model/entities/DadosPortifolio";
import api from "./api";

const ROUTE = "portifolio";

class portifolioApi {
  async insert(portifolio: DadosPortifolio) {
    try {
      const res = await api.post<DadosPortifolio>(`/${ROUTE}`, portifolio);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async get() {
    try {
      const res = await api.get<DadosPortifolio>(`/${ROUTE}`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(portifolio: DadosPortifolio) {
    try {
      const res = await api.put<DadosPortifolio>(`/${ROUTE}`, portifolio);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new portifolioApi();
