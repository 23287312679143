import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, useTheme } from "@mui/material";
import { LISTA_HORAS } from "../../constants";

type Props = {
  setHora: (p: string) => void;
  hora: string;
  label?: string;
};

const HorasField: React.FC<Props> = (props) => {
  const { setHora, hora, label } = props;

  const theme = useTheme();

  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  const [horasObj, setHorasObj] = useState<(typeof LISTA_HORAS)[0]>(
    LISTA_HORAS[0]
  );

  useEffect(() => {
    console.log("hora", hora);
    const prioridadeEncontrada = LISTA_HORAS.find((item) => item.hora === hora);
    if (prioridadeEncontrada) {
      setHora(prioridadeEncontrada.hora);
      setHorasObj(prioridadeEncontrada);
    }
  }, [hora]);

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-horas"
        options={LISTA_HORAS}
        value={horasObj}
        getOptionLabel={(option) => option.hora}
        onChange={(event, value) => {
          if (value) setHora(value.hora);
        }}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={label ? label : "Horas"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default HorasField;
