import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCYYd2F_J0BGMBpo8d26UcTTxH70nZYAYE",
  authDomain: "sispro-fccee.firebaseapp.com",
  projectId: "sispro-fccee",
  storageBucket: "sispro-fccee.appspot.com",
  messagingSenderId: "709956942959",
  appId: "1:709956942959:web:b7a08bef465204aa33219a",
  measurementId: "G-J7Z4W8EFPN",
};
// Inicialize o Firebase
let app;
if (!firebase.apps.length) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app(); // Se já estiver inicializado, use a instância existente
}

// Inicialize o Firebase Analytics separadamente
const analytics = firebase.analytics();
// const messaging = getMessaging(app);

export { analytics };
