import { Usuario } from "../model/entities/Usuario";
import { ResponseGenericType } from "../model/types/ResponseType";
import {
  DadosCadastrarUsuario,
  DadosUsuario,
} from "../model/types/UsuarioTypes";
import api from "./api";

const ROUTE = "usuario";

class usuarioApi {
  async getMany(pagina: number, linhasPorPagina: number) {
    try {
      const res = await api.get<ResponseGenericType<Usuario[]>>(
        `/${ROUTE}?size=${linhasPorPagina}&page=${pagina}`
      );
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getManyComplete() {
    try {
      const res = await api.get<Usuario[]>(`/${ROUTE}/complete`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(usuario: DadosCadastrarUsuario) {
    try {
      const res = await api.post<Usuario>(`/${ROUTE}`, usuario);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(idUsuario: number, usuario: DadosUsuario) {
    try {
      const res = await api.put<Usuario>(`/${ROUTE}/${idUsuario}`, usuario);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new usuarioApi();
