import * as React from "react";
import TextField from "@mui/material/TextField";

import { Autocomplete, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FormaPagamento } from "../../model/entities/FormaPagamento";
import formaPagamentoApi from "../../api/formaPagamentoApi";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  setFormaPagamento: (p: FormaPagamento) => void;
  formaPagamento: FormaPagamento | null;
  idFormaPagamento?: number;
  disabled?: boolean;
};

const FormaPagamentoField: React.FC<Props> = (props) => {
  const { setFormaPagamento, idFormaPagamento, disabled } = props;

  const [formaPagamentoSelecionada, setFormaPagamentoSelecionada] =
    useState<FormaPagamento | null>(null);
  const [formasPagamento, setFormasPagamento] = useState<FormaPagamento[]>([]);
  const mostrarSnack = useSnack();

  useEffect(() => {
    buscarFormasPagamento();
  }, []);

  const buscarFormasPagamento = async () => {
    try {
      const res = await formaPagamentoApi.getManyComplete();
      setFormasPagamento(res);

      if (idFormaPagamento) {
        const frmPG = res.find((item) => item.id === idFormaPagamento);
        console.log("yyyy idFormaPagamento", idFormaPagamento);
        if (frmPG) {
          setFormaPagamentoSelecionada(frmPG);
          setFormaPagamento(frmPG);
        }
      }
    } catch (erro: any) {
      mostrarSnack("Erro ao buscar lista de formasPagamento", "error");
      console.log("erro ao buscar formasPagamento", erro);
    }
  };
  const theme = useTheme();

  // Defina o valor de xs com base nos breakpoints do tema
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;
  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        disabled={disabled}
        id="filter-tipo-endereco"
        options={formasPagamento}
        getOptionLabel={(option) => `${option.descricao}`}
        onChange={(event, value) => {
          if (value) {
            setFormaPagamento(value);
            setFormaPagamentoSelecionada(value);
          } else {
            setFormaPagamentoSelecionada(null);
          }
        }}
        value={formaPagamentoSelecionada}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={"Formas de pagamento"}
            placeholder={"FormaPagamento"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default FormaPagamentoField;
