import * as React from "react";
import TextField from "@mui/material/TextField";

import { Autocomplete, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Servico } from "../../model/entities/Servico";
import servicoApi from "../../api/servicoApi";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  setServico: (p: Servico | null) => void;
  idServico: number;
  disabled?: boolean;
};

const ServicoField: React.FC<Props> = (props) => {
  const { setServico, idServico, disabled } = props;

  const [servicoSelecionado, setServicoSelecionado] = useState<Servico | null>(
    null
  );
  const [servicos, setServicos] = useState<Servico[]>([]);
  const mostrarSnack = useSnack();

  useEffect(() => {
    buscaClientes();
  }, []);

  const buscaClientes = async () => {
    try {
      const res = await servicoApi.getManyComplete();
      setServicos(res);

      const servico = res.filter((item) => item.id === idServico)[0];
      if (servico) {
        setServicoSelecionado(servico);
        setServico(servico);
      }
    } catch (erro: any) {
      mostrarSnack("Erro ao buscar lista de servicos", "error");
      console.log("erro ao buscar servicos", erro);
    }
  };

  const theme = useTheme();

  // Defina o valor de xs com base nos breakpoints do tema
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-tipo-servios"
        options={servicos}
        getOptionLabel={(option) => option.titulo}
        onChange={(event, value) => {
          if (value) {
            setServico(value);
            setServicoSelecionado(value);
          } else {
            setServico(null);
            setServicoSelecionado(null);
          }
        }}
        value={servicoSelecionado}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={disabled}
            variant={"outlined"}
            label={"Serviço"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default ServicoField;
