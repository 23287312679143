import * as React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { CategoriaFinanceiro } from "../../model/entities/CategoriaFinanceiro";
import categoriaFinanceiroApi from "../../api/categoriaFinanceiroApi";
import IconRenderer from "../Generics/IconRenderer";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  setCategoria: (p: CategoriaFinanceiro | null) => void;
  categoria: CategoriaFinanceiro | null;
  label?: string;
  idCategoria?: number;
};

const CategoriaFinanceiroField: React.FC<Props> = (props) => {
  const { setCategoria, label, idCategoria } = props;

  const mostrarSnack = useSnack();
  const [categoriaSelecionada, setCategoriaSelecionada] =
    useState<CategoriaFinanceiro | null>(null);
  const [categorias, setCategorias] = useState<CategoriaFinanceiro[]>([]);

  useEffect(() => {
    buscarCategorias();
  }, []);

  const buscarCategorias = async () => {
    try {
      const res = await categoriaFinanceiroApi.getManyComplete();
      setCategorias(res);

      if (idCategoria) {
        const categoriaEncontrada = res.find((item) => item.id === idCategoria);
        if (categoriaEncontrada) {
          setCategoria(categoriaEncontrada);
          setCategoriaSelecionada(categoriaEncontrada);
        }
      }
    } catch (erro: any) {
      mostrarSnack("Erro ao buscar categorias do financeiro", "error");
      console.log("Erro ao buscar categorias do financeiro", erro);
    }
  };
  const theme = useTheme();

  // Defina o valor de xs com base nos breakpoints do tema
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-categoria"
        options={categorias}
        getOptionLabel={(option) => option.nome}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            style={{
              margin: 8,
              padding: 5,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconRenderer iconName={option.icone} />
            <span style={{ marginLeft: 8 }}>{option.nome}</span>
          </Box>
        )}
        onChange={(event, value) => {
          setCategoriaSelecionada(value);
          setCategoria(value);
        }}
        value={categoriaSelecionada}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={
              label
                ? label
                : categoriaSelecionada
                ? "Categoria transação "
                : "Selecione a categoria a qual a transação pertence"
            }
            placeholder="Selecione a categoria a qual a transação pertence"
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default CategoriaFinanceiroField;
