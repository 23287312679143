import { useState, useEffect } from "react";
import { Grid, Paper, Tooltip, Typography, useMediaQuery } from "@mui/material";

import servicoOsApi from "../../api/servicoOsApi";
import {
  DadosServicoOS,
  FiltroBuscaServicoByUsuario,
} from "../../model/types/ServicoOSTypes";
import GridCenter from "./GridCenter";
import Titulo2 from "./Titulo2";
import dayjs from "dayjs";
import { PersonPin } from "@mui/icons-material";
import { createStyles, makeStyles } from "@material-ui/core";
import theme, { colors } from "../../theme";
import { useSnack } from "../../redux/snack/snackHooks";
import Container from "./Container";

type PropsStyles = {
  scroollColor: string;
};

const ServicosSemana = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const mostrarSnack = useSnack();
  const [servicos, setServicos] = useState<DadosServicoOS[]>([]);
  const classes = useStyles({ scroollColor: colors.scroll });

  const [filtros] = useState<FiltroBuscaServicoByUsuario>(
    new FiltroBuscaServicoByUsuario()
  );

  useEffect(() => {
    buscarServicos();
  }, []);

  const dataAtual = dayjs();
  const diaDaSemana = dataAtual.day();
  const inicioDaSemana = dataAtual.subtract(diaDaSemana, "day");
  const finalDaSemana = inicioDaSemana.add(6, "day");

  const buscarServicos = async () => {
    try {
      const res = await servicoOsApi.getServicos({
        ...filtros,
        dataInicioEntegra: inicioDaSemana.toDate(),
        dataFimEntegra: finalDaSemana.toDate(),
        apenasNaoTerminados: true,
      });
      setServicos(res);
    } catch (erro: any) {
      console.log("erro ao buscar servicos", erro);
      mostrarSnack("Não foi possível buscar os servicos da semana", "error");
    }
  };

  function formatDateRange(startDate: any, endDate: any) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    const formattedStartDate = start.format("dddd DD/MM");
    const formattedEndDate = end.format("dddd DD/MM");

    return `${
      formattedStartDate.charAt(0).toUpperCase() + formattedStartDate.slice(1)
    } a   ${
      formattedEndDate.charAt(0).toUpperCase() + formattedEndDate.slice(1)
    }`;
  }

  const formatarDia = (data: Date) => {
    return (
      dayjs(data).format("dddd").charAt(0).toUpperCase() +
      dayjs(data).format("dddd").slice(1)
    );
  };

  return (
    <Container page>
      <Grid item xs={12} md={12}>
        <Titulo2>Serviços para esta semana</Titulo2>
      </Grid>

      <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
        <Titulo2>{formatDateRange(inicioDaSemana, finalDaSemana)}</Titulo2>
      </Grid>

      {servicos.length === 0 && (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo2>Não há serviços para serem entregue esta semana</Titulo2>
        </Grid>
      )}

      <Grid item xs={12}>
        <div
          style={{ maxHeight: "500px", overflowY: "auto" }}
          className={classes.scroll}
        >
          {servicos.map((servico) => (
            <GridCenter>
              <Paper
                style={{
                  borderRadius: "20px",
                  margin: "12px 0px",
                  padding: "12px",
                  width: "95%",
                }}
                key={servico.idOsServico + servico.idServico}
              >
                <Grid container spacing={1}>
                  <Grid item md={4} xs={12} style={{ textAlign: "left" }}>
                    <Tooltip title={"Data prevista para entrega"}>
                      <Typography
                        fontWeight={"bold"}
                        color={
                          dayjs(servico.dataEntrega).isBefore(dayjs())
                            ? "red"
                            : ""
                        }
                      >
                        {formatarDia(servico.dataEntrega)}
                      </Typography>
                    </Tooltip>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    style={{ textAlign: isSmallScreen ? "left" : "center" }}
                  >
                    <Tooltip title={"Data prevista para entrega"}>
                      <Typography fontWeight={"bold"}>
                        {dayjs(servico.dataEntrega).format("DD/MM/YYYY")}
                      </Typography>
                    </Tooltip>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    style={{ textAlign: isSmallScreen ? "left" : "right" }}
                  >
                    <Typography fontWeight={"bold"}>
                      {servico.nomeServico}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography fontWeight={"bold"} display="flex">
                      <PersonPin />
                      {servico.nomeResponsavel}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </GridCenter>
          ))}
        </div>
      </Grid>
    </Container>
  );
};

export default ServicosSemana;

const useStyles = makeStyles(() =>
  createStyles({
    scroll: (props: PropsStyles) => ({
      flex: 1,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
      },
      "&:hover": {
        "&::-webkit-scrollbar-track": {
          background: "white",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#0a4b75",
          borderRadius: "100px",
        },
      },
    }),
  })
);
