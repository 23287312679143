import { ToastOptions } from "react-toastify";
import { showSnackAction } from "./snackActions";
import { useDispatch } from "react-redux";

export const useSnack = () => {
  const dispatch = useDispatch();
  const mostrarSnack = (
    mensagem: string,
    tipo: "error" | "success" | "info",
    options?: ToastOptions<{}>
  ) => {
    const action = showSnackAction({
      mensagem,
      tipo,
      show: true,
      options: options,
    });
    dispatch(action);
  };
  return mostrarSnack;
};
