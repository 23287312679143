import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ModalGeneric from "../../../components/Generics/ModalGeneric";
import dayjs from "dayjs";
import DatePickerGeneric from "../../../components/Generics/DatePickerGeneric";
import TextFieldGeneric from "../../../components/Generics/TextFieldGeneric";
import Titulo2 from "../../../components/Generics/Titulo2";
import NumberFieldGeneric from "../../../components/Generics/NumberFieldGeneric";
import ButtonGeneric from "../../../components/Generics/ButtonGeneric";
import { Transacao } from "../../../model/entities/Transacao";
import DialogConfirmGeneric from "../../../components/Generics/DialogConfirmGeneric";
import transacaoApi from "../../../api/transacaoApi";
import {
  TIPO_LANCAMENTO_CAIXA_CREDITO,
  TIPO_LANCAMENTO_CAIXA_DEBITO,
} from "../../../constants";
import ContaCaixaField from "../../../components/fields/ContaCaixaField";
import CategoriaFinanceiroField from "../../../components/fields/CategoriaFinanceiroField";
import { CategoriaFinanceiro } from "../../../model/entities/CategoriaFinanceiro";
import { ContaCaixa } from "../../../model/entities/ContaCaixa";
import { useSnack } from "../../../redux/snack/snackHooks";

type Props = {
  open: boolean;
  onClose: () => void;
  transacao: Transacao;
};

const ModalEditarTransacao: React.FC<Props> = (props) => {
  const { open, onClose, transacao } = props;
  const mostrarSnack = useSnack();

  const [valor, setValor] = useState(transacao.valor);
  const [descricao, setDescricao] = useState(transacao.titulo);
  const [dataEmissao, setDataEmissao] = useState(dayjs(transacao.dataEmissao));
  const [contaCaixa, setContaCaixa] = useState<ContaCaixa | null>(null);
  const [categoria, setCategoria] = useState<CategoriaFinanceiro | null>(null);
  const [dataVencimento, setDataVencimento] = useState(
    dayjs(transacao.dataVencimento)
  );
  const [openAlert, setOpenAlert] = useState(false);

  const salvar = async () => {
    try {
      if (valor === 0 || !valor) {
        mostrarSnack("Por favor informe o valor.", "info");
        return;
      }

      if (transacao.idTransacaoRecorrente) {
        setOpenAlert(true);
      } else {
        atualizar(false);
      }
    } catch (erro: any) {
      mostrarSnack("Erro ao cadastrar transacao", "error");
      console.log("Erro ao cadastrar transacao", erro);
    }
  };

  const atualizar = async (atualizarRecorrentes: boolean) => {
    try {
      if (!categoria || !categoria.id) {
        mostrarSnack("Informe a categoria da transação", "info");
        return;
      }

      await transacaoApi.update(transacao.id, {
        atualizarVarias: atualizarRecorrentes,
        contaCaixaId: 1,
        dataEmissao: dataEmissao.toDate(),
        dataVencimento: dataVencimento.toDate(),
        idTransacaoRecorrente: transacao.idTransacaoRecorrente ?? null,
        pago: transacao.pago,
        tipoTransacao:
          transacao.tipoTransacao === TIPO_LANCAMENTO_CAIXA_CREDITO
            ? TIPO_LANCAMENTO_CAIXA_CREDITO
            : TIPO_LANCAMENTO_CAIXA_DEBITO,
        titulo: descricao,
        valor: valor,
        categoriaID: categoria.id,
        formaPagamentoId: transacao.formaPagamento.id ?? 0,
      });
      mostrarSnack(
        `${
          atualizarRecorrentes
            ? "Transações atualiadas"
            : "Transação atualizada"
        }
        `,
        "success"
      );
      onClose();
    } catch (erro: any) {
      mostrarSnack(
        `Erro ao atualizar ${
          atualizarRecorrentes ? "transações recorrentes" : "transação"
        }`,
        "error"
      );
      console.error("Erro ao atualizar transações recorrentes", erro);
    }
  };
  return (
    <ModalGeneric open={open} onClose={onClose}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo2>Editar transacao {transacao.titulo}</Titulo2>
        </Grid>

        <Grid item xs={6}>
          <DatePickerGeneric
            label="Data emissão"
            value={dataEmissao}
            setValue={(value) => setDataEmissao(value)}
          />
        </Grid>

        <Grid item xs={6}>
          <DatePickerGeneric
            label="Data vencimento"
            value={dataVencimento}
            setValue={(value) => setDataVencimento(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label="Título"
            value={descricao}
            onChange={(value) => setDescricao(value)}
          />
        </Grid>
        {/* 
        <Grid item xs={12}>
          <ContaCaixaField
            contaCaixaId={contaCaixa?.id ?? 0}
            setContaCaixa={(obj) => {
              setContaCaixa(obj);
            }}
          />
        </Grid> */}

        <Grid item xs={12}>
          <CategoriaFinanceiroField
            idCategoria={transacao?.categoria?.id ?? 0}
            setCategoria={(obj) => {
              setCategoria(obj);
            }}
            categoria={transacao.categoria}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <NumberFieldGeneric
            label="Valor"
            type="money"
            value={valor}
            onChange={(value) => setValor(value)}
          />
        </Grid>

        <Grid item xs={12} textAlign={"center"}>
          <ButtonGeneric
            variant="contained"
            label={"Salvar"}
            onClick={() => {
              salvar();
            }}
          />
        </Grid>
      </Grid>

      {openAlert && (
        <DialogConfirmGeneric
          open={openAlert}
          cabecalho={"Editar transações recorrentes"}
          txtRespostaNegativa="Apenas esta"
          txtRespostaPositiva="Também as futuras"
          texto={`Você deseja editar apenas esta transação, ou as transações futuras de ${transacao.titulo}?`}
          onResposta={(resposta) => {
            atualizar(resposta);
            setOpenAlert(false);
          }}
        />
      )}
    </ModalGeneric>
  );
};

export default ModalEditarTransacao;
