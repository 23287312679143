import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import "dayjs/locale/pt-br";
import Container from "../../../components/Generics/Container";
import { Box, Tab, Tabs, Typography } from "@mui/material";

import theme from "../../../theme";
import transacaoApi from "../../../api/transacaoApi";
import { Transacao } from "../../../model/entities/Transacao";

import TransacoesCaixa from "./TransacoesCaixa";
import GridCenter from "../../../components/Generics/GridCenter";
import DoughnutTransacoes from "../../Graficos/DoughnutTransacoes";
import SwipeableViews from "react-swipeable-views";
import { useSnack } from "../../../redux/snack/snackHooks";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BalancoCaixaPage: React.FC = () => {
  const [balanco, setBalanco] = useState("mes");
  const [dataInicio, setDataInicio] = useState(dayjs());
  const [dataFim, setDataFim] = useState(dayjs());
  const [transacoes, setTransacoes] = useState<Transacao[]>([]);
  const mostrarSnack = useSnack();

  useEffect(() => {
    if (balanco === "semana") {
      const dataAtual = dayjs();
      const diaDaSemana = dataAtual.day();
      const inicioDaSemana = dataAtual.subtract(diaDaSemana, "day");
      const finalDaSemana = inicioDaSemana.add(5, "day");
      setDataInicio(inicioDaSemana);
      setDataFim(finalDaSemana);
    } else if (balanco === "dia") {
      setDataFim(dayjs());
      setDataInicio(dayjs());
    } else if (balanco === "mes") {
      setDataInicio(dayjs().startOf("month"));
      setDataFim(dayjs().endOf("month"));
    }
  }, [balanco]);

  useEffect(() => {
    buscarLancamentos();
  }, [dataInicio, dataFim]);

  const buscarLancamentos = async () => {
    try {
      const res = await transacaoApi.findAllWhitFilers({
        dataVencimentoInicio: dataInicio.toDate(),
        dataVencimentoFim: dataFim.toDate(),
        contaCaixaId: 1,
      });
      setTransacoes(res);
    } catch (erro: any) {
      mostrarSnack("Erro ao buscar os lançamentos", "error");
      console.log("Erro ao buscar os lançamentos", erro);
    }
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  return (
    <Container page style={{ borderRadius: "20px" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        aria-label="full width tabs example"
      >
        <Tab label="Transações" {...a11yProps(0)} />
        <Tab label="Gráficos" {...a11yProps(1)} />
      </Tabs>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          <TransacoesCaixa
            transacoes={transacoes}
            onReload={buscarLancamentos}
            dataInicio={dataInicio}
            setDataInicio={setDataInicio}
            dataFim={dataFim}
            setDataFim={setDataFim}
            balanco={balanco}
            setBalanco={setBalanco}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <GridCenter>
            <DoughnutTransacoes transacoes={transacoes} />{" "}
          </GridCenter>
        </TabPanel>
      </SwipeableViews>
    </Container>
  );
};

export default BalancoCaixaPage;
