import ZapPage from "../../pages/Zap/ZapPage";

import { RouteType } from "../config";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const grupowWhatsApp: RouteType = {
  path: "/whatsApp",
  element: <ZapPage />,
  state: "whatsApp",
  sidebarProps: {
    displayText: "WhatsApp",
    icon: <WhatsAppIcon />,
  },
};

export default grupowWhatsApp;
