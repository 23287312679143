import {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  TextField,
} from "@mui/material";
import { CSSProperties, HTMLInputTypeAttribute } from "react";
import InputMask from "react-input-mask";

type Props = {
  label: string;
  value: string;
  maxLength?: number;
  exibirMaxLength?: boolean;
  onChange: (value: string) => void;
  variant?: "outlined" | "standard" | "filled";
  type?: HTMLInputTypeAttribute;
  style?: CSSProperties;
  fullWidth?: boolean;
  minRows?: number;
  disabled?: boolean;
  placeholder?: string;
  focused?: boolean;
  helperText?: string;
  error?: boolean | undefined;
  InputProps?:
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | undefined;
  mask?: string;
};

const TextFieldGeneric: React.FC<Props> = (props) => {
  const {
    label,
    value,
    maxLength,
    variant,
    type,
    onChange,
    style,
    fullWidth,
    minRows,
    disabled,
    InputProps,
    exibirMaxLength,
    mask,
    placeholder,
    focused,
    error,
    helperText,
  } = props;

  let newVariant = variant;
  if (!newVariant) {
    newVariant = "outlined";
  }

  if (mask) {
    return (
      <InputMask
        mask={mask}
        onChange={(event) => {
          if (maxLength) {
            onChange(event.target.value.substring(0, maxLength));
          } else {
            onChange(event.target.value);
          }
        }}
        value={value}
        // onFocus={(event) => {
        //   if (autoSelect) {
        //     event.target.select();
        //   }
        //   if (onFocus) {
        //     onFocus();
        //   }
        // }
        // onBlur={(event) => {
        //   if (onBlur) {
        //     onBlur();
        //   }
        //   if (forceUppercase) {
        //     onChange(newValue.toString().toUpperCase(), event);
        //   }
        // }}
        // {...other}
      >
        <TextField
          label={label}
          placeholder={placeholder}
          // inputRef={ref}
          variant={newVariant}
          type={type}
          fullWidth={fullWidth === false ? false : true}
          // NÃO PODE TER INPUTPROPS POIS QUEBRA O INPUTMASK
          // inputProps={{
          //   maxLength
          // }}
          // onKeyDown={(event) => {
          //   if (submit && event.keyCode === 13) {
          //     submit();
          //   }
          // }}
        />
      </InputMask>
    );
  }

  return (
    <div>
      <TextField
        placeholder={placeholder ? placeholder : ""}
        id="standard-basic"
        label={label}
        variant={newVariant}
        value={value}
        multiline
        error={error}
        helperText={helperText}
        type={type}
        focused={focused}
        disabled={disabled}
        minRows={minRows ? minRows : 0}
        style={style}
        fullWidth={fullWidth === false ? false : true}
        onChange={(event) => {
          if (maxLength && event.target.value.length >= maxLength) {
            return;
          }
          onChange(event.target.value);
        }}
        InputProps={InputProps}
      />
      {maxLength && exibirMaxLength && (
        <div
          style={{
            textAlign: "right",
            fontSize: "12px",
            color: value.length + 1 >= maxLength ? "red" : "inherit",
          }}
        >
          {value.length + 1} / {maxLength}
        </div>
      )}
    </div>
  );
};

export default TextFieldGeneric;
