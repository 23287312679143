import DashboardPageLayout from "../../components/layout/DashboardPageLayout";
import HomePage from "../../pages/home/HomePage";
import HandymanIcon from "@mui/icons-material/Handyman";
import { RouteType } from "../config";
import ListagemOSPage from "../../pages/OrdemServico/ListaOS/ListagemOSPage";
import OSPage from "../../pages/OrdemServico/ListaOS/OSPage";
import ServicoPorUsuarioPage from "../../pages/OrdemServico/ListaOS/ServicoPorUsuarioPage";

const grupoOrdemServico: RouteType = {
  path: "/ordemServico",
  element: <DashboardPageLayout />,
  state: "ordemServico",
  sidebarProps: {
    displayText: "Ordem servico",
    icon: <HandymanIcon />,
  },

  child: [
    {
      index: true,
      element: <HomePage />,
      state: "ordemServico.index",
    },

    {
      path: "/ordemServico/cadastro/:osId",
      element: <OSPage />,
      state: "ordemServico.cadastro",
      verificarPermissao: () => false,
      sidebarProps: {
        displayText: "Nova ordem de serviço",
      },
    },

    {
      path: "/ordemServico/listagem",
      element: <ListagemOSPage />,
      state: "ordemServico.listagemPorResposavel",
      sidebarProps: {
        displayText: "Lista de OS",
      },
    },
    {
      path: "/ordemServico/listagemPorResponsavel",
      element: <ServicoPorUsuarioPage />,
      state: "ordemServico.listagem",
      sidebarProps: {
        displayText: "Meus serviços",
      },
    },
  ],
};

export default grupoOrdemServico;
