import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import rotas from "../../routes/grupos/rotas";
import SidebarItemCollapse from "./SidebarItemCollapse";
import SidebarItem from "./SidebarItem";
import { drawerWidth } from "../../constants";
import colorConfigs from "../../configs/colorConfigs";
import AntLogo from "../../assets/LogoAnt-white-no-bk.png";
import { Grid } from "@mui/material";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 15px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 15px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const DrawerComponent = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  handleDrawerClose: () => void;
};
const Drawer: React.FC<Props> = (props) => {
  const { open, handleDrawerClose, onOpen, onClose } = props;

  const [openMenu, setOpenMenu] = React.useState<string | null>(null);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <DrawerComponent
        ModalProps={{
          keepMounted: true, // Melhor performance de abertura em mobile.
        }}
        variant="permanent"
        open={open}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        sx={{
          "& .MuiDrawer-paper": {
            padding: "15px",
            boxSizing: "border-box",
            borderRight: "0px",
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color,
          },
        }}
      >
        <DrawerHeader
          style={{
            justifyContent: "center",
          }}
        >
          {/* <Grid>
            <img src={AntLogo} alt="Ant System logo" width={150} />
          </Grid> */}
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
        </DrawerHeader>
        <Divider />
        {rotas.map((route, index) =>
          route.sidebarProps ? (
            route.child ? (
              <SidebarItemCollapse
                drawerIsOpen={open}
                closeDrawer={onClose}
                item={route}
                openMenu={openMenu}
                setOpenMenu={setOpenMenu}
                key={index}
              />
            ) : (
              <SidebarItem item={route} key={index} closeDrawer={onClose} />
            )
          ) : null
        )}
      </DrawerComponent>
    </Box>
  );
};

export default Drawer;
