import { Empresa } from "../model/entities/Empresa";
import { Usuario } from "../model/entities/Usuario";
import api from "./api";
import empresaApi from "./empresaApi";

const ROUTE = "login";

class atestadoApi {
  async login(login: string, senha: string) {
    try {
      const res = await api.post<{
        tokenJWT: string;
        usuario: Usuario;
        empresa: Empresa;
      }>(`/${ROUTE}`, { login, senha });

      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new atestadoApi();
