import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ModalGeneric from "../../components/Generics/ModalGeneric";
import { DadosListagemOrcamento } from "../../model/types/OrcamentoType";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import SendIcon from "@material-ui/icons/Send";
import { useSnack } from "../../redux/snack/snackHooks";
import ButtonGeneric from "../../components/Generics/ButtonGeneric";
import CheckBoxGeneric from "../../components/Generics/CheckBoxGeneric";
import Titulo2 from "../../components/Generics/Titulo2";
import Info from "../../components/Generics/Info";
import zapApi from "../../api/zapApi";
import { toast } from "react-toastify";

import DoneAllIcon from "@material-ui/icons/DoneAll";
import { useCfgGeral } from "../../redux/cfgGeral/cfgGeralHooks";
import { error } from "console";
type Props = {
  open: boolean;
  onClose: () => void;
  dadosOrcamento: DadosListagemOrcamento;
};

const ModalEnviarOrcamentoZap: React.FC<Props> = (props) => {
  const { open, onClose, dadosOrcamento } = props;

  const mostrarSnack = useSnack();
  const [textoMensagem, setTextoMensagem] = useState("");
  const [enviarPdf, setEnviarPdf] = useState(true);
  const [cfgGeral] = useCfgGeral();

  useEffect(() => {
    if (!cfgGeral.statusZap) {
      mostrarSnack(
        "A api do Whats-App, parece estar offline. Se o problema persistir. Contate o suporte!",
        "error"
      );
    }
  }, []);

  const enviarMensagem = async () => {
    try {
      const resolveAfter3Sec = zapApi.enviarOrcamento({
        countryCode: "55",
        ddd: dadosOrcamento.celularCliente.substring(0, 2),
        number: dadosOrcamento.celularCliente.substring(
          3,
          dadosOrcamento.celularCliente.length
        ),
        message: textoMensagem,
        enviarPdf: enviarPdf,
      });

      toast.promise(
        resolveAfter3Sec,
        {
          pending: {
            render() {
              return "Enviando mensagem";
            },
          },
          success: {
            render() {
              onClose();
              return "Mensagem enviada";
            },
            icon: <DoneAllIcon color="primary" />,
          },
          error: "Erro ao enviar mensagem",
        },
        { position: "top-center" }
      );
    } catch (erro: any) {
      console.log("Não foi possível enviar a mensagem", erro);
      mostrarSnack("Não foi possível enviar a mensagem!", "info");
    }
  };

  return (
    <ModalGeneric open={open} onClose={onClose}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo2>Enviar mensagem via whats-app</Titulo2>
        </Grid>

        <Grid item xs={12} md={6}>
          <Info label="Cliente">{dadosOrcamento.nomeCliente}</Info>
        </Grid>

        <Grid item xs={12} md={6}>
          <Info label="Celular">{dadosOrcamento.celularCliente}</Info>
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label={"Mensagem"}
            minRows={4}
            placeholder="Digite aqui o texto a ser enviado para o cliente."
            value={textoMensagem}
            onChange={(value) => setTextoMensagem(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <CheckBoxGeneric
            label={"Enviar PDF do orçamento "}
            value={enviarPdf}
            onChange={(x) => setEnviarPdf(x)}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <ButtonGeneric
            startIcon={<SendIcon />}
            label="Enviar"
            onClick={enviarMensagem}
          />
        </Grid>
      </Grid>
    </ModalGeneric>
  );
};

export default ModalEnviarOrcamentoZap;
