import MiniInfo from "../components/Generics/MiniInfo";
import {
  TIPO_LANCAMENTO_CAIXA_CREDITO,
  TIPO_LANCAMENTO_CAIXA_DEBITO,
} from "../constants";
import { Transacao } from "../model/entities/Transacao";
import { DadosRecebimentoServico } from "../model/types/LancamentoCaixaTypes";
import { ResponseGenericType } from "../model/types/ResponseType";
import {
  DadosAtualizarTransacao,
  DadosCadastrarTransacao,
  FiltrosListagemTransacao,
} from "../model/types/TransacaoTypes";
import { colors } from "../theme";
import api from "./api";

const ROUTE = "transacao";

class transacaoApi {
  async getMany(pagina: number, linhasPorPagina: number) {
    try {
      const res = await api.get<ResponseGenericType<Transacao[]>>(`/${ROUTE}`);
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async findAllWhitFilers(filtros: FiltrosListagemTransacao) {
    try {
      const res = await api.post<Transacao[]>(
        `/${ROUTE}/findAllWhitFilers`,
        filtros
      );
      console.log(res);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(dados: DadosCadastrarTransacao) {
    try {
      const res = await api.post<Transacao>(`/${ROUTE}`, dados);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(idTransacao: number, dados: DadosAtualizarTransacao) {
    try {
      const res = await api.put<boolean>(`/${ROUTE}/${idTransacao}`, dados);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async delete(
    idTransacao: number,
    excluirRecorrentes: Boolean,
    idTransacaoRecorrente?: number
  ) {
    try {
      const res = await api.delete<boolean>(
        `/${ROUTE}/${idTransacao}?excluirRecorrentes=${excluirRecorrentes}&idTransacaoRecorrente=${idTransacaoRecorrente}`,
        {
          params: {
            excluirRecorrentes: excluirRecorrentes,
            idTransacaoRecorrente: idTransacaoRecorrente,
          },
        }
      );
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async lancarRecebimentoServico(dados: DadosRecebimentoServico) {
    try {
      const res = await api.post<Transacao>(
        `/${ROUTE}/recebimentoServico`,
        dados
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new transacaoApi();
export const getStatusTransacao = (dado: Transacao) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {dado.pago && (
        <MiniInfo style={{ marginTop: 3 }} color={colors.lightGreen}>
          PAGO
        </MiniInfo>
      )}

      {!dado.pago && (
        <MiniInfo style={{ marginTop: 3 }} color={colors.red}>
          EM ABERTO
        </MiniInfo>
      )}
    </div>
  );
};

export const getTipoTransacao = (tipoTransacao: string) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {(tipoTransacao === "CREDITO".toString() || tipoTransacao === "1") && (
        <MiniInfo style={{ marginTop: 3 }} color={colors.lightGreen}>
          CRÉDITO
        </MiniInfo>
      )}

      {(tipoTransacao === "DEBITO".toString() || tipoTransacao === "0") && (
        <MiniInfo style={{ marginTop: 3 }} color={colors.red}>
          DÉBITO
        </MiniInfo>
      )}
    </div>
  );
};
