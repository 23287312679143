import { FormaPagamento } from "../model/entities/FormaPagamento";
import { ResponseGenericType } from "../model/types/ResponseType";
import api from "./api";

const ROUTE = "formaPagamento";

class formaPagamento {
  async getMany(pagina: number, linhasPorPagina: number) {
    try {
      const res = await api.get<ResponseGenericType<FormaPagamento[]>>(
        `/${ROUTE}?size=${linhasPorPagina}&page=${pagina}`
      );
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getManyComplete() {
    try {
      const res = await api.get<FormaPagamento[]>(`/${ROUTE}/complete`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(formaPagamento: FormaPagamento) {
    try {
      const res = await api.post<FormaPagamento>(`/${ROUTE}`, formaPagamento);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new formaPagamento();
