import DashboardPageLayout from "../../components/layout/DashboardPageLayout";
import EmpresaPage from "../../pages/Empresa/EmpresaPage";
import HomePage from "../../pages/home/HomePage";
import PerfisPage from "../../pages/Perfis/PerfisPage";
import UsuariosPage from "../../pages/Usuarios/UsuariosPage";
import { RouteType } from "../config";
import SettingsIcon from "@mui/icons-material/Settings";

const grupoConfiguracao: RouteType = {
  path: "/configuracoes",
  element: <DashboardPageLayout />,
  state: "configuracoes",
  sidebarProps: {
    displayText: "Configurações",
    icon: <SettingsIcon />,
  },

  child: [
    {
      index: true,
      element: <HomePage />,
      state: "configuracao.index",
    },
    {
      path: "/configuracoes/empresa",
      element: <EmpresaPage />,
      state: "configuracao.empresa",
      sidebarProps: {
        displayText: "Empresa",
      },
    },
    {
      path: "/configuracoes/usuarios",
      element: <UsuariosPage />,
      state: "configuracao.usuario",
      sidebarProps: {
        displayText: "Usuários",
      },
    },
    {
      path: "/configuracoes/perfis",
      element: <PerfisPage />,
      state: "configuracao.perfil",
      sidebarProps: {
        displayText: "Perfis",
      },
    },
  ],
};

export default grupoConfiguracao;
