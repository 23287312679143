import { Cliente } from "./Cliente";

export class Endereco {
  id!: number;

  logradouro!: string;

  bairro!: string;

  numero!: number;

  cidade!: string;

  uf!: string;

  tipoEndereco!: number;

  cliente!: Cliente;

  createdAt!: Date;

  updatedAt!: Date;

  cep!: string;
}
