import * as React from "react";
import TextField from "@mui/material/TextField";

import { Autocomplete, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Cliente } from "../../model/entities/Cliente";
import clienteApi from "../../api/clienteApi";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  setCliente: (p: Cliente) => void;
  cliente: Cliente | null;
};

const ClienteField: React.FC<Props> = (props) => {
  const { cliente, setCliente } = props;

  const mostrarSnack = useSnack();
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [clienteSelecionado, setClienteSelecionado] = useState<Cliente | null>(
    null
  );

  useEffect(() => {
    buscaClientes();
  }, [cliente]);

  const buscaClientes = async () => {
    try {
      const res = await clienteApi.getManyComplete();
      setClientes(res);
      if (cliente) {
        const clienteEncontrado = res.find((item) => item.id === cliente.id);

        if (clienteEncontrado) {
          setClienteSelecionado(clienteEncontrado);
        }
      }
    } catch (erro: any) {
      mostrarSnack("Erro ao buscar lista de clientes", "error");
      console.log("erro ao buscar clientes", erro);
    }
  };
  const theme = useTheme();

  // Defina o valor de xs com base nos breakpoints do tema
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;
  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-tipo-endereco"
        options={clientes}
        getOptionLabel={(option) =>
          option.apelido ? `${option.apelido} - ${option.nome}` : option.nome
        }
        onChange={(event, value) => {
          if (value) {
            setCliente(value);
            setClienteSelecionado(value);
          } else {
            setClienteSelecionado(null);
          }
        }}
        value={clienteSelecionado}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={"Clientes"}
            placeholder={"Selecionar cliente"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default ClienteField;
