import { Servico } from "../model/entities/Servico";
import {
  DadosCadastrarServico,
  DadosListagemServico,
} from "../model/types/ServicoTypes";
import api from "./api";

const ROUTE = "servico";

class servicoApi {
  async getMany(pagina: number, linhasPorPagina: number) {
    try {
      const res = await api.get<DadosListagemServico[]>(
        `/${ROUTE}?size=${linhasPorPagina}&page=${pagina}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getManyComplete() {
    try {
      const res = await api.get<Servico[]>(`/${ROUTE}/complete`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(dados: DadosCadastrarServico) {
    try {
      const res = await api.post<Servico>(`/${ROUTE}`, dados);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(idServico: number, dados: DadosCadastrarServico) {
    try {
      const res = await api.put<boolean>(`/${ROUTE}/${idServico}`, dados);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new servicoApi();
