import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FloppyDisk } from "@phosphor-icons/react";
import ModalGeneric from "../../components/Generics/ModalGeneric";
import orcamentoApi from "../../api/orcamentoApi";
import {
  DadosListagemOrcamento,
  DadosListagemServicoOrcamento,
} from "../../model/types/OrcamentoType";
import dayjs from "dayjs";
import { Card, Paper } from "@material-ui/core";
import ServicoField from "../../components/fields/ServicoField";
import NumberFieldGeneric from "../../components/Generics/NumberFieldGeneric";
import DatePickerGeneric from "../../components/Generics/DatePickerGeneric";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import StatusOrcamentoField from "../../components/fields/StatusOrcamentoField";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { STATUS_ORCAMENTO_CONCUIDO } from "../../constants";
import GridCenter from "../../components/Generics/GridCenter";
import theme from "../../theme";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  open: boolean;
  onClose: () => void;
  dadosOrcamento: DadosListagemOrcamento;
};

const ModalInformacoesOrcamento: React.FC<Props> = (props) => {
  const { open, onClose, dadosOrcamento } = props;

  const mostrarSnack = useSnack();
  const [servicos, setServicos] = useState<DadosListagemServicoOrcamento[]>([]);

  const [, setLoading] = useState(false);
  const [valorOrcamento, setValorOrcamento] = useState(0);

  useEffect(() => {
    buscaServicos();
  }, []);

  useEffect(() => {
    const valor = servicos.reduce(
      (soma, item) => (soma += item.valorServico),
      0
    );
    setValorOrcamento(valor);
  }, [servicos]);

  const buscaServicos = async () => {
    try {
      if (!dadosOrcamento.id) return;
      setLoading(true);
      const res = await orcamentoApi.getServicos(dadosOrcamento.id);

      setServicos(res);
      setLoading(false);
    } catch (erro: any) {
      mostrarSnack(`Não consegui encontrar os serviços do orçamento`, "info");
    }
  };

  const salvar = async () => {
    try {
      await orcamentoApi.update(servicos);
      mostrarSnack("Informações atualizadas", "info");
      onClose();
    } catch (erro: any) {
      mostrarSnack("Não consegui salvar as informações do orçamento", "error");
    }
  };

  const excluirServicoOrcamento = async (
    dados: DadosListagemServicoOrcamento
  ) => {
    try {
      await orcamentoApi.deleteOrcamentoServico(dados.idOrcamentoServico);
      mostrarSnack("Informações atualizadas", "info");
    } catch (erro: any) {
      mostrarSnack("Não consegui salvar as informações do orçamento", "error");
    }
  };

  const excluirServicoOrcamentoFilho = async (
    dados: DadosListagemServicoOrcamento,
    idOrcamentoServicoFilho: number
  ) => {
    try {
      await orcamentoApi.deleteOrcamentoServicoFilho(
        dados.idOrcamentoServico,
        idOrcamentoServicoFilho
      );
      mostrarSnack("Informações atualizadas", "info");
    } catch (erro: any) {
      mostrarSnack("Não consegui salvar as informações do orçamento", "error");
    }
  };

  const handleRemoverServico = async (index: number) => {
    await excluirServicoOrcamento(servicos[index]);
    const novosServicos = [...servicos];
    novosServicos.splice(index, 1);
    setServicos(novosServicos);
  };

  const handleRemoverServicoFilho = (index: number, indexFilho: number) => {
    excluirServicoOrcamentoFilho(
      servicos[index],
      servicos[index].filhos[indexFilho].servicoFilhoId
    );
    const novosServicos = [...servicos];
    novosServicos[index].filhos.splice(indexFilho, 1);
    setServicos(novosServicos);
  };

  return (
    <ModalGeneric open={open} onClose={onClose}>
      <Grid container xs={12} spacing={2}>
        <Grid
          item
          xs={12}
          style={{ textAlign: "center", textDecoration: "underline" }}
        >
          <h3> Orçamento #{dadosOrcamento.id}</h3>
        </Grid>

        <Grid item xs={12}>
          {dayjs(dadosOrcamento.validadeProposta).isBefore(dayjs()) && (
            <Alert severity="error">Proposta de orçamento vencida</Alert>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label="Nome cliente"
            disabled
            value={dadosOrcamento.nomeCliente}
            onChange={() => {}}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <NumberFieldGeneric
            disabled
            type="money"
            label="Valor do orçamento"
            value={valorOrcamento}
            onChange={() => {}}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <DatePickerGeneric
            label="Data do orçamento"
            disabled
            value={dayjs(dadosOrcamento.dataOrcamento)}
            setValue={() => {}}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <DatePickerGeneric
            label="Validade da proposta"
            disabled={
              servicos[0]
                ? servicos[0].statusOrcamento === STATUS_ORCAMENTO_CONCUIDO.id
                : false
            }
            value={servicos[0] ? dayjs(servicos[0].validadeProposta) : dayjs()}
            setValue={(newDate) => {
              if (newDate) {
                let novosServicos = [...servicos];
                novosServicos[0].validadeProposta = newDate.toDate();
                setServicos(novosServicos);
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <StatusOrcamentoField
            disabled={
              servicos[0]
                ? servicos[0].statusOrcamento === STATUS_ORCAMENTO_CONCUIDO.id
                : false
            }
            codigoStatus={servicos[0] ? servicos[0].statusOrcamento : 0}
            setStatus={(obj) => {
              if (obj) {
                let novosServicos = [...servicos];
                novosServicos[0].statusOrcamento = obj.id;
                setServicos(novosServicos);
              }
            }}
          />
        </Grid>

        <Grid item xs={6} style={{ textAlign: "left" }}>
          <h4> Serviços em orçamento</h4>
        </Grid>

        {((servicos[0] &&
          servicos[0].statusOrcamento !== STATUS_ORCAMENTO_CONCUIDO.id) ||
          servicos.length === 0) && (
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                setServicos([
                  ...servicos,
                  {
                    idOrcamento: dadosOrcamento.id,
                    idServico: 0,
                    tituloServico: "",
                    validadeProposta: dadosOrcamento.validadeProposta,
                    valorServico: 0,
                    statusOrcamento: 0,
                    idOrcamentoServico: 0,
                    filhos: [],
                  },
                ]);
              }}
              children="Adicionar serviço"
              variant="outlined"
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {servicos.map((servico, index) => (
            <Card elevation={5} style={{ padding: "15px", marginTop: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={10} md={6}>
                  <ServicoField
                    disabled={
                      servicos[0]
                        ? servicos[0].statusOrcamento ===
                          STATUS_ORCAMENTO_CONCUIDO.id
                        : false
                    }
                    idServico={servico.idServico}
                    setServico={(newServico) => {
                      if (newServico) {
                        if (newServico.id === servico.idServico) return;
                        let novosServicos = [...servicos];

                        novosServicos[index].idServico = newServico.id;
                        novosServicos[index].tituloServico = newServico.titulo;
                        novosServicos[index].filhos =
                          newServico.servicosFilhos.map((item) => ({
                            servicoPaiId: item.servicoPai.id,
                            servicoFilhoId: item.servicoFilho.id,
                            tituloServicoFilho: item.servicoFilho.titulo,
                          }));
                        setServicos(novosServicos);
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={2} md={6} style={{ textAlign: "right" }}>
                  <IconButton onClick={() => handleRemoverServico(index)}>
                    <DeleteForever />
                  </IconButton>
                </Grid>

                <Grid item xs={12}>
                  {servico.filhos &&
                    servico.filhos.map((servicoFilho, indexFilho) => (
                      <Grid
                        container
                        spacing={1}
                        style={{ marginLeft: "22px", alignItems: "center" }}
                      >
                        <Grid item>
                          <Typography fontWeight={"bold"} fontSize={16}>
                            {servicoFilho.tituloServicoFilho}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <IconButton
                            onClick={() =>
                              handleRemoverServicoFilho(index, indexFilho)
                            }
                          >
                            <DeleteForever />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>

                <Grid item xs={12} md={12}>
                  <NumberFieldGeneric
                    value={servico.valorServico}
                    type="money"
                    label={"Valor total"}
                    onChange={(value) => {
                      let novosServicos = [...servicos];
                      novosServicos[index].valorServico = value;
                      setServicos(novosServicos);
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            style={{ margin: "5px" }}
            variant="contained"
            endIcon={<FloppyDisk size={32} />}
            onClick={salvar}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </ModalGeneric>
  );
};

export default ModalInformacoesOrcamento;
