import { createReducer } from "@reduxjs/toolkit";
import { OrcamentoState } from "./orcamentoTypes";
import {
  setApelidoClienteOrcamentoAction,
  setClienteOrcamentoAction,
  setDadosCadastroEnderecoAction,
  limparOrcamentoAction,
  setNomeClienteOrcamentoAction,
  setServicosOrcamentoAction,
  setEmailClienteOrcamentoAction,
  setTelefoneClienteOrcamentoAction,
  setDataValidadeOrcamentoAction,
} from "./orcamentoActions";
import dayjs from "dayjs";

export const initialState: OrcamentoState = {
  cliente: null,
  apelidoCliente: "",
  nomeCliente: "",
  servicosOrcamento: [],
  email: "",
  telefone: "",
  dataValidadeOrcamento: dayjs(),
  dadosEndereco: {
    bairro: "",
    cep: "",
    cidade: "",
    idEndereco: null,
    numeroEndereco: 0,
    rua: "",
  },
};

const orcamentoReducer = createReducer<OrcamentoState>(initialState, {
  [limparOrcamentoAction.toString()]: (state, action) => {
    return initialState;
  },

  [setClienteOrcamentoAction.toString()]: (state, action) => {
    if (setClienteOrcamentoAction.match(action)) {
      if (!action.payload) {
        state.cliente = null;
      } else {
        state.cliente = { ...action.payload };
      }
      return;
    }
    return state;
  },
  [setServicosOrcamentoAction.toString()]: (state, action) => {
    if (setServicosOrcamentoAction.match(action)) {
      if (!action.payload) {
        state.servicosOrcamento = [];
      } else {
        state.servicosOrcamento = action.payload;
      }
      return;
    }
    return state;
  },

  [setDadosCadastroEnderecoAction.toString()]: (state, action) => {
    if (setDadosCadastroEnderecoAction.match(action)) {
      if (!action.payload) {
        state.dadosEndereco = initialState.dadosEndereco;
      } else {
        state.dadosEndereco = action.payload;
      }
      return;
    }
    return state;
  },

  [setNomeClienteOrcamentoAction.toString()]: (state, action) => {
    if (setNomeClienteOrcamentoAction.match(action)) {
      if (!action.payload) {
        state.nomeCliente = initialState.nomeCliente;
      } else {
        state.nomeCliente = action.payload;
      }
      return;
    }
    return state;
  },

  [setApelidoClienteOrcamentoAction.toString()]: (state, action) => {
    if (setApelidoClienteOrcamentoAction.match(action)) {
      if (!action.payload) {
        state.apelidoCliente = initialState.apelidoCliente;
      } else {
        state.apelidoCliente = action.payload;
      }
      return;
    }
    return state;
  },

  [setEmailClienteOrcamentoAction.toString()]: (state, action) => {
    if (setEmailClienteOrcamentoAction.match(action)) {
      if (!action.payload) {
        state.email = initialState.email;
      } else {
        state.email = action.payload;
      }
      return;
    }
    return state;
  },

  [setTelefoneClienteOrcamentoAction.toString()]: (state, action) => {
    if (setTelefoneClienteOrcamentoAction.match(action)) {
      if (!action.payload) {
        state.telefone = initialState.telefone;
      } else {
        state.telefone = action.payload;
      }
      return;
    }
    return state;
  },

  [setDataValidadeOrcamentoAction.toString()]: (state, action) => {
    if (setDataValidadeOrcamentoAction.match(action)) {
      if (!action.payload) {
        state.dataValidadeOrcamento = initialState.dataValidadeOrcamento;
      } else {
        state.dataValidadeOrcamento = action.payload;
      }
      return;
    }
    return state;
  },
});

export default orcamentoReducer;
