import { Button, Grid } from "@mui/material";
import ModalGeneric from "../Generics/ModalGeneric";
import { DadosServicoOS } from "../../model/types/ServicoOSTypes";
import Titulo2 from "../Generics/Titulo2";

import { Save } from "@mui/icons-material";
import { useState } from "react";
import servicoOsApi from "../../api/servicoOsApi";
import DatePickerGeneric from "../Generics/DatePickerGeneric";
import dayjs from "dayjs";
import UsuarioField from "../fields/UsuarioField";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import { Usuario } from "../../model/entities/Usuario";
import StatusServicoOSField from "../fields/StatusServicoOSField";
import { formatarDinheiro } from "../../helper";
import Info from "../Generics/Info";
import { ACESSO_OS_VINCULADA } from "../../constants";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  open: boolean;
  onClose: () => void;
  servico: DadosServicoOS;
};

const ModalEditarServicoOS: React.FC<Props> = (props) => {
  const { open, onClose, servico } = props;

  const mostrarSnack = useSnack();
  const [dadosServicoEditar, setDadosServicoEditar] = useState(servico);
  const [sessao] = useSessao();
  const [usuario, setUsuario] = useState<Usuario | null>(sessao.usuario);

  const salvar = async () => {
    try {
      if (dadosServicoEditar.idOsServicoFilho) {
        await servicoOsApi.atualizar(dadosServicoEditar);
      } else {
        await servicoOsApi.atualizar(dadosServicoEditar);
      }
      mostrarSnack("Dados do serviço foram atualizados!", "success");
      onClose();
    } catch (erro: any) {
      console.log("Erro ao editar dados do serviço", erro);
      mostrarSnack("Erro ao editar dados do serviço", "error");
    }
  };

  return (
    <ModalGeneric open={open} onClose={onClose}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo2>{servico.nomeServico}</Titulo2>
        </Grid>

        <Grid item xs={6}>
          <DatePickerGeneric
            label="Data ínicio"
            value={dayjs(dadosServicoEditar.dataInicio)}
            setValue={(value) => {
              setDadosServicoEditar({
                ...dadosServicoEditar,
                dataInicio: value.toDate(),
              });
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <DatePickerGeneric
            label="Data entrega"
            value={dayjs(dadosServicoEditar.dataEntrega)}
            setValue={(value) => {
              setDadosServicoEditar({
                ...dadosServicoEditar,
                dataEntrega: value.toDate(),
              });
            }}
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <UsuarioField
            usuario={usuario}
            disabled={
              ACESSO_OS_VINCULADA.descricaoEnum ===
              usuario?.perfil.acessoOSPerfil
            }
            label="Responsável pelo serviço"
            idUsuario={dadosServicoEditar.idResponsavel}
            setUsuario={(obj) => {
              if (obj) {
                setUsuario(obj);
                setDadosServicoEditar({
                  ...dadosServicoEditar,
                  idResponsavel: obj.id,
                });
              }
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <StatusServicoOSField
            codigoStatus={dadosServicoEditar.status}
            setStatus={(obj) => {
              if (obj) {
                setDadosServicoEditar({
                  ...dadosServicoEditar,
                  status: obj.id,
                });
              }
            }}
          />
        </Grid>

        {servico.valorPago > 0 && (
          <Grid item xs={6}>
            <Info
              label={"Valor já pago"}
              children={formatarDinheiro(dadosServicoEditar.valorPago)}
            ></Info>
          </Grid>
        )}

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            style={{ margin: "5px" }}
            variant="contained"
            startIcon={<Save />}
            onClick={salvar}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </ModalGeneric>
  );
};

export default ModalEditarServicoOS;
