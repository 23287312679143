import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import Container from "../../../components/Generics/Container";
import PropiedadesOrçamento from "./PropiedadesOrçamento";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { colors } from "../../../theme";
import "dayjs/locale/pt-br";
import ServicosOrçamento from "./ServicosOrçamento";

const NovoOrcamentoPage: React.FC = () => {
  const frases = [
    "O cliente pode esquecer o que você disse, mas nunca esquecerá como você o fez sentir ao comprar seu serviço.",
    "Vender serviços é mais do que um negócio; é construir confiança e agregar valor.",
    "Cada cliente é uma oportunidade de transformar um simples contato em uma parceria sólida e duradoura.",
    "A excelência no atendimento ao cliente é a chave para o sucesso em qualquer venda de serviço.",
    "Nunca subestime o impacto de uma experiência positiva para o cliente; ela pode mudar tudo.",
    "Mais do que vender, foque em entender e resolver os problemas do seu cliente com seu serviço.",
    "Um cliente satisfeito com seu serviço é a melhor estratégia de marketing que existe.",
    "Transforme cada interação em uma experiência única e memorável para o cliente.",
    "A verdadeira arte de vender serviços está em ouvir mais do que falar.",
    "Vender é ajudar o cliente a encontrar a melhor solução para suas necessidades com seu serviço.",
    "A satisfação do cliente é o maior indicador de sucesso em qualquer negócio de serviços.",
    "Seja apaixonado pelo seu serviço, mas ainda mais pelo sucesso do seu cliente.",
    "Venda com propósito: para melhorar a vida do cliente através do seu serviço.",
    "Cada desafio em uma venda de serviço é uma oportunidade para crescer e se destacar.",
    "Lembre-se: o cliente é a razão da existência do seu negócio de serviços.",
  ];
  const [fraseAtual, setFraseAtual] = useState("");
  const [ocultarFrase, setOcultarFrase] = useState(false);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * frases.length);
    setFraseAtual(frases[randomIndex]);

    const timer = setTimeout(() => {
      setOcultarFrase(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const styles = {
    container: {
      margin: "5px",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      backgroundColor: "#f9f9f9",
      transition: "opacity 0.3s ease-in-out",

      opacity: ocultarFrase ? 0 : 1,
      // pointerEvents: ocultarFrase ? "none" : ("auto" as const), // corrigindo o tipo para pointerEvents, // Desabilita cliques quando oculto
    },
    text: {
      fontSize: "18px",
      color: colors.darkBlue,
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginRight: "10px",
    },
  };

  return (
    <Container page>
      <Grid container spacing={1}>
        {!ocultarFrase && (
          <div
            style={styles.container}
            onMouseEnter={() => setOcultarFrase(true)}
          >
            <span style={styles.text}>
              <WbIncandescentIcon style={styles.icon} />
              {fraseAtual}
            </span>
          </div>
        )}

        <Grid item xs={12}>
          <Container>
            <PropiedadesOrçamento />
          </Container>
        </Grid>

        <Grid item xs={12}>
          <Container>
            <ServicosOrçamento />
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NovoOrcamentoPage;
