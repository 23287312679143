import { Cep } from "../model/entities/Cep";
import api from "./api";

const ROUTE = "cep";

class cepApi {
  async getCep(cep: string) {
    try {
      const res = await api.get<Cep>(`/${ROUTE}/${cep}`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new cepApi();
