import React, { useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import { Button } from "@material-ui/core";
import { CategoriaFinanceiro } from "../../../model/entities/CategoriaFinanceiro";
import categoriaFinanceiroApi from "../../../api/categoriaFinanceiroApi";
import TableGeneric from "../../../components/Generics/TableGeneric";
import ModalGeneric from "../../../components/Generics/ModalGeneric";
import TextFieldGeneric from "../../../components/Generics/TextFieldGeneric";
import Container from "../../../components/Generics/Container";
import Titulo2 from "../../../components/Generics/Titulo2";
import ButtonGeneric from "../../../components/Generics/ButtonGeneric";
import IconSelector from "../../../components/Generics/IconSelector";
import IconRenderer from "../../../components/Generics/IconRenderer";
import { GridColDef } from "@mui/x-data-grid";
import { getTipoTransacao } from "../../../api/transacaoApi";
import TipoTransacaoField from "../../../components/fields/TipoTransacaoField";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogConfirmGeneric from "../../../components/Generics/DialogConfirmGeneric";
import { useSnack } from "../../../redux/snack/snackHooks";

type Icon = {
  name: string;
  component: React.ElementType;
};

const CategoriasFinanceiro: React.FC = () => {
  const mostrarSnack = useSnack();
  const [categorias, setCategorias] = useState<CategoriaFinanceiro[]>([]);

  const [totalLinhas, setTotalLinhas] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagina, setPagina] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [nome, setTitulo] = useState("");
  const [selectedIcon, setSelectedIcon] = useState<Icon | null>(null);
  const [tipoTransacao, setTipoTransacao] = useState(0);

  const [categoriaSelecionadaExcluir, setCategoriaSelecionadaExcluir] =
    useState<CategoriaFinanceiro | null>(null);

  const [openAlertExcluiur, setOpenAlertExcluiur] = useState(false);

  useEffect(() => {
    buscarCategorias();
  }, [pagina, linhasPorPagina]);

  const buscarCategorias = async () => {
    try {
      setLoading(true);
      const count = await categoriaFinanceiroApi.count();
      setTotalLinhas(count);

      const res = await categoriaFinanceiroApi.getMany(pagina, linhasPorPagina);
      setCategorias(res);
      setLoading(false);
    } catch (erro: any) {
      mostrarSnack(
        "Ops, aconteceu um problema ao buscar as contas caixa!!",
        "error"
      );
      console.log("Erro ao buscar contas caixa", erro);
      setLoading(false);
    }
  };

  const cadastrarCategoria = async () => {
    try {
      setLoading(true);

      if (
        categorias.find(
          (item) => item.nome.toUpperCase() === nome.toUpperCase()
        )
      ) {
        mostrarSnack("Já existe uma conta caixa com este nome.", "error");
        return;
      }

      await categoriaFinanceiroApi.insert({
        id: 0,
        nome: nome,
        icone: selectedIcon?.name ?? "",
        tipoLancamento: tipoTransacao,
      });
      setOpenModal(false);
      await buscarCategorias();

      setLoading(false);
    } catch (erro: any) {
      mostrarSnack(
        "Ops, aconteceu um problema ao cadastrar categoria!",
        "error"
      );
      console.log("Erro ao cadastrar categoria", erro);
      setLoading(false);
    }
  };

  const handleIconSelect = (icon: Icon) => {
    setSelectedIcon(icon);
  };

  const excluirCategoria = async () => {
    try {
      setLoading(true);
      const res = await categoriaFinanceiroApi.delete(
        categoriaSelecionadaExcluir?.id ?? 0
      );

      mostrarSnack("Categoria excluida", "success");
      setCategoriaSelecionadaExcluir(null);
      setOpenAlertExcluiur(false);
      await buscarCategorias();
    } catch (error: any) {
      console.log("erro", error.data);
      if (
        error.data ===
        "Não é possível excluir esta categoria pois existem transações associadas a ela."
      ) {
        mostrarSnack(error.data, "info");
        setLoading(false);
        return;
      }

      mostrarSnack("Não foi possível excluir categoria", "error");
      console.log("Não foi possível excluir categoria", error);
    }
    setLoading(false);
  };

  const columns: GridColDef<CategoriaFinanceiro>[] = [
    {
      field: "nome",
      headerName: "Categoria",
      width: 400,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <IconRenderer iconName={params.row.icone} />
            <span style={{ margin: "5px" }}>{params.row.nome} </span>
          </>
        );
      },
    },

    {
      field: "tipoLancamento",
      headerName: "Débito/Crédito",
      width: 230,
      editable: false,
      renderCell: (params) => {
        return getTipoTransacao(params.row.tipoLancamento.toString());
      },
    },

    {
      field: "",
      headerName: "Excluir",
      width: 100,
      editable: false,

      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              setCategoriaSelecionadaExcluir(params.row);
              setOpenAlertExcluiur(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Container page>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign={"right"}>
          <ButtonGeneric
            onClick={() => {
              setOpenModal(true);
            }}
            variant="contained"
            label="Cadastrar nova categoria"
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo2>Abaixo estão listadas as categorias do financeiro</Titulo2>
        </Grid>

        <Grid item xs={12}>
          <TableGeneric
            pageSize={linhasPorPagina}
            colunas={columns}
            linhas={categorias}
            getRowId={(row) => row.id}
            loading={loading}
            totalLinhas={totalLinhas}
            selecionaLinha={false}
            formasDeListar={[5, 10]}
            onRowClick={(params) => {}}
            setPaginacao={(model) => {
              setPagina(model.page);
              setLinhasPorPagina(model.pageSize);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <ModalGeneric
            open={openModal}
            onClose={() => {
              setOpenModal(false);
              buscarCategorias();
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <h1>Cadastro de nova categoria</h1>
              </Grid>

              <Grid item xs={12}>
                <TextFieldGeneric
                  label="Título"
                  maxLength={25}
                  value={nome}
                  onChange={(value) => {
                    setTitulo(value);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TipoTransacaoField
                  setTipoTransacao={(value) => setTipoTransacao(value)}
                />
              </Grid>

              <Grid item xs={12} style={{ textAlign: "center" }}>
                <IconSelector onSelect={handleIconSelect} />
              </Grid>

              <Grid item xs={12} textAlign={"center"}>
                <ButtonGeneric
                  variant="contained"
                  label={"Salvar"}
                  onClick={() => {
                    cadastrarCategoria();
                  }}
                />
              </Grid>
            </Grid>
          </ModalGeneric>
        </Grid>
      </Grid>

      {openAlertExcluiur && (
        <DialogConfirmGeneric
          open={openAlertExcluiur}
          cabecalho={"Confirmar ação"}
          texto={
            "Você tem certeza que deseja apagar a categoria? Essa ação não poderá ser desfeita."
          }
          onResposta={(res) => {
            if (res) {
              excluirCategoria();
            } else {
              setOpenAlertExcluiur(false);
              setCategoriaSelecionadaExcluir(null);
            }
          }}
        ></DialogConfirmGeneric>
      )}
    </Container>
  );
};

export default CategoriasFinanceiro;
