import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ReactElement } from "react";
import { Container } from "@material-ui/core";

type Props = {
  open: boolean;
  cabecalho: string | ReactElement;
  texto: string | ReactElement;
  onResposta: (resposta: boolean) => void;
  txtRespostaPositiva?: string;
  txtRespostaNegativa?: string;
};

const DialogConfirmGeneric: React.FC<Props> = (props) => {
  const {
    cabecalho,
    texto,
    open,
    onResposta,
    txtRespostaPositiva,
    txtRespostaNegativa,
  } = props;

  const yesButtonRef = useRef<HTMLButtonElement | null>(null);
  const noButtonRef = useRef<HTMLButtonElement | null>(null);

  const [foco, setFoco] = useState<"left" | "right">("left");

  useEffect(() => {
    const callback = (event: any) => {
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        noButtonRef.current?.focus();
        setFoco("left");
      }
      if (event.key === "ArrowRight") {
        event.preventDefault();
        yesButtonRef.current?.focus();
        setFoco("right");
      }
    };
    document.addEventListener("keydown", callback);

    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, [open]);

  const clickOk = () => {
    onResposta(true);
    //setOpen(false);
  };

  const clickCancelar = () => {
    onResposta(false);
    //setOpen(false);
  };

  const handleClose = () => {
    onResposta(false);
    //setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          borderRadius: 20,
          backgroundColor: "#fff",
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Container
        style={{
          padding: "20px",
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          {cabecalho}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center", fontWeight: "bold", color: "black" }}
          >
            {texto}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={clickCancelar}
            color="primary"
            variant={foco === "left" ? "contained" : "outlined"}
            style={{ marginRight: 10 }}
            ref={noButtonRef}
            onKeyDown={(e) => {
              if (e.key === "Enter") clickCancelar();
            }}
            disableRipple
            autoFocus
          >
            {txtRespostaNegativa ?? "Cancelar"}
          </Button>
          <Button
            onClick={clickOk}
            color="primary"
            variant={foco === "right" ? "contained" : "outlined"}
            ref={yesButtonRef}
            onKeyDown={(e) => {
              if (e.key === "Enter") clickOk();
            }}
            disableRipple
          >
            {txtRespostaPositiva ?? "OK"}
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

export default DialogConfirmGeneric;
