import { Grid, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import ButtonGeneric from "../../components/Generics/ButtonGeneric";
import DatePickerGeneric from "../../components/Generics/DatePickerGeneric";
import dayjs, { Dayjs } from "dayjs";
import Titulo2 from "../../components/Generics/Titulo2";
import CheckBoxGeneric from "../../components/Generics/CheckBoxGeneric";
import HorasField from "../../components/fields/HorasField";
import PrioriradadeAgendamentoField from "../../components/fields/PrioriradadeAgendamentoField";
import UsuarioField from "../../components/fields/UsuarioField";
import { Usuario } from "../../model/entities/Usuario";
import agendamentoApi from "../../api/agendamentoApi";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import ModalGeneric from "../../components/Generics/ModalGeneric";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  dataAgendamento?: string;
  onReload?: () => void;
  idAgendamento?: number;
  onClose?: () => void;
};

const FormularioAgenda: React.FC<Props> = (props) => {
  const { dataAgendamento, onReload, idAgendamento, onClose } = props;

  const mostrarSnack = useSnack();
  const [sessao] = useSessao();
  const [titulo, setTitulo] = useState("");
  const [startDate, setStartDate] = useState(
    dayjs(dataAgendamento, "DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(dayjs(dataAgendamento, "DD/MM/YYYY"));

  const [solucionado, setSolucionado] = useState(false);

  const [loading, setLoading] = useState(false);
  const [horaInicio, setHoraInicio] = useState("00:00");
  const [horaFim, setHoraFim] = useState("23:59");
  const [observacao, setObservaccao] = useState("");
  const [prioridade, setPrioridade] = useState(0);
  const [agendadorPor, setAgendadorPor] = useState<Usuario | null>(
    sessao.usuario
  );

  const [agendarPara, setAgendarPara] = useState<Usuario | null>(
    sessao.usuario
  );
  const schema = Yup.object().shape({
    titulo: Yup.string().required("Informe o título"),
    agendarPara: Yup.number().required("Informe para quem será o agendamento"),
    agendadorPor: Yup.number().required(
      "Informe quem está fazendo o agendamento"
    ),
  });
  const {
    register,
    handleSubmit: handleSubmitForm,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleSubmit = async (data: any) => {
    console.log("será que tem erro?");
    if (Object.keys(errors).length === 0) {
      // Se não houver erros, proceda com a submissão do formulário
      console.log("Dados do formulário:", data);
      // Adicione aqui a lógica para enviar os dados para o servidor, etc.
      await salvar();
    } else {
      // Se houver erros, você pode optar por lidar com eles de alguma forma
      console.error("Houve erros de validação:", errors);
    }
  };

  useEffect(() => {
    buscarAgendamento();
  }, []);

  async function buscarAgendamento() {
    try {
      setLoading(true);
      if (idAgendamento) {
        const res = await agendamentoApi.getById(Number(idAgendamento));
        setTitulo(res.title);
        setStartDate(dayjs(res.startDate));
        setEndDate(dayjs(res.endDate));
        setSolucionado(res.solucionado);
        setObservaccao(res.observacao);
        setHoraInicio(dayjs(res.startDate).format("HH:mm"));
        setHoraFim(dayjs(res.endDate).format("HH:mm"));
        setAgendadorPor(res.agendadoPor);
        setAgendarPara(res.agendadoPara);
        setPrioridade(res.prioridade);
      }
    } catch (erro: any) {
      console.log("erro ao buscar agendamento especifico", erro);
      mostrarSnack("Erro ao buscar dados do agendamento", "error");
    }
    setLoading(false);
  }

  const salvar = async () => {
    try {
      if (!titulo) {
        mostrarSnack("Por favor informe o título", "error");
        return;
      }

      if (!agendadorPor || !agendadorPor.id) {
        mostrarSnack(
          "Por favor informe quem está fazendo o agendamento",
          "error"
        );
        return;
      }

      if (!agendarPara || !agendarPara.id) {
        mostrarSnack("Por favor informe para quem é o agendamento", "error");
        return;
      }

      const horaInicial = horaInicio.substring(0, 2);
      const minutoInicial = horaInicio.substring(3, 5);

      const horaFinal = horaFim.substring(0, 2);
      const minutoFinal = horaFim.substring(3, 5);

      if (idAgendamento) {
        await agendamentoApi.update(idAgendamento, {
          allDay: false,
          title: titulo,
          endDate: dayjs(
            endDate.toDate().setHours(Number(horaFinal), Number(minutoFinal))
          ).toDate(),
          startDate: dayjs(
            endDate
              .toDate()
              .setHours(Number(horaInicial), Number(minutoInicial))
          ).toDate(),
          horaFim: horaFim,
          horaInicio: horaInicio,
          id: 0,
          observacao: observacao,
          prioridade: prioridade,
          solucionado: solucionado,
          agendadoPara: agendarPara?.id,
          agendadoPor: agendadorPor?.id,
        });
        mostrarSnack("Agendamento concluído!", "success");
        if (onReload) onReload();
        return;
      }

      await agendamentoApi.insert({
        allDay: false,
        title: titulo,
        endDate: dayjs(
          endDate.toDate().setHours(Number(horaFinal), Number(minutoFinal))
        ).toDate(),
        startDate: dayjs(
          endDate.toDate().setHours(Number(horaInicial), Number(minutoInicial))
        ).toDate(),
        horaFim: horaFim,
        horaInicio: horaInicio,
        id: 0,
        observacao: observacao,
        prioridade: prioridade,
        solucionado: solucionado,
        agendadoPara: agendarPara?.id,
        agendadoPor: agendadorPor?.id,
      });
      mostrarSnack("Agendamento concluído!", "success");
      if (onReload) onReload();
    } catch (erro: any) {
      mostrarSnack("Não foi possível criar agendamento", "error");
      console.log("Não foi possível criar agendamento", erro);
    }
  };

  // if (loading) {
  //   return (
  //     <ModalGeneric open={loading} onClose={() => {}}>
  //       <Grid container>
  //         <Grid item xs={12} style={{ textAlign: "center" }}>
  //           <Titulo2>Buscando dados do agendamento</Titulo2>
  //           <LinearProgress />
  //         </Grid>
  //       </Grid>
  //     </ModalGeneric>
  //   );
  // }

  return (
    <form onSubmit={handleSubmitForm(handleSubmit)}>
      <Grid container spacing={2} style={{ borderRadius: "15px" }}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo2>Criar novo agendamento</Titulo2>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="titulo"
            control={control}
            render={({ field }) => (
              <TextFieldGeneric
                {...field}
                focused
                label={"Titulo"}
                placeholder="Adicionar um título"
                value={titulo}
                error={!!errors.titulo}
                helperText={errors.titulo ? errors.titulo.message : ""}
                onChange={(value) => {
                  field.onChange(value);
                  setTitulo(value);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={5}>
          <DatePickerGeneric
            label={"Data ínicio"}
            value={startDate}
            setValue={(value) => setStartDate(value)}
          />
        </Grid>

        <Grid item xs={6}>
          <HorasField
            label="Hora ínicio"
            hora={horaInicio}
            setHora={(value) => {
              setHoraInicio(value);
            }}
          />
        </Grid>

        <Grid item xs={5}>
          <DatePickerGeneric
            label={"Data fim"}
            value={endDate}
            setValue={(value) => setEndDate(value)}
          />
        </Grid>

        <Grid item xs={6}>
          <HorasField
            label="Hora fim"
            hora={horaFim}
            setHora={(value) => {
              setHoraFim(value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CheckBoxGeneric
            label={"Solucionado"}
            value={solucionado}
            onChange={(x) => setSolucionado(x)}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "left" }}>
          <PrioriradadeAgendamentoField
            prioridade={prioridade}
            setPrioridade={(x) => {
              setPrioridade(x);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label={"Observação"}
            value={observacao}
            minRows={4}
            onChange={(value) => setObservaccao(value)}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="agendadorPor"
            control={control}
            render={({ field }) => (
              <UsuarioField
                idUsuario={agendadorPor?.id}
                label={"Agendador por"}
                usuario={agendadorPor}
                setUsuario={(u) => {
                  field.onChange(u?.id);
                  setAgendadorPor(u);
                }}
                error={!!errors.agendadorPor}
                helperText={
                  errors.agendadorPor ? errors.agendadorPor.message : ""
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="agendarPara"
            control={control}
            render={({ field }) => (
              <UsuarioField
                label={"Agendar para"}
                usuario={agendarPara}
                idUsuario={agendarPara?.id}
                setUsuario={(u) => {
                  field.onChange(u?.id);
                  setAgendarPara(u);
                }}
                error={!!errors.agendarPara}
                helperText={
                  errors.agendarPara ? errors.agendarPara.message : ""
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <ButtonGeneric label={"Salvar"} type="submit" onClick={() => {}} />
        </Grid>
      </Grid>
    </form>
  );
};

export default FormularioAgenda;
