import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Grid, CircularProgress, Typography } from "@mui/material";
import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ResumoOrcamento } from "../../model/types/OrcamentoType";
import servicoOsApi from "../../api/servicoOsApi";

ChartJS.register(ArcElement, Tooltip, Legend);
const backgroundColors = [
  "#20c958",
  "rgba(255, 206, 86, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 99, 132, 1)",
];

const borderColor = [
  "#20c958",
  "rgba(255, 225, 86, 1)",
  "rgba(54, 182, 235, 1)",
  "rgba(255, 105, 132, 1)",
];

type Props = {
  quantidadeRegistro?: (x: number) => void;
};

const DoughnutServicos: React.FC<Props> = (props) => {
  const [resumo, setResumo] = useState<ResumoOrcamento[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { quantidadeRegistro } = props;

  useEffect(() => {
    getResumoServicos();
  }, []);

  const getResumoServicos = async () => {
    try {
      const res = await servicoOsApi.getResumoServicos();
      setResumo(res);
      if (quantidadeRegistro) quantidadeRegistro(res.length);
    } catch (erro: any) {
      console.log("erro ao buscar resumo dos orçamentos", erro);
    } finally {
      setLoading(false);
    }
  };

  const data = {
    labels: resumo.map((item) => item.status),
    datasets: [
      {
        data: resumo.map((item) => item.quantidade),
        backgroundColor: backgroundColors.slice(0, resumo.length),
        borderColor: borderColor.slice(0, resumo.length),
        borderWidth: 1,
      },
    ],
  };

  return (
    <Grid item xs={12} md={6}>
      <Container style={{ textAlign: "center" }}>
        <h2>Serviços</h2>
        {loading ? (
          <CircularProgress />
        ) : resumo.length === 0 ? (
          <Typography variant="body1">Nenhum dado disponível</Typography>
        ) : (
          <Doughnut data={data} />
        )}
      </Container>
    </Grid>
  );
};
export default DoughnutServicos;
