import { TransacaoRecorrente } from "../model/entities/TransacaoRecorrente";
import { ResponseGenericType } from "../model/types/ResponseType";
import {
  DadosCadastrarTransacao,
  DadosCadastrarTransacaoRecorrente,
} from "../model/types/TransacaoTypes";
import api from "./api";

const ROUTE = "transacaoRecorrente";

class transacaoRecorrenteApi {
  async getMany() {
    try {
      const res = await api.get<ResponseGenericType<TransacaoRecorrente[]>>(
        `/${ROUTE}`
      );
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(dados: DadosCadastrarTransacaoRecorrente) {
    try {
      const res = await api.post<TransacaoRecorrente>(`/${ROUTE}`, dados);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new transacaoRecorrenteApi();
