import { Servico } from "./Servico";
import { ServicoFilho } from "./ServicoFilho";

export class OrcamentoServico {
  id!: number;
  idServico!: number;
  titulo!: string;
  valor!: number;
  servico!: Servico;
  servicosFilhos!: ServicoFilho[];
  servicoPai?: boolean;

  constructor(servico: Servico, servicosFilhos: ServicoFilho[]) {
    this.idServico = servico.id;
    this.titulo = servico.titulo;
    this.valor = servico.valor;
    this.servico = servico;
    this.servicosFilhos = servicosFilhos;
    this.servicoPai = servico.servicoPai;
  }
}
