import { useEffect, useState } from "react";
import { Alert, Button, Grid } from "@mui/material";
import ModalGeneric from "../Generics/ModalGeneric";
import { DadosServicoOS } from "../../model/types/ServicoOSTypes";
import Titulo2 from "../Generics/Titulo2";
import Info from "../Generics/Info";
import { formatarDinheiro } from "../../helper";
import FormaPagamentoField from "../fields/FormaPagamentoField";
import PaidIcon from "@mui/icons-material/Paid";
import DatePickerGeneric from "../Generics/DatePickerGeneric";
import dayjs, { Dayjs } from "dayjs";
import TextFieldGeneric from "../Generics/TextFieldGeneric";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import NumberFieldGeneric from "../Generics/NumberFieldGeneric";
import { FormaPagamento } from "../../model/entities/FormaPagamento";
import { TIPO_LANCAMENTO_CAIXA_CREDITO } from "../../constants";
import DialogConfirmGeneric from "../Generics/DialogConfirmGeneric";
import transacaoApi from "../../api/transacaoApi";
import { CategoriaFinanceiro } from "../../model/entities/CategoriaFinanceiro";
import CategoriaFinanceiroField from "../fields/CategoriaFinanceiroField";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  open: boolean;
  onClose: () => void;
  dadosServico: DadosServicoOS;
};

const ModalRecebimentoServico: React.FC<Props> = (props) => {
  const { open, onClose, dadosServico } = props;

  const [dataPagamento, setDataPagamento] = useState(dayjs());
  const [observacao, setObservacao] = useState("");
  const [valorPagamento, setValorPagamento] = useState(0);
  const [formaPagamento, setFormaPagamento] = useState<FormaPagamento | null>(
    null
  );
  const [sessao] = useSessao();
  const [mensagemAviso, setMensagemAviso] = useState("");
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [mensagemDialogConfirm, setMensagemDialogConfirm] = useState("");
  const [categoria, setCategoria] = useState<CategoriaFinanceiro | null>(null);
  const mostrarSnack = useSnack();

  const historico = `Recebimento do serviço :${dadosServico.idServico}: ${
    dadosServico.nomeServico
  } referente a ordem de serviço ${
    dadosServico.idOrdemServico
  }, no valor de  R$ ${formatarDinheiro(
    dadosServico.valorServico
  )}. Feito pelo usuário ${sessao?.usuario?.nome}.`;

  const conferirRegras = async () => {
    try {
      const valorRestante = dadosServico.valorServico - dadosServico.valorPago;
      if (!formaPagamento || !formaPagamento.id) {
        mostrarSnack("Por favor informe a forma de pagamento.", "error");
        return;
      }

      if (!dataPagamento) {
        mostrarSnack("Por favor informe a data do pagamento", "error");
        return;
      }

      if (!valorPagamento || valorPagamento === 0) {
        mostrarSnack("Por favor informe o valor pago pelo cliente.", "error");
        return;
      }

      if (valorPagamento > valorRestante) {
        mostrarSnack(
          `O valor que está sendo pago de ${formatarDinheiro(
            valorPagamento
          )}, é maior que o valor restante de ${formatarDinheiro(
            valorRestante
          )}`,
          "error"
        );
        return;
      }

      if (valorPagamento + dadosServico.valorPago < dadosServico.valorServico) {
        setMensagemDialogConfirm(
          `Confirma pagamento parcial de R$ ${formatarDinheiro(
            valorPagamento
          )} ? Ficará um restante de R$ ${formatarDinheiro(
            dadosServico.valorServico - dadosServico.valorPago - valorPagamento
          )}`
        );
        setOpenDialogConfirm(true);

        return;
      }

      await fazerLancamento();
    } catch (erro: any) {
      console.log("erro ao fazer reebimento de serviço", erro);
      mostrarSnack("Erro ao efetuar recebimento de serviço", "error");
    }
  };

  const fazerLancamento = async () => {
    try {
      if (!formaPagamento || !formaPagamento.id) {
        mostrarSnack("Por favor informe a forma de pagamento.", "error");
        return;
      }

      // if (!contaCaixa || !contaCaixa.id) {
      //   mostrarSnack("Por favor informe a conta caixa.","error");
      //   return;
      // }

      if (!categoria || !categoria.id) {
        mostrarSnack("Por favor informe a categoria.", "error");
        return;
      }
      console.log("dadosServico", dadosServico);
      await transacaoApi.lancarRecebimentoServico({
        dataPagamento: dataPagamento.toDate(),
        historico: historico,
        tipoLancamento: TIPO_LANCAMENTO_CAIXA_CREDITO,
        observacao: observacao,
        idFormaPagamento: formaPagamento.id,
        valorLancamento: valorPagamento,
        idOrdemServico: dadosServico.idOrdemServico,
        idServico: dadosServico.idServico,
        contaCaixaId: 1,
        idOsServico: dadosServico.idOsServico,
        titulo: `Recebimento servico: ${dadosServico.nomeServico}`,
        categoriaFinaneiroID: categoria?.id,
      });

      mostrarSnack("Pagamento confirmado", "success");
      onClose();
    } catch (erro: any) {
      console.log("erro ao fazer reebimento de serviço", erro);
      mostrarSnack("Erro ao efetuar recebimento de serviço", "error");
    }
  };

  useEffect(() => {
    if (
      valorPagamento < dadosServico.valorServico + dadosServico.valorPago &&
      dadosServico.valorServico - dadosServico.valorPago - valorPagamento > 0
    ) {
      setMensagemAviso(
        `Estão faltando R$${formatarDinheiro(
          dadosServico.valorServico - dadosServico.valorPago - valorPagamento
        )} no valor pago.`
      );
    } else {
      setMensagemAviso("");
    }
  }, [valorPagamento, dadosServico]);

  return (
    <ModalGeneric open={open} onClose={onClose}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo2>Fazer recebimento</Titulo2>
          <Titulo2>{dadosServico.nomeServico}</Titulo2>
        </Grid>

        <Grid item xs={dadosServico.valorPago > 0 ? 6 : 12} md={6}>
          <Info
            label={"Valor do serviço"}
            children={formatarDinheiro(dadosServico.valorServico)}
          ></Info>
        </Grid>

        {dadosServico.valorPago > 0 && (
          <Grid item xs={dadosServico.valorPago > 0 ? 6 : 12} md={6}>
            <Info
              label={"Valor já pago"}
              children={formatarDinheiro(dadosServico.valorPago)}
            ></Info>
          </Grid>
        )}

        <Grid item xs={12}>
          <Info label={"Tipo do lançamento"} children={"Crédito"} />
        </Grid>

        <Grid item md={4} xs={12}>
          <NumberFieldGeneric
            type="money"
            label={"Valor pagamento"}
            value={valorPagamento}
            onChange={(value) => setValorPagamento(value)}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <DatePickerGeneric
            label={"Data pagamento"}
            value={dataPagamento}
            setValue={(value) => {
              setDataPagamento(value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CategoriaFinanceiroField
            categoria={categoria}
            idCategoria={11}
            setCategoria={(obj) => setCategoria(obj)}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <FormaPagamentoField
            formaPagamento={formaPagamento}
            idFormaPagamento={formaPagamento?.id}
            setFormaPagamento={(obj) => {
              setFormaPagamento(obj);
            }}
          />
        </Grid>

        <Grid item md={12} xs={21}>
          <TextFieldGeneric
            minRows={4}
            exibirMaxLength
            maxLength={1000}
            label={"Observação"}
            value={observacao}
            onChange={(value) => setObservacao(value)}
          />
        </Grid>

        {mensagemAviso && (
          <Grid item xs={12}>
            <Alert
              elevation={6}
              variant="filled"
              severity={mensagemAviso.includes("parcial") ? "info" : "error"}
            >
              {mensagemAviso}
            </Alert>
          </Grid>
        )}

        <Grid item md={12} xs={21}>
          <TextFieldGeneric
            minRows={2}
            label={"Histórico"}
            disabled
            value={historico}
            onChange={() => {}}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            style={{ margin: "5px" }}
            variant="contained"
            startIcon={<PaidIcon />}
            onClick={conferirRegras}
          >
            Receber
          </Button>
        </Grid>

        {openDialogConfirm && (
          <DialogConfirmGeneric
            open={openDialogConfirm}
            cabecalho={"Confirmar"}
            texto={mensagemDialogConfirm}
            onResposta={(value) => {
              if (value) {
                fazerLancamento();
              } else {
                setOpenDialogConfirm(false);
              }
            }}
          />
        )}
      </Grid>
    </ModalGeneric>
  );
};

export default ModalRecebimentoServico;
