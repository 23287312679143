export class Empresa {
  id?: number;

  cnpj!: string;

  razaoSocial!: string;

  nomeFantasia!: string;

  telefone!: string;

  email!: string;

  cep!: string;

  logradouro!: string;

  cidade!: string;

  bairro!: string;

  numeroEndereco!: string;

  uf!: string;

  nomeResposavel!: string;

  telefoneResponsavel!: string;

  logo!: string;

  urlApiZap!: string;
}
