import { useEffect, useState } from "react";
import { Button, Grid, Paper, useMediaQuery } from "@mui/material";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import { FloppyDisk, Plus } from "@phosphor-icons/react";
import Container from "../../components/Generics/Container";
import { toast, ToastContentProps } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import clienteApi from "../../api/clienteApi";
import DatePickerGeneric from "../../components/Generics/DatePickerGeneric";
import dayjs from "dayjs";
import TableGeneric from "../../components/Generics/TableGeneric";
import { Endereco } from "../../model/entities/Endereco";

import ModalNovoEndereco from "../../components/modais/ModalNovoEndereco";
import { GridColDef } from "@mui/x-data-grid";
import SexoField from "../../components/fields/SexoField";
import { Cliente } from "../../model/entities/Cliente";
import GridCenter from "../../components/Generics/GridCenter";
import theme from "../../theme";
import Info from "../../components/Generics/Info";
import Titulo2 from "../../components/Generics/Titulo2";
import { useSnack } from "../../redux/snack/snackHooks";
import NumberFieldGeneric from "../../components/Generics/NumberFieldGeneric";

type Props = {
  onSave?: (cliente: Cliente) => void;
  cadastrando?: boolean;
};

const NovoClientePage: React.FC<Props> = (props) => {
  const { onSave, cadastrando } = props;

  const [nome, setNome] = useState("");
  const [apelido, setApelido] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [genero, setGenero] = useState("");
  const [dataNascimento, setDataNascimento] = useState(dayjs());
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [enderecos, setEnderecos] = useState<Endereco[]>([]);
  const [openModalNovoEndereco, setOpenModalNovoEndereco] = useState(false);
  const isSmallSScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { state } = useLocation();
  const navigate = useNavigate();
  const clienteSelecionado = state as Cliente;
  const mostrarSnack = useSnack();

  useEffect(() => {
    if (clienteSelecionado) {
      setNome(clienteSelecionado.nome);
      setApelido(clienteSelecionado.apelido);
      setEmail(clienteSelecionado.email);
      setGenero(clienteSelecionado.genero);
      setCpf(clienteSelecionado.cpf);
      setCnpj(clienteSelecionado.cnpj);
      setEnderecos(clienteSelecionado.enderecos);
      setCelular(clienteSelecionado.celular);
    }
  }, [clienteSelecionado]);

  const salvar = async () => {
    try {
      if (!nome) {
        mostrarSnack("Por favor, informe o nome do cliente", "error");
        return;
      }

      if (!enderecos || enderecos.length === 0) {
        mostrarSnack(
          "Por favor, informe o endereço do cliente antes de continuar.",
          "error"
        );
        return;
      }

      const resolveAfter3Sec = clienteApi.insert({
        nome,
        apelido,
        cnpj,
        cpf,
        dataNascimento: dataNascimento.toDate(),
        email,
        enderecos,
        genero: genero.substring(0, 1),
        celular,
      });

      toast.promise(resolveAfter3Sec, {
        pending: {
          render() {
            return "Cadastrando cliente";
          },
        },
        success: {
          render(params) {
            if (onSave && params.data) {
              onSave({ ...params.data, enderecos });
              return "Cliente cadastrado";
            } else {
              navigate("/cliente/listagem");
            }
          },
        },
        error: {
          render: (params: any) => {
            if (!params) {
              return "Dados indisponíveis";
            }
            console.log("params", params);
            return `${params.data.data}`;
          },
        },
      });
    } catch (erro: any) {
      console.log("Erro ao cadastrar cliente", erro);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container page>
        <Grid container xs={12} spacing={2}>
          {cadastrando && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Titulo2>Cadastrar cliente</Titulo2>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextFieldGeneric
              label="Nome"
              value={nome}
              maxLength={50}
              onChange={(value) => {
                setNome(value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label="Apelido"
              value={apelido}
              onChange={(value) => {
                setApelido(value);
              }}
            />
          </Grid>

          <Grid item xs={6} md={12}>
            <TextFieldGeneric
              mask="(99)999999999"
              label="Celular"
              value={celular}
              onChange={(value) => {
                setCelular(value);
              }}
            />
          </Grid>

          <Grid item xs={6} md={12}>
            <TextFieldGeneric
              label="Email"
              value={email}
              onChange={(value) => {
                setEmail(value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SexoField setSexo={(value) => setGenero(value)} />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePickerGeneric
              value={dataNascimento}
              label="Data de nascimento"
              setValue={(value) => {
                setDataNascimento(value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextFieldGeneric
              label="CPF"
              value={cpf}
              mask={"999.999.999-99"}
              onChange={(value) => {
                const texto = value.replace(/\D/g, "");

                setCpf(texto);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextFieldGeneric
              label="CNPJ"
              mask={"99.999.999/9999-99"}
              value={cnpj}
              onChange={(value) => {
                const texto = value.replace(/\D/g, "");
                setCnpj(texto);
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              endIcon={<Plus size={32} />}
              onClick={() => {
                setOpenModalNovoEndereco(true);
              }}
            >
              Novo endereço
            </Button>
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Titulo2>Endereços do cliente</Titulo2>
          </Grid>

          <Grid item xs={12}>
            {enderecos.map((item) => (
              <GridCenter>
                <Paper
                  style={{
                    borderRadius: "20px",
                    margin: "12px 0px",
                    padding: "12px",
                    width: isSmallSScreen ? "100%" : "75%",
                  }}
                  key={item.id}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Info label="" style={{ textAlign: "center" }}>
                        {item.tipoEndereco === 1 ? (
                          <span>Endereço residencial</span>
                        ) : item.tipoEndereco === 0 ? (
                          <span>Endereço de cobrança</span>
                        ) : (
                          " "
                        )}
                      </Info>
                    </Grid>

                    <Grid item xs={12}>
                      <Info label="Logradouro">{item.logradouro} </Info>
                    </Grid>

                    <Grid item xs={12}>
                      <Info label="Bairro">{item.bairro} </Info>
                    </Grid>

                    <Grid item xs={12}>
                      <Info label="Cidade">{item.cidade} </Info>
                    </Grid>
                  </Grid>
                </Paper>
              </GridCenter>
            ))}
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              style={{ margin: "5px" }}
              variant="contained"
              endIcon={<FloppyDisk size={32} />}
              onClick={() => salvar()}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>

        {openModalNovoEndereco && (
          <ModalNovoEndereco
            open={openModalNovoEndereco}
            onClose={() => setOpenModalNovoEndereco(false)}
            adicionarEndereco={(value) => {
              setEnderecos([
                ...enderecos,
                { ...value, id: enderecos.length + 1 },
              ]);

              setOpenModalNovoEndereco(false);
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default NovoClientePage;
