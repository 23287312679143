import { RouteType } from "../config";
import PortifolioPage from "../../pages/Portifolio/PortifolioPage";
import PermMediaIcon from "@mui/icons-material/PermMedia";

const grupoPortifolio: RouteType = {
  path: "/portifolioPage",
  element: <PortifolioPage />,
  state: "portifolio",
  sidebarProps: {
    displayText: "Portfólio ",
    icon: <PermMediaIcon />,
  },
};

export default grupoPortifolio;
