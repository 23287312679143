import React, { useState, ChangeEvent, useEffect } from "react";
import * as Icons from "@mui/icons-material";
import { FixedSizeGrid as Grid, GridChildComponentProps } from "react-window";
import { IconButton, TextField, Box } from "@mui/material";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";

type PropsStyles = {
  cor: string;
};

type Icon = {
  name: string;
  component: React.ElementType;
};

interface IconSelectorProps {
  onSelect: (icon: Icon) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: (props: PropsStyles) => ({
      display: "flex",
      flexDirection: "column",
      flex: 1,
      overflowY: "auto", // Scroll vertical
      border: `1px solid ${props.cor}`,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1),
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "transparent",
      },
      "&:hover": {
        "&::-webkit-scrollbar-track": {
          background: "black",
        },
        "&::-webkit-scrollbar-thumb": {
          background: props.cor,
          borderRadius: "100px",
        },
      },
    }),
    gridContainer: {
      flex: 1,
      overflow: "hidden", // Remove qualquer overflow dentro do GridContainer
    },
  })
);

const removeAccents = (str: string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const IconSelector: React.FC<IconSelectorProps> = ({ onSelect }) => {
  const [selectedIcon, setSelectedIcon] = useState<Icon | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [numColumns, setNumColumns] = useState<number>(6); // Número inicial de colunas
  const classes = useStyles({ cor: colors.drawer });

  useEffect(() => {
    const handleResize = () => {
      const numCols = Math.floor(window.innerWidth / 100); // Ajuste conforme a largura dos ícones e da janela
      setNumColumns(numCols);
    };

    // Define o número inicial de colunas baseado na largura atual da janela
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleIconClick = (icon: Icon) => {
    setSelectedIcon(icon);
    onSelect(icon);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(removeAccents(event.target.value.toLowerCase()));
  };

  const iconNames = Object.keys(Icons).filter((iconName) =>
    removeAccents(iconName.toLowerCase()).includes(searchTerm)
  );

  const Cell: React.FC<GridChildComponentProps> = ({
    columnIndex,
    rowIndex,
    style,
  }) => {
    const index = rowIndex * numColumns + columnIndex;
    if (index >= iconNames.length) return null;
    const iconName = iconNames[index];
    const IconComponent = Icons[iconName as keyof typeof Icons];
    return (
      <div style={style}>
        <IconButton
          onClick={() =>
            handleIconClick({ name: iconName, component: IconComponent })
          }
          color={selectedIcon?.name === iconName ? "primary" : "default"}
        >
          <IconComponent />
        </IconButton>
      </div>
    );
  };

  const rowCount = Math.ceil(iconNames.length / numColumns);

  return (
    <Box className={classes.container}>
      <TextField
        label="Buscar Ícones"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <div className={classes.gridContainer}>
        <Grid
          columnCount={numColumns}
          columnWidth={100}
          height={500} // Altura fixa para o Grid
          rowCount={rowCount}
          rowHeight={100} // Altura fixa para cada linha do Grid
          width={numColumns * 100} // Largura ajustada conforme o número de colunas
        >
          {Cell}
        </Grid>
      </div>
    </Box>
  );
};

export default IconSelector;
