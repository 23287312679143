import { createAction } from "@reduxjs/toolkit";
import { Cliente } from "../../model/entities/Cliente";
import { OrcamentoServico } from "../../model/entities/OrcamentoServico";
import { DadosCadastroEndereco } from "./orcamentoTypes";
import { Dayjs } from "dayjs";

export const limparOrcamentoAction = createAction(
  "ORCAMENTO_LIMPAR/SET_ORCAMENTO_LIMPAR"
);

export const setClienteOrcamentoAction = createAction<Cliente | null>(
  "CLI_ORC/SET_CLIENTE_ORC"
);

export const setServicosOrcamentoAction = createAction<OrcamentoServico[]>(
  "ORCAMENTO_SERVIO_ORC/SET_ORCAMENTO_SERVICO_ORC"
);

export const setDadosCadastroEnderecoAction =
  createAction<DadosCadastroEndereco>(
    "ORCAMENTO_DADOS_ENDERECO/SET_DADOS_ENDERECO_ORC"
  );

export const setNomeClienteOrcamentoAction = createAction<string>(
  "ORCAMENTO_NOME_CLIENTE/SET_NOME_CLIENTE_ORC"
);

export const setApelidoClienteOrcamentoAction = createAction<string>(
  "ORCAMENTO_APELIDO/SET_APELIDO_ORC"
);

export const setEmailClienteOrcamentoAction = createAction<string>(
  "ORCAMENTO_EMAIL_CLIENTE/SET_NOME_CLIENTE_ORC"
);

export const setDataValidadeOrcamentoAction = createAction<Dayjs>(
  "ORCAMENTO_DATA_VALIDADE/SET_DATA_VALIDADE_ORC"
);

export const setTelefoneClienteOrcamentoAction = createAction<string>(
  "ORCAMENTO_TELEFONE_CLIENTE/SET_NOME_CLIENTE_ORC"
);
