import * as React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, useTheme } from "@mui/material";
import { LISTA_TIPO_TRANSACAO } from "../../constants";

type Props = {
  setTipoTransacao: (p: number) => void;
  label?: string;
};

const TipoTransacaoField: React.FC<Props> = (props) => {
  const { label, setTipoTransacao } = props;

  const theme = useTheme();

  // Defina o valor de xs com base nos breakpoints do tema
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-sexo"
        options={LISTA_TIPO_TRANSACAO}
        getOptionLabel={(option) => option.descricao}
        onChange={(_, value) => {
          if (value) setTipoTransacao(value.codigo);
        }}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={label ? label : "Tipo transação"}
            placeholder={label ? label : "Escolha o tipo da transação"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default TipoTransacaoField;
