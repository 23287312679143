import { useState } from "react";

import { Button, Grid, IconButton, InputAdornment } from "@mui/material";
import UsuarioField from "../../components/fields/UsuarioField";
import Container from "../../components/Generics/Container";
import { Usuario } from "../../model/entities/Usuario";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import PerfilField from "../../components/fields/PerfilField";
import { SaveOutlined } from "@mui/icons-material";
import usuarioApi from "../../api/usuarioApi";
import { Plus } from "@phosphor-icons/react";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {};

const UsuariosPage: React.FC<Props> = (props) => {
  const [usuarioSelecionado, setUsuarioSelecionado] = useState<Usuario | null>(
    null
  );
  const [verSenha, setVerSenha] = useState(false);
  const mostrarSnack = useSnack();

  const salvar = async () => {
    try {
      if (!usuarioSelecionado) return;

      if (!usuarioSelecionado.id) {
        await usuarioApi.insert({
          login: usuarioSelecionado.login,
          nome: usuarioSelecionado.nome,
          perfilId: usuarioSelecionado.perfil.id,
          senha: usuarioSelecionado.senha,
        });
        mostrarSnack(
          `Novo usuário ${usuarioSelecionado.nome} cadastrado.`,
          "success"
        );
        setUsuarioSelecionado(null);
        window.location.reload();
        return;
      }

      await usuarioApi.update(usuarioSelecionado.id, {
        nome: usuarioSelecionado.nome,
        senha: usuarioSelecionado.senha,
        perfilId: usuarioSelecionado.perfil.id,
      });

      setUsuarioSelecionado(null);
      window.location.reload();
    } catch (erro: any) {
      mostrarSnack(
        `Errro ao atualizar informações do usuário ${usuarioSelecionado?.nome}`,
        "error"
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container page>
        <Grid container spacing={2}>
          {(!usuarioSelecionado || !usuarioSelecionado.id) && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                children={"Novo usuário"}
                startIcon={<Plus />}
                onClick={() => setUsuarioSelecionado({ ...new Usuario() })}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <UsuarioField
              usuario={usuarioSelecionado}
              setUsuario={(user) => {
                setUsuarioSelecionado(user);
              }}
              idUsuario={usuarioSelecionado?.id}
            />
          </Grid>

          {usuarioSelecionado && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>Configurações do usuário {usuarioSelecionado.nome}</h2>
              </Grid>

              <Grid item xs={12}>
                <TextFieldGeneric
                  label="LOGIN"
                  disabled={Boolean(usuarioSelecionado.id)}
                  value={usuarioSelecionado.login}
                  onChange={(value) => {
                    setUsuarioSelecionado({
                      ...usuarioSelecionado,
                      login: value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextFieldGeneric
                  label="Nome"
                  value={usuarioSelecionado.nome}
                  onChange={(value) => {
                    setUsuarioSelecionado({
                      ...usuarioSelecionado,
                      nome: value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextFieldGeneric
                  label="Senha"
                  value={usuarioSelecionado.senha}
                  onChange={(value) => {
                    setUsuarioSelecionado({
                      ...usuarioSelecionado,
                      senha: value,
                    });
                  }}
                  type={verSenha ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setVerSenha(!verSenha)}
                        >
                          {verSenha ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <PerfilField
                  perfil={usuarioSelecionado?.perfil ?? null}
                  idPerfil={usuarioSelecionado?.perfil?.id ?? 0}
                  setPerfil={(value) => {
                    if (value) {
                      setUsuarioSelecionado({
                        ...usuarioSelecionado,
                        perfil: value,
                      });
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  children={"Salvar"}
                  onClick={() => salvar()}
                  variant="contained"
                  startIcon={<SaveOutlined />}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default UsuariosPage;
