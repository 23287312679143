import { Typography } from "@mui/material";
import Link from "@material-ui/core/Link";

type Props = {
  textColor?: string;
};

const Copyright: React.FC<Props> = (props) => {
  const { textColor } = props;
  return (
    <Link
      color="inherit"
      // href="https://edilsystem.com.br/site/contato/"
      target="_blank"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="body1"
        color={textColor ? textColor : "textSecondary"}
        align="center"
      >
        Copyright ©
      </Typography>

      <Typography
        variant="body2"
        color={textColor ? textColor : "textSecondary"}
        align="center"
        style={{ margin: "0px 5px", objectFit: "contain" }}
      >
        <b>Ant System</b>
      </Typography>

      <Typography
        variant="body2"
        color={textColor ? textColor : "textSecondary"}
        align="center"
      >
        {new Date().getFullYear()}.
      </Typography>
    </Link>
  );
};

export default Copyright;
