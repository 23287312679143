import * as React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, useTheme } from "@mui/material";
import { ACESSO_OS } from "../../constants";
import { useEffect, useState } from "react";

type Props = {
  value: string;
  setObj: (obj: (typeof ACESSO_OS)[0]) => void;
  disabled?: boolean;
  label: string;
};

const AcessoOSField: React.FC<Props> = (props) => {
  const { value, setObj, disabled, label } = props;

  const theme = useTheme();

  const [statusSelecionado, setCodigoSelecionado] = useState<
    (typeof ACESSO_OS)[0] | null
  >(null);
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  useEffect(() => {
    const status = ACESSO_OS.find((item) => item.descricaoEnum === value);
    if (status) {
      setCodigoSelecionado(status);
    }
  }, [value]);

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-acesso-os"
        options={ACESSO_OS}
        getOptionLabel={(option) => option.decricao}
        value={statusSelecionado}
        onChange={(event, value) => {
          if (value) setObj(value);
        }}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={disabled}
            variant={"outlined"}
            label={label}
            placeholder={label}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default AcessoOSField;
