/* eslint-disable @typescript-eslint/no-non-null-assertion */

export function parseData(data: string) {
  data = data.toString();
  return data.slice(6, 8) + "/" + data.slice(4, 6) + "/" + data.slice(0, 4);
}

export function desinverteData(data: string) {
  data = data.toString();
  return data.slice(6, 8) + data.slice(4, 6) + data.slice(0, 4);
}

export function inverteData(data: string) {
  data = data.toString();
  return data.slice(4, 8) + data.slice(2, 4) + data.slice(0, 2);
}

export function objectHasKey(obj: any, key: string): boolean {
  return Object.prototype.hasOwnProperty.call(obj, key);
}
export const formatMilliseconds = (ms: number) => {
  const totalSeconds = ms / 1000;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};
