import { useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import ClienteField from "../../../components/fields/ClienteField";
import DatePickerGeneric from "../../../components/Generics/DatePickerGeneric";
import Titulo4 from "../../../components/Generics/Titulo4";
import ButtonGeneric from "../../../components/Generics/ButtonGeneric";
import {
  useClienteOrcamento,
  useDataValidadeOrcamento,
} from "../../../redux/orcamento/orcamentoHooks";
import dayjs from "dayjs";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import theme from "../../../theme";
import ModalGeneric from "../../../components/Generics/ModalGeneric";
import NovoClientePage from "../../clientes/NovoClientePage";

const PropiedadesOrçamento: React.FC = () => {
  const [cliente, setCliente] = useClienteOrcamento();
  const [, setValidadeProposta] = useDataValidadeOrcamento();
  const [data, setData] = useState(dayjs());
  const isSmallSScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openNovoCliente, setOpenNovoCliente] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DatePickerGeneric
          label="Validade da proposta"
          pageLS="PropiedadesOrçamento"
          keyLS="validadeProposta"
          value={data}
          setValue={(value) => {
            setValidadeProposta(value);
            setData(value);
          }}
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Titulo4>Selecione o cliente. Ou cadastre o mesmo</Titulo4>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{ textAlign: isSmallSScreen ? "center" : "right" }}
        >
          <ButtonGeneric
            label={"Novo cliente"}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              setOpenNovoCliente(true);
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <ClienteField
            cliente={cliente}
            setCliente={(value) => {
              console.log("value", value);
              setCliente(value);
            }}
          />
        </Grid>
      </Grid>
      <ModalGeneric
        open={openNovoCliente}
        onClose={() => setOpenNovoCliente(false)}
      >
        <NovoClientePage
          cadastrando
          onSave={(cliente) => {
            setCliente(cliente);
            setOpenNovoCliente(false);
          }}
        />
      </ModalGeneric>
    </Grid>
  );
};

export default PropiedadesOrçamento;
