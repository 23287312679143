import { useState } from "react";
import { Button, Grid } from "@mui/material";
import Container from "../../components/Generics/Container";
import { Perfil } from "../../model/entities/Perfil";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import PerfilField from "../../components/fields/PerfilField";
import { SaveOutlined } from "@mui/icons-material";
import { Plus } from "@phosphor-icons/react";
import CheckBoxGeneric from "../../components/Generics/CheckBoxGeneric";
import perfilApi from "../../api/perfilApi";
import AcessoOSField from "../../components/fields/AcessoOSField";
import AcessoOramentoField from "../../components/fields/AcessoOrcamentoField";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {};

const PerfisPage: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const mostrarSnack = useSnack();
  const [perfilSelecionado, setPerfilSelecionado] = useState<Perfil | null>(
    null
  );

  const salvar = async () => {
    try {
      if (!perfilSelecionado) return;
      setLoading(true);
      if (!perfilSelecionado.id) {
        await perfilApi.insert(perfilSelecionado);
        mostrarSnack(
          `Novo perfil ${perfilSelecionado.descricao} cadastrado.`,
          "success"
        );
        setPerfilSelecionado(null);
        setLoading(false);
        return;
      }

      await perfilApi.update(perfilSelecionado.id, perfilSelecionado);

      setPerfilSelecionado(null);
      setLoading(false);
    } catch (erro: any) {
      setLoading(false);
      mostrarSnack(
        `Errro ao atualizar informações do perfil ${perfilSelecionado?.descricao}`,
        "error"
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container page loading={loading}>
        <Grid container spacing={2}>
          {(!perfilSelecionado || !perfilSelecionado.id) && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                children={"Novo perfil"}
                startIcon={<Plus />}
                onClick={() => setPerfilSelecionado({ ...new Perfil() })}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <PerfilField
              perfil={perfilSelecionado}
              setPerfil={(user) => {
                setPerfilSelecionado(user);
              }}
              idPerfil={perfilSelecionado?.id}
            />
          </Grid>

          {perfilSelecionado && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2>Configurações do perfil {perfilSelecionado.descricao}</h2>
              </Grid>

              <Grid item xs={12}>
                <TextFieldGeneric
                  label="Descrição"
                  value={perfilSelecionado.descricao}
                  onChange={(value) => {
                    setPerfilSelecionado({
                      ...perfilSelecionado,
                      descricao: value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <CheckBoxGeneric
                  label={"Pode acessar aba financeiro"}
                  value={perfilSelecionado.acessaFinanceiro}
                  onChange={(value) => {
                    setPerfilSelecionado({
                      ...perfilSelecionado,
                      acessaFinanceiro: value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <CheckBoxGeneric
                  label={"Pode cadastrar novos serviços"}
                  value={perfilSelecionado.cadastraServico}
                  onChange={(value) => {
                    setPerfilSelecionado({
                      ...perfilSelecionado,
                      cadastraServico: value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <AcessoOramentoField
                  label="Acesso a orçamentos"
                  value={perfilSelecionado.acessoOrcamentoPerfil}
                  setObj={(value) => {
                    console.log(value);
                    setPerfilSelecionado({
                      ...perfilSelecionado,
                      acessoOrcamentoPerfil: value.descricaoEnum,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <AcessoOSField
                  label="Acesso a OS"
                  value={perfilSelecionado.acessoOSPerfil}
                  setObj={(value) => {
                    setPerfilSelecionado({
                      ...perfilSelecionado,
                      acessoOSPerfil: value.descricaoEnum,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  children={"Salvar"}
                  onClick={() => salvar()}
                  variant="contained"
                  startIcon={<SaveOutlined />}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default PerfisPage;
