import { useEffect, useState } from "react";
import GridCenter from "../../components/Generics/GridCenter";
import CachedIcon from "@material-ui/icons/Cached";

import zapApi from "../../api/zapApi";
import Container from "../../components/Generics/Container";
import { Alert, Grid, IconButton } from "@mui/material";
import { useSnack } from "../../redux/snack/snackHooks";
import QrCodeWhatsApp from "./QrCodeWhatsApp";

const ZapPage: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(false);
  const mostrarSnack = useSnack();

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = async () => {
    setLoading(true);

    zapApi
      .getStatus()
      .then((res) => {
        setStatus(res);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        mostrarSnack("Não consegui verificar o status do Whats App", "info");
      });
  };
  return (
    <GridCenter>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <IconButton onClick={getStatus}>
          <CachedIcon style={{ fontSize: 62, margin: "15px" }} />
        </IconButton>
      </Grid>
      <Grid item xs={12} md={12}>
        {status ? (
          <Alert
            elevation={6}
            variant="filled"
            severity="success"
            style={{
              borderRadius: "25px",
              fontSize: "22px",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            Serviço de WhatsApp conectado.
          </Alert>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert
                elevation={6}
                variant="filled"
                severity="error"
                style={{
                  borderRadius: "25px",
                  fontSize: "22px",
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Serviço de WhatsApp desconectado.
              </Alert>
            </Grid>

            <Grid item xs={12}></Grid>
            <QrCodeWhatsApp onReload={getStatus} />
          </Grid>
        )}
      </Grid>
    </GridCenter>
  );
};

export default ZapPage;
