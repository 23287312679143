import { createStyles, Grid, makeStyles } from "@material-ui/core";
import React from "react";

import { hexWithAlpha } from "../../helper";
import UploadButton from "./UploadButton";
import ImageGallery from "./ImageGallery";

type Props = {
  imagens: string[];
  onUpload: (event: React.ChangeEvent<HTMLInputElement>, arquivo: File) => void;
  onDelete: (index: number) => void;
  title: string;
  max?: number;
  disabled?: boolean;
};

const ImageFieldSelector: React.FC<Props> = (props) => {
  const { imagens, onUpload, onDelete, title, max, disabled } = props;
  const classes = useStyles({});

  const showUploadButton = !disabled && (!max || max < imagens.length);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ImageGallery imagens={imagens} onDelete={(index) => onDelete(index)} />
      </Grid>

      {showUploadButton && (
        <Grid item xs={12} className={classes.centerDiv}>
          <UploadButton
            onUpload={(e, file) => onUpload(e, file)}
            title={title}
            onlyImages={true}
          />
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: any) =>
  createStyles({
    image: {
      maxWidth: 300,
      maxHeight: 300,
      borderRadius: 7,
      overflow: "hidden",
      backgroundColor: hexWithAlpha(theme.palette.primary.main, 0.5),
      objectFit: "contain",
    },
    centerDiv: { display: "flex", justifyContent: "center" },
  })
);

export default ImageFieldSelector;
