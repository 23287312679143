import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#0a4b75",
    color: "#eeeeee",
    hoverBg: "#1e6495",
    activeBg: "#fb6f24",
  },

  mainBg: colors.grey["100"],
};

export default colorConfigs;
