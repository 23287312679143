import {
  Button,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import { useSessao } from "../../redux/sessao/sessaoHooks";
import { ModalCfgApp } from "../../components/modais/ModalCfgApp";
import theme from "../../theme";
import ProjeteLogo from "../../assets/LogoProjete.png";
import FooterTelaLogin from "./FooterTelaLogin";
import { useSnack } from "../../redux/snack/snackHooks";

const LoginPage: React.FC = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const mostrarSnack = useSnack();

  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [verSenha, setVerSenha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessao, login, revalidate] = useSessao();
  const classes = useStyles();
  const [openModalCfg, setOpenModalCfg] = useState(false);

  const onLogin = async () => {
    try {
      setLoading(true);
      await login(usuario, senha);
      setLoading(false);
    } catch (err: any) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      mostrarSnack(`${err}`, "error");
      console.log("Erro no login", err);
    }
  };

  useEffect(() => {
    const callback = (event: any) => {
      if (event.key === "*") {
        event.preventDefault();
        setOpenModalCfg(true);
      }
    };
    document.addEventListener("keydown", callback);

    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, []);

  return (
    <Grid className={classes.body}>
      <Grid
        item
        xs={12}
        style={{
          background: isSmallScreen ? "#0F1F2C" : "#ffffff",
          height: "100%",
          color: "#ffffff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Paper className={classes.form} elevation={15}>
          <TextField
            className={classes.input}
            style={{ marginBottom: "25px" }}
            label="Usuário"
            value={usuario}
            onChange={(event) => setUsuario(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                onLogin();
              }
            }}
            disabled={loading}
          />

          <TextField
            className={classes.input}
            label="Senha"
            style={{ marginBottom: "25px" }}
            value={senha}
            onChange={(event) => setSenha(event.target.value)}
            type={verSenha ? "text" : "password"}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                onLogin();
              }
            }}
            disabled={loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setVerSenha(!verSenha)}
                  >
                    {verSenha ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            onClick={() => {
              onLogin();
            }}
          >
            Entrar
          </Button>
        </Paper>

        {isSmallScreen && <FooterTelaLogin />}
      </Grid>

      <Hidden mdDown>
        <Grid item xs={12} md={6} className={classes.rightSide}>
          <img src={ProjeteLogo} alt="Sispro Logo" className={classes.logo} />
        </Grid>
      </Hidden>

      {openModalCfg && (
        <ModalCfgApp
          open={openModalCfg}
          onClose={() => setOpenModalCfg(false)}
        />
      )}
    </Grid>
  );
};

export default LoginPage;

const useStyles = makeStyles({
  body: {
    overflow: "hidden ",
    display: "grid",
    gridTemplateColumns: "0.4fr 1fr",
    height: "100vh",
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr", // Uma coluna quando a largura da tela for menor ou igual a 768px
    },
  },
  leftSide: {
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      height: "50%",
    },
  },
  rightSide: {
    background: "rgb(15, 31, 44)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    "@media (max-width: 768px)": {
      height: "100%",
    },
  },
  input: {
    width: "100%",
    minWidth: "250px",
    marginBottom: "35px",
    "@media (max-width: 768px)": {
      minWidth: "200px",
      marginBottom: "20px",
    },
  },
  form: {
    background: "transparent",
    color: "#000",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    "@media (max-width: 768px)": {
      minWidth: "200px",
      marginBottom: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: "350px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      padding: "20px",
    },
  },
  logo: {
    maxWidth: "70%",
    maxHeight: "70%",
    objectFit: "contain",
    "@media (max-width: 768px)": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
});
