import React, { useEffect, useState } from "react";
import { Grid, LinearProgress } from "@mui/material";

import ModalGeneric from "../../../components/Generics/ModalGeneric";
import dayjs, { Dayjs } from "dayjs";
import { Parcela } from "../../../model/types/ContasPagarTypes";
import DatePickerGeneric from "../../../components/Generics/DatePickerGeneric";
import TextFieldGeneric from "../../../components/Generics/TextFieldGeneric";
import Titulo2 from "../../../components/Generics/Titulo2";
import NumberFieldGeneric from "../../../components/Generics/NumberFieldGeneric";
import CheckBoxGeneric from "../../../components/Generics/CheckBoxGeneric";
import { arredondar, formatarDinheiro } from "../../../helper";
import ListaParcelas from "../ContaPagar/ListaParcelas";
import ContaCaixaField from "../../../components/fields/ContaCaixaField";
import { ContaCaixa } from "../../../model/entities/ContaCaixa";
import { DadosCadastrarTransacao } from "../../../model/types/TransacaoTypes";
import transacaoApi from "../../../api/transacaoApi";
import transacaoRecorrenteApi from "../../../api/transacaoRecorrenteApi";
import {
  TIPO_LANCAMENTO_CAIXA_CREDITO,
  TIPO_LANCAMENTO_CAIXA_DEBITO,
} from "../../../constants";
import ButtonGeneric from "../../../components/Generics/ButtonGeneric";
import { CategoriaFinanceiro } from "../../../model/entities/CategoriaFinanceiro";
import CategoriaFinanceiroField from "../../../components/fields/CategoriaFinanceiroField";
import FormaPagamentoField from "../../../components/fields/FormaPagamentoField";
import { FormaPagamento } from "../../../model/entities/FormaPagamento";
import { useSnack } from "../../../redux/snack/snackHooks";

type Props = {
  open: boolean;
  onClose: () => void;
  transacao:
    | typeof TIPO_LANCAMENTO_CAIXA_DEBITO
    | typeof TIPO_LANCAMENTO_CAIXA_CREDITO;
};

const ModalCadastrarTransacao: React.FC<Props> = (props) => {
  const { open, onClose, transacao } = props;

  const mostrarSnack = useSnack();
  const [valor, setValor] = useState(0);
  const [descricao, setDescricao] = useState("");
  const [numeroParcelas, setNumeroParcelas] = useState(1);
  const [dataEmissao, setDataEmissao] = useState(dayjs());
  const [dataVencimento, setDataVencimento] = useState(dayjs());
  const [parcelas, setParcelas] = useState<Parcela[]>([]);
  const [contaCaixa, setContaCaixa] = useState<ContaCaixa | null>(null);
  const [dividirValor, setDividirValor] = useState(false);
  const [replicarValor, setReplicarValor] = useState(true);
  const [cadastrando, setCadastrando] = useState(false);
  const [categoria, setCategoria] = useState<CategoriaFinanceiro | null>(null);
  const [formaPagamento, setFormaPagamento] = useState<FormaPagamento | null>(
    null
  );

  useEffect(() => {
    if (replicarValor) {
      let novasParcelas: Parcela[] = [];
      for (let i = 0; i < numeroParcelas; i++) {
        const vencimento: Dayjs = dayjs(dataVencimento).add(i + 1, "month");
        if (i === 0) {
          novasParcelas.push({
            valorParcela: valor,
            vencimento: vencimento,
            numero: i + 1,
          });
        } else {
          novasParcelas.push({
            valorParcela: valor,
            vencimento: vencimento,
            numero: i + 1,
          });
        }
      }
      setParcelas(novasParcelas);
    } else if (dividirValor) {
      calculaParcelas();
    }
  }, [numeroParcelas, valor, dataVencimento, replicarValor, dividirValor]);

  const calculaParcelas = () => {
    const valorParcelas = Number((valor / numeroParcelas).toFixed(2));

    const newParcelas: Parcela[] = [];

    for (let i = 0; i < numeroParcelas; i++) {
      let valorPrimeiraParcela =
        valorParcelas + (valor - valorParcelas * numeroParcelas);
      const vencimento: Dayjs = dayjs(dataVencimento).add(i + 1, "month");
      if (i === 0) {
        newParcelas.push({
          valorParcela: valorPrimeiraParcela,
          vencimento: vencimento,
          numero: i + 1,
        });
      } else {
        newParcelas.push({
          valorParcela: valorParcelas,
          vencimento: vencimento,
          numero: i + 1,
        });
      }
    }
    setParcelas(newParcelas);
  };

  const salvar = async () => {
    try {
      if (valor === 0 || !valor) {
        mostrarSnack("Por favor informe o valor.", "info");
        return;
      }

      let somaParcelas = parcelas.reduce(
        (acumulador, parcela) => acumulador + parcela.valorParcela,
        0
      );

      somaParcelas = arredondar(somaParcelas, 2);

      let resto = arredondar(valor - somaParcelas, 2);
      // if (resto > 0) {
      //   toast.info(
      //     `Atenção, faltam R$${formatarDinheiro(resto)} na soma das parcelas.`
      //   );
      //   return;
      // } else if (resto !== 0) {
      //   toast.info(
      //     `Atenção, está passando R$${formatarDinheiro(
      //       Math.abs(resto)
      //     )} na soma das parcelas.`
      //   );
      //   return;
      // }

      if (!formaPagamento || !formaPagamento.id) {
        mostrarSnack(
          `Atenção selecione a forma de pagamento da transação.`,
          "info"
        );
        return;
      }

      if (!categoria || !categoria.id) {
        mostrarSnack(
          `Atenção selecione a categoria que a transação pertence.`,
          "info"
        );
        return;
      }

      setCadastrando(true);
      //aqui eu vou cadastrar so se for maior que 1
      if (numeroParcelas > 1) {
        const transacaoRecorrente = await transacaoRecorrenteApi.insert({
          dataVencimento: dataVencimento.toDate(),
          dataEmissao: dataEmissao.toDate(),
          dataFim: parcelas[parcelas.length - 1].vencimento.toDate(),
          dataInicio: dataEmissao.toDate(),
          frequencia: numeroParcelas,
          icone: "",
          recorrencia: 1, //fazer algo para falar se é mensal ou não....
          // recorrencia:numeroParcelas,
          titulo: descricao,
          valor: valor,
          contaCaixaId: 1,
          tipoTransacao: transacao,
          categoriaId: categoria.id,
        });

        if (!transacaoRecorrente) {
          setCadastrando(false);
          return;
        }
        for (let x = 0; x < parcelas.length; x++) {
          const parcela = parcelas[x];
          const dados: DadosCadastrarTransacao = {
            dataVencimento: parcela.vencimento.toDate(),
            dataEmissao: dataEmissao.toDate(),
            titulo: descricao,
            valor: parcela.valorParcela,
            contaCaixaId: 1,
            tipoTransacao: transacao,
            pago: false,
            idTransacaoRecorrente: transacaoRecorrente.id,
            categoriaId: categoria.id,
            formaPagamentoId: formaPagamento?.id,
          };
          await transacaoApi.insert(dados);
        }
      } else {
        for (let x = 0; x < parcelas.length; x++) {
          const parcela = parcelas[x];
          const dados: DadosCadastrarTransacao = {
            dataVencimento: parcela.vencimento.toDate(),
            dataEmissao: dataEmissao.toDate(),
            titulo: descricao,
            valor: parcela.valorParcela,
            contaCaixaId: 1,
            tipoTransacao: transacao,
            pago: false,
            categoriaId: categoria.id,
            formaPagamentoId: formaPagamento?.id,
          };
          await transacaoApi.insert(dados);
        }
      }
      mostrarSnack("Transação cadastrada!", "success");
      onClose();
    } catch (erro: any) {
      mostrarSnack("Erro ao cadastrar transação", "error");
      console.log("Erro ao cadastrar transação", erro);
    }
    setCadastrando(false);
  };

  if (cadastrando) {
    return (
      <ModalGeneric open={cadastrando} onClose={() => {}}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo2>
            Cadastrando{" "}
            {transacao === TIPO_LANCAMENTO_CAIXA_DEBITO ? "Despesa" : "Receita"}{" "}
            ...
          </Titulo2>
          <LinearProgress />
        </Grid>
      </ModalGeneric>
    );
  }

  return (
    <ModalGeneric open={open} onClose={onClose}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Titulo2>
            Nova{" "}
            {transacao === TIPO_LANCAMENTO_CAIXA_DEBITO ? "Despesa" : "Receita"}
          </Titulo2>
        </Grid>

        <Grid item xs={6}>
          <DatePickerGeneric
            label="Data emissão"
            value={dataEmissao}
            setValue={(value) => setDataEmissao(value)}
          />
        </Grid>

        <Grid item xs={6}>
          <DatePickerGeneric
            label="Data vencimento"
            value={dataVencimento}
            setValue={(value) => setDataVencimento(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldGeneric
            label="Título"
            value={descricao}
            onChange={(value) => setDescricao(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <FormaPagamentoField
            formaPagamento={formaPagamento}
            setFormaPagamento={(obj) => {
              setFormaPagamento(obj);
            }}
          />
        </Grid>
        {/* 
        <Grid item xs={12}>
          <ContaCaixaField
            contaCaixaId={contaCaixa?.id ?? 0}
            setContaCaixa={(obj) => {
              setContaCaixa(obj);
            }}
          />
        </Grid> */}

        <Grid item xs={12}>
          <CategoriaFinanceiroField
            categoria={categoria}
            idCategoria={categoria?.id ?? 0}
            setCategoria={(obj) => {
              setCategoria(obj);
            }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <NumberFieldGeneric
            label="Valor"
            type="money"
            value={valor}
            onChange={(value) => setValor(value)}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <NumberFieldGeneric
            maxLength={2}
            label="Nº parcelas"
            value={numeroParcelas}
            onChange={(value) => setNumeroParcelas(value)}
          />
        </Grid>

        {numeroParcelas > 0 && (
          <>
            <Grid item md={6} xs={6}>
              <CheckBoxGeneric
                label={`Dividir ${formatarDinheiro(
                  valor
                )} em ${numeroParcelas} parcelas`}
                value={dividirValor}
                onChange={(value) => {
                  setDividirValor(value);
                  setReplicarValor(false);
                }}
              />
            </Grid>

            <Grid item md={6} xs={6}>
              <CheckBoxGeneric
                label={`Replicar ${formatarDinheiro(
                  valor
                )} em ${numeroParcelas} parcelas`}
                value={replicarValor}
                onChange={(value) => {
                  setReplicarValor(value);
                  setDividirValor(false);
                }}
              />
            </Grid>
          </>
        )}

        {parcelas.length > 0 && (
          <Grid item xs={12}>
            <ListaParcelas
              valorPrazo={replicarValor ? valor * numeroParcelas : valor}
              parcelas={parcelas}
              setParcelas={setParcelas}
            />
          </Grid>
        )}

        <Grid item xs={12} textAlign={"center"}>
          <ButtonGeneric
            variant="contained"
            label={"Salvar"}
            onClick={() => {
              salvar();
            }}
          />
        </Grid>
      </Grid>
    </ModalGeneric>
  );
};

export default ModalCadastrarTransacao;
