import { createStyles, Grid, IconButton, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { hexWithAlpha } from "../../helper";
import SwipeableViews from "react-swipeable-views";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import DeleteIcon from "@material-ui/icons/Delete";

import NoImage from "../../assets/no-image.png";
import { colors } from "../../theme";

type Props = {
  imagens: string[];
  onDelete?: (index: number) => void;
};

const ImageGallery: React.FC<Props> = (props) => {
  const { imagens, onDelete } = props;
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  const mudarIndex = (novoIndex: number) => {
    if (novoIndex < 0 || novoIndex >= imagens.length) {
      return;
    }
    setIndex(novoIndex);
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={1}
        className={classes.centerDiv}
        onClick={() => mudarIndex(index - 1)}
      >
        {imagens.length < 2 ? <div /> : <ArrowLeftIcon />}
      </Grid>

      <Grid item xs={10} className={classes.centerDiv}>
        <SwipeableViews index={index} onChangeIndex={(i) => setIndex(i)}>
          {imagens.map((img, i) => (
            <SingleImage
              key={"image" + i}
              img={img}
              onDelete={onDelete ? () => onDelete(i) : undefined}
            />
          ))}
        </SwipeableViews>

        {!imagens.length && <img src={NoImage} className={classes.image} />}
      </Grid>
      <Grid
        item
        xs={1}
        className={classes.centerDiv}
        onClick={() => mudarIndex(index + 1)}
      >
        {imagens.length < 2 ? <div /> : <ArrowRightIcon />}
      </Grid>

      {imagens.length > 1 && (
        <Grid item xs={12} className={classes.centerDiv}>
          {imagens.map((img, i) => (
            <div
              key={"dot" + i}
              className={classes.circle}
              style={
                i === index
                  ? { backgroundColor: colors.darkGrey }
                  : { backgroundColor: colors.grey }
              }
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

type Props2 = {
  img: string;
  onDelete?: () => void;
};

const SingleImage: React.FC<Props2> = (props) => {
  const { img, onDelete } = props;
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState("");

  const getImageUrl = async () => {
    if (!img) {
      return setImageUrl("");
    }

    return setImageUrl(img);
  };

  useEffect(() => {
    getImageUrl();
  }, [img]);

  return (
    <div key={img} className={classes.centerDiv}>
      {onDelete && <div className={classes.btnDelete} />}
      <img src={imageUrl} className={classes.image} />
      {onDelete && (
        <IconButton onClick={() => onDelete()} className={classes.btnDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      width: 200,
      height: 200,
      borderRadius: 7,
      overflow: "hidden",
      backgroundColor: hexWithAlpha(theme.palette.primary.main, 0.5),
      objectFit: "contain",
    },
    centerDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: 100,
      marginLeft: 5,
      marginRight: 5,
    },
    btnDelete: { width: 40, height: 40, alignSelf: "flex-start" },
  })
);

export default ImageGallery;
