import { createReducer } from "@reduxjs/toolkit";
import { Empresa } from "../../model/entities/Empresa";
import { setEmpresaAction } from "./empresaActions";

const empresaReducer = createReducer<Empresa | null>(null, {
  [setEmpresaAction.toString()]: (state, action) => {
    if (setEmpresaAction.match(action)) {
      if (!action.payload) {
        return state;
      } else {
        state = { ...action.payload };
      }
    }

    return state;
  },
});

export default empresaReducer;
