import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { colors } from "../../theme";

type Props = {
  style?: React.CSSProperties;
  className?: string;
  color?: string;
  disableMargin?: boolean;
  children: any;
};

const Titulo2: React.FC<Props> = (props) => {
  const { children, style, color, disableMargin, ...other } = props;
  const classes = useStyles();

  let marginStyles;
  if (disableMargin) {
    marginStyles = { marginTop: 0, marginBottom: 0 };
  } else {
    marginStyles = { marginTop: 10, marginBottom: 10 };
  }

  return (
    <h2
      style={{ ...style, ...marginStyles, color }}
      className={clsx(classes.titulo, props.className)}
      {...other}
    >
      {children}
    </h2>
  );
};

const useStyles = makeStyles({
  titulo: {
    color: colors.darkGrey,
  },
});

export default Titulo2;
