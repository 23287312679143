import { ServicoFilho } from "./ServicoFilho";

export class Servico {
  id!: number;

  titulo!: string;

  descricao!: string;

  createdAt?: Date;

  updatedAt?: Date;

  valor!: number;

  servicoPai!: boolean;

  servicosFilhos!: ServicoFilho[];

  constructor(servicoFilho: ServicoFilho) {
    this.id = servicoFilho.servicoFilho.id;
    this.titulo = servicoFilho.servicoFilho.titulo;
    this.valor = servicoFilho.servicoFilho.valor;
  }
}
