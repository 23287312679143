import * as React from "react";
import TextField from "@mui/material/TextField";

import { Autocomplete, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Perfil } from "../../model/entities/Perfil";
import perfilApi from "../../api/perfilApi";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  setPerfil: (p: Perfil | null) => void;
  perfil: Perfil | null;
  label?: string;
  idPerfil?: number;
};

const PerfilField: React.FC<Props> = (props) => {
  const { setPerfil, label, idPerfil } = props;

  const [perfilSelecionado, setPerfilSelecionado] = useState<Perfil | null>(
    null
  );
  const [perfils, setPerfils] = useState<Perfil[]>([]);
  const mostrarSnack = useSnack();

  useEffect(() => {
    buscarPerfis();
  }, []);

  const buscarPerfis = async () => {
    try {
      const res = await perfilApi.getManyComplete();
      setPerfils(res);

      if (idPerfil) {
        const perfilEncontrado = res.find((item) => item.id === idPerfil);
        if (perfilEncontrado) {
          setPerfil(perfilEncontrado);
          setPerfilSelecionado(perfilEncontrado);
        }
      }
    } catch (erro: any) {
      mostrarSnack("Erro ao buscar lista de perfils", "error");
      console.log("erro ao buscar perfils", erro);
    }
  };
  const theme = useTheme();

  // Defina o valor de xs com base nos breakpoints do tema
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;
  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-perfil"
        options={perfils}
        getOptionLabel={(option) => `${option.descricao}`}
        onChange={(event, value) => {
          setPerfilSelecionado(value);
          setPerfil(value);
        }}
        value={perfilSelecionado}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={label ? label : "Perfis"}
            placeholder={"Perfil"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default PerfilField;
