import { Divider, Paper, makeStyles } from "@material-ui/core";
import dayjs, { Dayjs } from "dayjs";

import React, { useEffect, useState } from "react";
import { Parcela } from "../../../model/types/ContasPagarTypes";
import Titulo2 from "../../../components/Generics/Titulo2";
import { Alert, Box, Grid, Typography } from "@mui/material";
import {
  arredondar,
  formatarDinheiro,
  parseDiaDaSemana,
} from "../../../helper";
import { GridColDef } from "@mui/x-data-grid";
import NumberFieldGeneric from "../../../components/Generics/NumberFieldGeneric";
import DatePickerGeneric from "../../../components/Generics/DatePickerGeneric";
import MiniInfo from "../../../components/Generics/MiniInfo";
import { DIA_DA_SEMANA_DOMINGO } from "../../../constants";
import { colors } from "../../../theme";

type Props = {
  parcelas: Parcela[];
  setParcelas: (parcelas: Parcela[]) => void;
  valorPrazo: number;
};

const ListaParcelas: React.FC<Props> = (props) => {
  const { parcelas, setParcelas, valorPrazo } = props;
  const classes = useStyles();
  const [mensagemAviso, setMensagemAviso] = useState("");
  const [hoje] = useState(dayjs());

  const mudarVencimento = (data: Dayjs | null, index: number) => {
    if (data === null) {
      return;
    }
    let novasParcelas = [...parcelas];
    const parcela = { ...novasParcelas[index] };
    parcela.vencimento = data;
    novasParcelas[index] = parcela;
    novasParcelas = verificarParcelas(novasParcelas);
    setParcelas(novasParcelas);
  };

  const mudarValor = (valor: number, index: number) => {
    let novasParcelas = [...parcelas];
    const parcela = { ...novasParcelas[index] };
    parcela.valorParcela = valor;
    novasParcelas[index] = parcela;
    novasParcelas = verificarParcelas(novasParcelas);
    setParcelas(novasParcelas);
  };

  const verificarParcelas = (parcelas: Parcela[]) => {
    let novasParcelas = [...parcelas];
    let somaParcelas = calcularSomaParcelas(novasParcelas);
    let resto = arredondar(valorPrazo - somaParcelas, 2);

    if (resto <= 0.02 && resto >= -0.02) {
      const parcelaInicial = { ...novasParcelas[0] };
      parcelaInicial.valorParcela = parcelaInicial.valorParcela + resto;
      novasParcelas[0] = parcelaInicial;
      setMensagemAviso("");
    } else if (resto > 0) {
      setMensagemAviso(
        `Atenção, faltam R$${formatarDinheiro(resto)} na soma das parcelas.`
      );
    } else {
      setMensagemAviso(
        `Atenção, está passando R$${formatarDinheiro(
          Math.abs(resto)
        )} na soma das parcelas.`
      );
    }
    return novasParcelas;
  };

  const calcularSomaParcelas = (parcelasAtuais?: Parcela[]) => {
    if (!parcelasAtuais) {
      parcelasAtuais = parcelas;
    }

    let somaParcelas = parcelasAtuais.reduce(
      (acumulador, parcela) => acumulador + parcela.valorParcela,
      0
    );

    somaParcelas = arredondar(somaParcelas, 2);
    return somaParcelas;
  };

  useEffect(() => {
    let novasParcelas = verificarParcelas(parcelas);
    setParcelas(novasParcelas);
  }, [valorPrazo]);

  useEffect(() => {
    let diffTotal = 0;
    for (let i = 0; i < parcelas.length; i++) {
      const parcela = parcelas[i];
      const vencimentoObj = dayjs(parcela.vencimento, "DD/MM/YYYY");

      const diff = vencimentoObj.diff(hoje, "days");
      diffTotal += diff + 1;
    }

    verificarParcelas(parcelas);
  }, [parcelas]);

  return (
    <>
      <Grid item xs={12}>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <Titulo2 style={{ marginTop: 10 }}>Parcelas</Titulo2>
      </Grid>
      {mensagemAviso && (
        <Grid item xs={12}>
          <Alert elevation={6} variant="filled" severity="error">
            {mensagemAviso}
          </Alert>
        </Grid>
      )}
      {parcelas.map((parcela, index) => {
        return (
          <Paper style={{ margin: "8px 0px", padding: "12px" }}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <Typography fontWeight={"bold"} variant="h6">
                  {parcela.numero}º Parcela
                </Typography>
              </Grid>

              <Grid item md={3} xs={12} style={{ justifyContent: "center" }}>
                <NumberFieldGeneric
                  label="Valor"
                  type="money"
                  value={parcela.valorParcela}
                  variant={"outlined"}
                  autoSelect
                  textAlign="center"
                  onChange={(novoValor) => mudarValor(novoValor, index)}
                />
              </Grid>

              <Grid
                item
                md={6}
                xs={12}
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                {(() => {
                  const vencimentoObj = dayjs(parcela.vencimento, "DD/MM/YYYY");
                  return (
                    <Grid container>
                      <Grid item md={8}>
                        <DatePickerGeneric
                          label={"Data vencimento"}
                          value={vencimentoObj}
                          setValue={(value) => mudarVencimento(value, index)}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <MiniInfo
                          style={{ margin: 3 }}
                          color={
                            vencimentoObj?.get("day").toString() ===
                            DIA_DA_SEMANA_DOMINGO
                              ? colors.red
                              : colors.lightGreen
                          }
                        >
                          {parseDiaDaSemana(
                            vencimentoObj.get("day").toString()
                          )}
                        </MiniInfo>

                        <MiniInfo style={{ margin: 3 }} color={colors.blue}>
                          Vence em {vencimentoObj.diff(hoje, "days") + 1} dias
                        </MiniInfo>
                      </Grid>
                    </Grid>
                  ); // Retornando JSX que usa a variável x
                })()}
              </Grid>
            </Grid>
          </Paper>
        );
      })}

      {/* <Grid item xs={12}>
        <TableGeneric
          // chave="ListaParcelas2"
          colunas={columns}
          rowHeight={100}
          getRowId={(row) => row.vencimento}
          linhas={parcelas}
          loading={false}
          totalLinhas={0}
          setPaginacao={() => {}}
          formasDeListar={[]} // stringTabelaVazia="Nenhuma parcela"
        />
      </Grid> */}
    </>
  );
};

// const styles = () => ({
// });

const useStyles = makeStyles({
  row: {
    display: "flex",
    // flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default ListaParcelas;
