import * as React from "react";
import TextField from "@mui/material/TextField";

import { Autocomplete, useTheme } from "@mui/material";
import { TIPOS_ENDERECOS } from "../../constants";
import { useState } from "react";

type Props = {
  sx: number;
  setTipoEndereco: (p: (typeof TIPOS_ENDERECOS)[0]) => void;
};

const TipoEnderecoField: React.FC<Props> = (props) => {
  const { sx, setTipoEndereco } = props;

  const [tipoEnderecoSelecionado, setTipoEnderecoSelecionado] = useState<
    (typeof TIPOS_ENDERECOS)[0] | null
  >(null);

  const theme = useTheme();

  // Defina o valor de xs com base nos breakpoints do tema
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-tipo-endereco"
        options={TIPOS_ENDERECOS}
        getOptionLabel={(option) => option.descricao}
        onChange={(event, value) => {
          if (value) {
            setTipoEndereco(value);
            setTipoEnderecoSelecionado(value);
          }
        }}
        value={tipoEnderecoSelecionado}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={"Tipo do endereço"}
            placeholder={"Tipo Endereço"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default TipoEnderecoField;
