import React from "react";
import * as Icons from "@mui/icons-material";

// Defina um tipo para o mapeamento de ícones
type IconMap = { [key: string]: React.ElementType };

// Crie um objeto contendo todos os ícones disponíveis
const createIconMap = (): IconMap => {
  const iconMap: IconMap = {};
  Object.keys(Icons).forEach((iconName) => {
    iconMap[iconName] = Icons[iconName as keyof typeof Icons];
  });
  return iconMap;
};

interface IconRendererProps {
  iconName: string; // Tipo do nome do ícone
}

const IconRenderer: React.FC<IconRendererProps> = ({ iconName }) => {
  // Obtenha o ícone do mapa de ícones
  const iconMap = createIconMap();
  const IconComponent = iconMap[iconName];

  if (!IconComponent) {
    // Se o ícone não existir, renderize uma mensagem de erro ou um ícone padrão
    return <div>Ícone não encontrado</div>;
  }

  return (
    // Renderize o ícone utilizando o componente de ícone correspondente
    <IconComponent />
  );
};

export default IconRenderer;
