import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import appStateSlice from "./features/appStateSlice";
import sessaoReducer from "./sessao/sessaoReducer";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import cfgGeralReducer from "./cfgGeral/cfgGeralReducer";
import orcamentoReducer from "./orcamento/orcamentoReducer";
import empresaReducer from "./empresa/empresaReducer";
import snackReducer from "./snack/snackReducer";

export const rootReducer = combineReducers({
  appState: appStateSlice,
  sessao: sessaoReducer,
  cfgGeral: cfgGeralReducer,
  orcamento: orcamentoReducer,
  empresa: empresaReducer,
  snack: snackReducer,
});

const persistConfig = {
  key: "@redux",
  storage,
  blacklist: ["snack", "loadingGeral", "dialog"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootAppState = ReturnType<typeof persistedReducer>;

const store = configureStore({
  reducer: persistedReducer,
  // middleware: getDefaultMiddleware({
  //   serializableCheck: {
  //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //   },
  // }),
});
export const persistor = persistStore(store);

export default store;
