import { makeStyles, Typography, TypographyStyle } from "@material-ui/core";
import React from "react";
import { colors } from "../../theme";

type Props = {
  color?: string;
  fontColor?: string;
  style?: TypographyStyle;
  children: React.ReactNode;
};

const MiniInfo: React.FC<Props> = (props) => {
  const { children, color, fontColor, style, ...other } = props;
  const classes = useStyles();

  let newColor = color;
  if (!newColor) {
    newColor = colors.red;
  }

  if (typeof children !== "string") {
    return (
      <div
        className={classes.warning}
        style={{
          textAlign: "center",
          backgroundColor: newColor,
          color: fontColor,
          ...style,
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <Typography
      className={classes.warning}
      style={{
        textAlign: "center",
        backgroundColor: newColor,
        color: fontColor,
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};

MiniInfo.propTypes = {
  // // classes: PropTypes.object.isRequired,
  // value: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.number
  // ]),
  // onChange: PropTypes.func.isRequired,
  // label: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  warning: {
    padding: 3,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    color: colors.white,
    fontSize: 10,
    fontWeight: "bold",
  },
});

export default MiniInfo;
