import { CategoriaFinanceiro } from "../model/entities/CategoriaFinanceiro";
import { ResponseGenericType } from "../model/types/ResponseType";
import api from "./api";

const ROUTE = "categoriaFinanceiro";

class contaCaixaApi {
  async getMany(pagina: number, linhasPorPagina: number) {
    try {
      const res = await api.get<ResponseGenericType<CategoriaFinanceiro[]>>(
        `/${ROUTE}?size=${linhasPorPagina}&page=${pagina}`
      );
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getManyComplete() {
    try {
      const res = await api.get<CategoriaFinanceiro[]>(`/${ROUTE}/complete`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(categoria: CategoriaFinanceiro) {
    try {
      const res = await api.post<CategoriaFinanceiro>(`/${ROUTE}`, categoria);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(idCategoria: number, categoria: CategoriaFinanceiro) {
    try {
      const res = await api.put<CategoriaFinanceiro>(
        `/${ROUTE}/${idCategoria}`,
        categoria
      );
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async delete(idCategoria: number) {
    try {
      const res = await api.delete<string>(`/${ROUTE}/${idCategoria}`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new contaCaixaApi();
