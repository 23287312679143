import { Orcamento } from "../model/entities/Orcamento";
import { Servico } from "../model/entities/Servico";
import { DadosListagemOS, DadosOS } from "../model/types/OSTypes";
import {
  DadosCadastrarOrcamentoType,
  DadosListagemServicoOrcamento,
} from "../model/types/OrcamentoType";
import { ResponseGenericType } from "../model/types/ResponseType";
import api from "./api";

const ROUTE = "ordemServico";

class servicoApi {
  async insert(dados: DadosCadastrarOrcamentoType) {
    try {
      const res = await api.post<Servico>(`/${ROUTE}`, dados);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getMany(pagina: number, linhasPorPagina: number) {
    try {
      const res = await api.get<ResponseGenericType<DadosListagemOS[]>>(
        `/${ROUTE}?size=${linhasPorPagina}&page=${pagina}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getServicos(idOrcamento: number) {
    try {
      const res = await api.get<DadosListagemServicoOrcamento[]>(
        `/${ROUTE}/servicos/${idOrcamento}`
      );
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(dados: DadosOS) {
    try {
      const res = await api.put<Orcamento>(`/${ROUTE}`, dados);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async delete(idOrcamentoServico: number) {
    try {
      const res = await api.delete<Orcamento>(
        `/${ROUTE}/${idOrcamentoServico}`
      );
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async findById(osId: string) {
    try {
      const res = await api.get<DadosOS>(`/${ROUTE}/${osId}`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new servicoApi();
