import * as React from "react";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  Appointments,
  ViewSwitcher,
  Toolbar,
  DateNavigator,
  TodayButton,
  MonthView,
  WeekView,
  AppointmentForm,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import dayjs from "dayjs";
import TooltipAgenda from "./TooltipAgenda";
import { useEffect, useState } from "react";
import FormularioCadastrarAgendameto from "./FormularioAgenda";
import { Agendamento } from "../../model/entities/Agendamento";
import agendamentoApi from "../../api/agendamentoApi";
import DialogConfirmGeneric from "../../components/Generics/DialogConfirmGeneric";
import Container from "../../components/Generics/Container";
import GridCenter from "../../components/Generics/GridCenter";
import { useSnack } from "../../redux/snack/snackHooks";

const CustonViewSwitcher: React.FC<ViewSwitcher.SwitcherProps> = ({
  children,
  ...restProps
}: any) => (
  <ViewSwitcher.Switcher {...restProps}>{children}</ViewSwitcher.Switcher>
);

const CustonDateNavigator: React.FC<DateNavigator.NavigationButtonProps> = ({
  ...restProps
}) => <DateNavigator.NavigationButton {...restProps} />;

const Button: React.FC<TodayButton.ButtonProps> = ({ ...restProps }) => (
  <TodayButton.Button
    {...restProps}
    getMessage={() => "Hoje"}
    style={{
      backgroundColor: "#fff",
      borderRadius: "8px",
    }}
  />
);

const CustonMonthView: React.FC<MonthView.LayoutProps> = ({ ...restProps }) => (
  <MonthView.Layout {...restProps} style={{ height: "800px" }} />
);

const Appointment: React.FC<Appointments.AppointmentProps> = ({
  ...restProps
}) => (
  <Appointments.Appointment
    {...restProps}
    style={{ borderRadius: "8px" }}
    onDoubleClick={() => {
      console.log("double click in the appointment");
    }}
  />
);

const CustonAppointmentTooltip: React.FC<AppointmentTooltip.ContentProps> = (
  props
) => {
  return <TooltipAgenda id={props.appointmentData?.id} />;
};

const CustomCommandButton = (props: AppointmentForm.CommandLayoutProps) => {
  return null;
};

const Agenda = () => {
  const mostrarSnack = useSnack();

  const [openALertExcluirAgendamento, setOpenALertExcluirAgendamento] =
    useState(false);
  const [idAgendamentoDeletar, setIdAgendamentoDeletar] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(true);

  const excluirAgendamento = async () => {
    try {
      await agendamentoApi.delete(idAgendamentoDeletar);
      mostrarSnack("Agendamento excluido!", "info");
      setOpenALertExcluirAgendamento(false);
      setIsTooltipVisible(true);
      getAgendamentos();
    } catch (erro) {
      mostrarSnack("Não foi possível deletar o agendamento", "error");
    }
  };

  const CustomAppointmentTooltipLayout: React.FC<
    AppointmentTooltip.LayoutProps
  > = (props) => {
    return (
      <AppointmentTooltip.Layout
        {...props}
        contentComponent={CustonAppointmentTooltip}
        onDeleteButtonClick={() => {
          const id = Number(props.appointmentMeta?.data.id);
          setIdAgendamentoDeletar(id ?? 0);
          setOpenALertExcluirAgendamento(true);
          setIsTooltipVisible(false);
        }}
      />
    );
  };

  const CustonAppoitmentFormBase: React.FC<AppointmentForm.BasicLayoutProps> = (
    props
  ) => {
    return (
      <div style={{ padding: "20px" }}>
        <FormularioCadastrarAgendameto
          dataAgendamento={dayjs(props.appointmentData.startDate).format(
            "DD/MM/YYYY"
          )}
          idAgendamento={Number(props.appointmentData.id) ?? 0}
          onReload={getAgendamentos}
        />
      </div>
    );
  };
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [openForm, setOpenForm] = useState(false);
  const [agendamentos, setAgendamentos] = useState<Agendamento[]>([]);

  useEffect(() => {
    getAgendamentos();
  }, []);

  const getAgendamentos = async () => {
    try {
      const res = await agendamentoApi.getManyComplete();
      setAgendamentos(res);
    } catch (erro) {
      mostrarSnack("Não consegui buscar os agendamentos", "error");
      console.log('"Não consegui buscar os agendamentos', erro);
    }
  };

  return (
    <GridCenter>
      <Container page>
        <Scheduler data={agendamentos} locale={"pt-BR"}>
          <ViewState
            currentDate={currentDate.toDate()}
            onCurrentDateChange={(x) => setCurrentDate(dayjs(x))}
          />

          <MonthView displayName="Mês" layoutComponent={CustonMonthView} />
          <WeekView
            startDayHour={5}
            endDayHour={23}
            displayName="Semana"
            cellDuration={60}
          />
          <DayView
            startDayHour={5}
            endDayHour={23}
            displayName="Dia"
            cellDuration={60}
          />

          <Appointments appointmentComponent={Appointment} />

          {isTooltipVisible && (
            <AppointmentTooltip
              showCloseButton
              showOpenButton
              showDeleteButton
              layoutComponent={CustomAppointmentTooltipLayout}
            />
          )}

          <AppointmentForm
            basicLayoutComponent={CustonAppoitmentFormBase}
            commandLayoutComponent={CustomCommandButton}
            onVisibilityChange={(valor) => setOpenForm(valor)}
            visible={openForm}
          />

          <Toolbar />
          <DateNavigator navigationButtonComponent={CustonDateNavigator} />
          <ViewSwitcher switcherComponent={CustonViewSwitcher} />
          <TodayButton buttonComponent={Button} />
        </Scheduler>

        <DialogConfirmGeneric
          open={openALertExcluirAgendamento}
          cabecalho={"Excluir ?"}
          texto={
            "Deseja realmente excluir o agendamento? Essa ação não poderá ser desfeita."
          }
          onResposta={(res) => {
            if (res) {
              excluirAgendamento();
            } else {
              setOpenALertExcluirAgendamento(false);
              setIsTooltipVisible(true);
            }
          }}
        />
      </Container>
    </GridCenter>
  );
};

export default Agenda;
