import { useDispatch, useSelector } from "react-redux";
import { loginAction, logoutAction, removeTokenAction } from "./sessaoActions";
import { SessaoState } from "./sessaoTypes";
import { RootAppState } from "../store";
import authApi from "../../api/authApi";

export const useSessao = (): [
  SessaoState,
  (username: string, senha: string, browserID?: string) => Promise<void>,
  () => Promise<void>,
  () => Promise<void>,
  (token: string) => Promise<void>
] => {
  const sessao = useSelector((state: RootAppState) => state.sessao);

  const dispatch = useDispatch();
  const login = async (username: string, senha: string, browserID?: string) => {
    const sessao = await authApi.login(username, senha);
    const action = loginAction(sessao);

    dispatch(action);
  };

  const revalidate = async () => {
    try {
      if (!sessao.tokenJWT) return;

      // const newSessao = await authApi.revalidate();
      // if (!newSessao.token || !newSessao.usuario) {
      //   throw new Error('Erro no login automático.');
      // }
      // const action = loginAction(newSessao);
      // dispatch(action);
    } catch (err) {
      console.log("Revalidate error: ", err);
      throw err;
    }
  };

  const logout = async () => {
    const action = logoutAction();
    dispatch(action);
    // const limparPedido = limparTudoPMAction();
    // dispatch(limparPedido);

    const limparToken = removeTokenAction();
    dispatch(limparToken);
  };

  const trocaToken = async (newToken: string) => {
    const newSessao = { ...sessao, token: newToken };
    const action = loginAction(newSessao);
    dispatch(action);
  };

  return [sessao, login, revalidate, logout, trocaToken];
};

// export const useVendedorSessao = () => {
//   const [sessao] = useSessao();
//   return sessao.usuario?.vendedor;
// };
