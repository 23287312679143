import { Empresa } from "../../model/entities/Empresa";
import { useDispatch, useSelector } from "react-redux";
import { RootAppState } from "../store";
import { setEmpresaAction } from "./empresaActions";

export const useEmpresa = (): [
  Empresa | null,
  (empresa: Empresa | null) => void
] => {
  const dispatch = useDispatch();
  const empresa = useSelector((state: RootAppState) => state.empresa);
  const setEmpresa = (empresa: Empresa | null) => {
    const action = setEmpresaAction(empresa);
    dispatch(action);
  };

  return [empresa, setEmpresa];
};
