export class DadosPortifolio {
  id!: number;
  nome!: string;
  areaAtuacao!: string;
  areaEspecializacao!: string;
  objetivo!: string;
  fraseApresentação!: string;
  contato!: string;
  email!: string;
}
