import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import { FormaPagamento } from "../../model/entities/FormaPagamento";

import TableGeneric from "../../components/Generics/TableGeneric";
import formaPagamentoApi from "../../api/formaPagamentoApi";
import { Button } from "@material-ui/core";
import ModalGeneric from "../../components/Generics/ModalGeneric";
import { useSnack } from "../../redux/snack/snackHooks";

const columns: FormaPagamento[] | any[] = [
  {
    field: "id",
    headerName: "id",
    width: 5,
    editable: false,
  },
  {
    field: "descricao",
    headerName: "Descricao",
    width: 230,
    editable: false,
  },
];

const FormasPagamentoPage: React.FC = () => {
  const [formasPagamento, setFormasPagamento] = useState<FormaPagamento[]>([]);
  const [totalLinhas, setTotalLinhas] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagina, setPagina] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [descricao, setDescricao] = useState("");
  const mostrarSnack = useSnack();

  useEffect(() => {
    buscarFormasPagamento();
  }, [pagina, linhasPorPagina]);

  const buscarFormasPagamento = async () => {
    try {
      setLoading(true);
      const count = await formaPagamentoApi.count();
      setTotalLinhas(count);

      const res = await formaPagamentoApi.getMany(pagina, linhasPorPagina);
      setFormasPagamento(res);
      setLoading(false);
    } catch (erro: any) {
      mostrarSnack(
        "Ops, aconteceu um problema ao buscar as formas de pagamento!!",
        "error"
      );
      console.log("Erro ao buscar formasPagamento", erro);
      setLoading(false);
    }
  };

  const cadastrarFormaPagamento = async () => {
    try {
      setLoading(true);

      await formaPagamentoApi.insert({ descricao: descricao });
      setOpenModal(false);
      await buscarFormasPagamento();

      setLoading(false);
    } catch (erro: any) {
      mostrarSnack(
        "Ops, aconteceu um problema ao cadastrar forma de pagamento!!",
        "error"
      );
      console.log("Erro ao buscar formasPagamento", erro);
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} textAlign={"right"}>
        <Button
          onClick={() => {
            setOpenModal(true);
          }}
          variant="contained"
          children={"Nova forma de pagamento"}
        />
      </Grid>

      <Grid item xs={12}>
        <TableGeneric
          pageSize={linhasPorPagina}
          colunas={columns}
          linhas={formasPagamento}
          loading={loading}
          totalLinhas={totalLinhas}
          selecionaLinha={false}
          formasDeListar={[5, 10]}
          onRowClick={(params) => {}}
          setPaginacao={(model) => {
            setPagina(model.page);
            setLinhasPorPagina(model.pageSize);
          }}
        />
      </Grid>

      <ModalGeneric
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          buscarFormasPagamento();
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1>Nova forma de pagamento</h1>
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label="Descricao"
              maxLength={25}
              value={descricao}
              onChange={(value) => {
                setDescricao(value);
              }}
            />
          </Grid>

          <Grid item xs={12} textAlign={"center"}>
            <Button
              variant="contained"
              onClick={() => {
                cadastrarFormaPagamento();
              }}
              children={"Salvar"}
            />
          </Grid>
        </Grid>
      </ModalGeneric>
    </Grid>
  );
};

export default FormasPagamentoPage;
