import { Perfil } from "../model/entities/Perfil";
import { ResponseGenericType } from "../model/types/ResponseType";
import api from "./api";

const ROUTE = "perfil";

class perfilApi {
  async getMany(pagina: number, linhasPorPagina: number) {
    try {
      const res = await api.get<ResponseGenericType<Perfil[]>>(
        `/${ROUTE}?size=${linhasPorPagina}&page=${pagina}`
      );
      const data = res.data.content;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getManyComplete() {
    try {
      const res = await api.get<Perfil[]>(`/${ROUTE}/complete`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(perfil: Perfil) {
    try {
      const res = await api.post<Perfil>(`/${ROUTE}`, perfil);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(idPerfil: number, perfil: Perfil) {
    try {
      const res = await api.put<Perfil>(`/${ROUTE}/${idPerfil}`, perfil);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new perfilApi();
