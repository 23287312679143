import React, { useRef } from "react";

import { ReactElement } from "react";
import ButtonGeneric from "./ButtonGeneric";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  onUpload: (event: React.ChangeEvent<HTMLInputElement>, arquivo: File) => void;
  title?: string;
  icon?: ReactElement;
  onlyImages?: boolean;
};

const UploadButton: React.FC<Props> = (props) => {
  const { onUpload, title, icon, onlyImages } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const mostrarSnack = useSnack();
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      let files: File[] = [];
      if (e.target.files && e.target.files.length > 0) {
        files = Array.from(e.target.files);
      }

      let file = files[0];

      // if (file.type.startsWith('image')) {
      //   file = await scaleImage(file);
      // }

      onUpload(e, file);

      e.target.value = "";
    } catch (err: any) {
      console.log("err", err);
      mostrarSnack(err.data ?? err.message, "error");
    }
  };

  return (
    <>
      <ButtonGeneric
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
        startIcon={icon}
        label={title ?? "Upload"}
      ></ButtonGeneric>

      <input
        ref={inputRef}
        accept={onlyImages ? ".png,.jpg,.jpeg" : ".xml"}
        type="file"
        style={{ display: "none" }}
        id="single"
        onChange={onChange}
      />
    </>
  );
};

export default UploadButton;
