import React from "react";
import ProjeteLogo from "../../assets/LogoPro.png";

const FooterTelaLogin: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        flexDirection: "column",
        marginTop: "85px",
      }}
    >
      <img src={ProjeteLogo} alt="Ant System logo" width={150} />
    </div>
  );
};

export default FooterTelaLogin;
