import {
  Agendamento,
  DadosAtualizarCadastrarAgendamento,
} from "../model/entities/Agendamento";
import api from "./api";

const ROUTE = "agendamento";

class agendamentoApi {
  async getById(id: number) {
    try {
      const res = await api.get<Agendamento>(`/${ROUTE}/${id}`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async getManyComplete() {
    try {
      const res = await api.get<Agendamento[]>(`/${ROUTE}/complete`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async insert(dados: DadosAtualizarCadastrarAgendamento) {
    try {
      const res = await api.post<Agendamento>(`/${ROUTE}`, dados);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async count() {
    try {
      const res = await api.get<number>(`/${ROUTE}/count`);
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async update(
    idAgendamento: number,
    agendamento: DadosAtualizarCadastrarAgendamento
  ) {
    try {
      const res = await api.put<Agendamento>(
        `/${ROUTE}/${idAgendamento}`,
        agendamento
      );
      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async delete(id: number) {
    try {
      const res = await api.delete<Agendamento>(`/${ROUTE}/${id}`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new agendamentoApi();
