import { Perfil } from "./Perfil";

export class Usuario {
  id!: number;

  nome!: string;

  senha!: string;

  login!: string;

  perfil!: Perfil;
}
