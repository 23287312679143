import * as React from "react";
import TextField from "@mui/material/TextField";

import { Autocomplete, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Usuario } from "../../model/entities/Usuario";
import usuarioApi from "../../api/usuarioApi";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  setUsuario: (p: Usuario | null) => void;
  usuario: Usuario | null;
  label?: string;
  idUsuario?: number;
  disabled?: boolean;
  helperText?: string;
  error?: boolean | undefined;
};

const UsuarioField: React.FC<Props> = (props) => {
  const { setUsuario, label, idUsuario, disabled, error, helperText } = props;

  const [usuarioSelecionado, setUsuarioSelecionado] = useState<Usuario | null>(
    null
  );
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const mostrarSnack = useSnack();

  useEffect(() => {
    buscarUsuarios();
  }, []);

  const buscarUsuarios = async () => {
    try {
      const res = await usuarioApi.getManyComplete();
      setUsuarios(res);

      if (idUsuario) {
        const usuarioEncontrado = res.find((item) => item.id === idUsuario);
        if (usuarioEncontrado) {
          setUsuario(usuarioEncontrado);
          setUsuarioSelecionado(usuarioEncontrado);
        }
      }
    } catch (erro: any) {
      mostrarSnack("Erro ao buscar lista de usuarios", "error");
      console.log("erro ao buscar usuarios", erro);
    }
  };

  const theme = useTheme();
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        disabled={disabled}
        id="filter-tipo-endereco"
        options={usuarios}
        getOptionLabel={(option) => `${option.nome}`}
        onChange={(event, value) => {
          setUsuarioSelecionado(value);
          setUsuario(value);
        }}
        value={usuarioSelecionado}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            variant={"outlined"}
            label={label ? label : "Usuários"}
            placeholder={"Usuario"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default UsuarioField;
