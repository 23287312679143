import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hideSnackAction } from "../../redux/snack/snackActions";
import { RootAppState } from "../../redux/store";

type Props = {};

const SnackRedux: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const snackState = useSelector((state: RootAppState) => state.snack);

  useEffect(() => {
    if (snackState.show) {
      let emoji;
      switch (snackState.tipo) {
        case "error":
          // emoji = '❌';
          emoji = "✖";
          toast.error(
            `${emoji} ${snackState.mensagem}`,
            snackState.options
              ? snackState.options
              : {
                  position: "top-center",
                  style: {
                    background: "#ff4d4f",
                    color: "#fff",
                    fontWeight: "bold",
                  },
                }
          );
          break;
        case "info":
          emoji = "";
          toast.info(
            `${emoji} ${snackState.mensagem}`,
            snackState.options
              ? snackState.options
              : {
                  position: "top-center",
                }
          );
          break;
        case "success":
          // emoji = '✔️';
          emoji = "✔";
          toast.success(
            `${emoji} ${snackState.mensagem}`,
            snackState.options ? snackState.options : {}
          );
          break;
      }
      const action = hideSnackAction();
      dispatch(action);
    }
  }, [snackState]);

  return <ToastContainer />;
};

export default SnackRedux;
