import { Checkbox, FormControlLabel } from "@mui/material";

type Props = {
  label: string;
  value: boolean;
  fontSize?: number;
  onChange: (value: boolean) => void;
};

export default function CheckBoxGeneric(props: Props) {
  const { label, value, fontSize, onChange } = props;

  return (
    <FormControlLabel
      checked={value}
      control={<Checkbox />}
      onChange={(event, checked) => onChange(checked)}
      label={<span style={{ fontSize: fontSize ? fontSize : 20 }}>{label}</span>}
    />
  );
}
