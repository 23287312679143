import DashboardPageLayout from "../../components/layout/DashboardPageLayout";

import HomePage from "../../pages/home/HomePage";
import ListagemClientesPage from "../../pages/clientes/ListagemClientesPage";
import NovoClientePage from "../../pages/clientes/NovoClientePage";
import { RouteType } from "../config";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

const grupoServico: RouteType = {
  path: "/cliente",
  element: <DashboardPageLayout />,
  state: "cliente",
  sidebarProps: {
    displayText: "Clientes",
    icon: <EmojiPeopleIcon />,
  },

  child: [
    {
      index: true,
      element: <HomePage />,
      state: "cliente.index",
    },
    {
      path: "/cliente/cadastro",
      element: <NovoClientePage />,
      state: "cliente.cadastro",
      sidebarProps: {
        displayText: "Cadastrar cliente",
      },
    },
    {
      path: "/cliente/listagem",
      element: <ListagemClientesPage />,
      state: "cliente.listagem",
      sidebarProps: {
        displayText: "Lista de clientes",
      },
    },

    {
      path: "/cliente/cadastro/:id",
      element: <NovoClientePage />,
      state: "cliente.id",
      verificarPermissao: (usuario) => false,
      sidebarProps: {
        displayText: "Lista de clientes",
      },
    },
  ],
};

export default grupoServico;
