import { Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { DadosPortifolio } from "../../model/entities/DadosPortifolio";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import Container from "../../components/Generics/Container";
import Titulo2 from "../../components/Generics/Titulo2";
import GridCenter from "../../components/Generics/GridCenter";
import ImageFieldSelector from "../../components/Generics/ImageFieldSelector";
import { useSnack } from "../../redux/snack/snackHooks";
import portifolioApi from "../../api/portifolioApi";
import ButtonGeneric from "../../components/Generics/ButtonGeneric";
import VisibilityIcon from "@material-ui/icons/Visibility";
export default function PortifolioPage() {
  const abrirNovaPagina = () => {
    window.open("/portifolio", "_blank"); // Abre a página em uma nova aba
  };

  const [dadosPortifolio, setDadosPortifolio] = useState<DadosPortifolio>(
    new DadosPortifolio()
  );

  const mostrarSnack = useSnack();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDados();
  }, []);

  const getDados = async () => {
    try {
      setLoading(true);
      const res = await portifolioApi.get();
      if (res) setDadosPortifolio(res);
      setLoading(false);
    } catch (erro: any) {
      setLoading(false);

      mostrarSnack(
        "Não foi possível encontrar os dados do portfólio ",
        "error"
      );
      console.log("Não foi possível encontrar os dados do portifolio", erro);
    }
  };

  const salvar = async () => {
    try {
      setLoading(true);
      if (dadosPortifolio.id) {
        await portifolioApi.update(dadosPortifolio);
      } else {
        await portifolioApi.insert(dadosPortifolio);
      }
      mostrarSnack("Portifolio atualizado!", "success");
      setLoading(false);
    } catch (erro: any) {
      setLoading(false);

      mostrarSnack("Não foi possível salvar os dados do portfólio ", "error");
      console.log("Não foi possível salvar os dados do portifolio", erro);
    }
  };

  return (
    <GridCenter>
      <Container page loading={loading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Titulo2>Informações para o portifolio</Titulo2>

              <IconButton onClick={abrirNovaPagina}>
                <VisibilityIcon />
              </IconButton>
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"Seu nome"}
              value={dadosPortifolio.nome}
              onChange={(value) =>
                setDadosPortifolio({ ...dadosPortifolio, nome: value })
              }
            ></TextFieldGeneric>
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"Area de atuação"}
              value={dadosPortifolio.areaAtuacao}
              onChange={(value) =>
                setDadosPortifolio({ ...dadosPortifolio, areaAtuacao: value })
              }
            ></TextFieldGeneric>
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"Area de especialização"}
              value={dadosPortifolio.areaEspecializacao}
              onChange={(value) =>
                setDadosPortifolio({
                  ...dadosPortifolio,
                  areaEspecializacao: value,
                })
              }
            ></TextFieldGeneric>
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"Telefone para contato"}
              mask="(99)999999999"
              value={dadosPortifolio.contato}
              onChange={(value) =>
                setDadosPortifolio({
                  ...dadosPortifolio,
                  contato: value,
                })
              }
            ></TextFieldGeneric>
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"E-mail para contato"}
              value={dadosPortifolio.email}
              onChange={(value) =>
                setDadosPortifolio({
                  ...dadosPortifolio,
                  email: value,
                })
              }
            ></TextFieldGeneric>
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"Frase apresentação"}
              minRows={4}
              value={dadosPortifolio.fraseApresentação}
              onChange={(value) =>
                setDadosPortifolio({
                  ...dadosPortifolio,
                  fraseApresentação: value,
                })
              }
            ></TextFieldGeneric>
          </Grid>

          <Grid item xs={12}>
            <ImageFieldSelector
              title="Selecionar imagem"
              imagens={
                []
                // logoBase64 ? [`data:image/jpeg;base64,${logoBase64}`] : []
              } // Passando uma array com a imagem base64 se existir
              onUpload={(_, file) => {
                // setLogo(file);
              }}
              onDelete={() => {}}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <ButtonGeneric label={"Salvar"} onClick={salvar} />
          </Grid>
        </Grid>
      </Container>
    </GridCenter>
  );
}
