import * as React from "react";
import TextField from "@mui/material/TextField";

import { Autocomplete, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Servico } from "../../model/entities/Servico";
import servicoApi from "../../api/servicoApi";
import { useSnack } from "../../redux/snack/snackHooks";

type Props = {
  setServicosSelecionados: (s: Servico[]) => void;
  servicosSelecionados: Servico[];
  label?: string;
  apenasFilhos?: boolean;
  idServicoAtual?: number;
};

const MultiServicoField: React.FC<Props> = (props) => {
  const {
    setServicosSelecionados,
    servicosSelecionados,
    label,
    apenasFilhos,
    idServicoAtual,
  } = props;

  const [servicos, setServicos] = useState<Servico[]>([]);
  const mostrarSnack = useSnack();

  useEffect(() => {
    buscarServicos();
  }, []);

  const buscarServicos = async () => {
    try {
      const res = await servicoApi.getManyComplete();

      if (apenasFilhos) {
        const servicosQueSaoFilhos = res.filter((item) => !item.servicoPai);
        setServicos(servicosQueSaoFilhos);
      } else {
        setServicos(res);
      }
    } catch (erro: any) {
      mostrarSnack("Erro ao buscar lista de servicos", "error");
      console.log("erro ao buscar servicos", erro);
    }
  };

  const theme = useTheme();
  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;

  console.log("idServicoAtual", idServicoAtual);

  // Filtrar serviços para remover os já selecionados e o serviço atual
  const servicosFiltrados = servicos.filter(
    (servico) =>
      !servicosSelecionados.some((s) => s.id === servico.id) &&
      servico.id !== idServicoAtual
  );
  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        multiple={true}
        id="filter-tipo-servios"
        options={servicosFiltrados}
        getOptionLabel={(option) => option.titulo}
        onChange={(event, value) => {
          if (value) {
            setServicosSelecionados(value);
          } else {
            setServicosSelecionados([]);
          }
        }}
        value={servicosSelecionados}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={label ? label : "Serviço"}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default MultiServicoField;
