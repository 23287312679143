import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import zapApi from "../../api/zapApi";
import Container from "../../components/Generics/Container";

type Props = {
  onReload: () => void;
};

const QrCodeWhatsApp: React.FC<Props> = (props) => {
  const { onReload } = props;
  const [qrCode, setQrCode] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(15);

  useEffect(() => {
    fetchNewQrCode();

    const interval = setInterval(() => {
      if (qrCode) {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
        if (secondsLeft === 0) {
          fetchNewQrCode();
        }
        setSecondsLeft(15);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchNewQrCode = async () => {
    try {
      setLoading(true);
      const newQrCode = await zapApi.generateQrCode();
      setQrCode(newQrCode);
    } catch (err) {
      console.log("Erro buscar QR Code:", err);
    } finally {
      setLoading(false);
      onReload();
    }
  };

  return (
    <>
      {qrCode && (
        <Container loading={loading} style={{ textAlign: "center" }}>
          <QRCode value={qrCode ?? ""} size={450} />

          {secondsLeft !== 15 && <div>Expira em: {secondsLeft} segundos</div>}
        </Container>
      )}
    </>
  );
};

export default QrCodeWhatsApp;
