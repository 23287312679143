import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, useTheme } from "@mui/material";
import { LISTA_PRIORIDADE_AGENDAMENTO } from "../../constants";

type Props = {
  setPrioridade: (p: number) => void;
  prioridade: number;
  placeholder?: string;
};

const PrioriradadeAgendamentoField: React.FC<Props> = (props) => {
  const { setPrioridade, prioridade, placeholder } = props;

  const theme = useTheme();

  const xsValue = theme.breakpoints.down("sm") ? 12 : 6;
  const [prioridadeObj, setPrioridadeObj] = useState<
    (typeof LISTA_PRIORIDADE_AGENDAMENTO)[0]
  >(LISTA_PRIORIDADE_AGENDAMENTO[0]);

  useEffect(() => {
    const prioridadeEncontrada = LISTA_PRIORIDADE_AGENDAMENTO.find(
      (item) => item.codigo === prioridade
    );
    if (prioridadeEncontrada) {
      setPrioridade(prioridadeEncontrada.codigo);
      setPrioridadeObj(prioridadeEncontrada);
    }
  }, [prioridade]);

  return (
    <div style={{ textAlign: "center" }}>
      <Autocomplete
        id="filter-prioridade"
        options={LISTA_PRIORIDADE_AGENDAMENTO}
        getOptionLabel={(option) => option.descricao}
        value={prioridadeObj}
        onChange={(event, value) => {
          if (value) {
            setPrioridade(value.codigo);
            setPrioridadeObj(value);
          }
        }}
        sx={{ xsValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            label={"Prioridade"}
            placeholder={placeholder ? placeholder : ""}
            multiline={true}
          />
        )}
      />
    </div>
  );
};

export default PrioriradadeAgendamentoField;
