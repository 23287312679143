import { CssBaseline } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import store from './redux/store';
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") 
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(function(registration) {
      console.log('Service Worker registrado com sucesso:', registration);
    }).catch(function(error) {
      console.error('Falha ao registrar o Service Worker:', error);
    });
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CssBaseline />
      <App />
    </Provider>
  </React.StrictMode>
);
