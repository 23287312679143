import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import "dayjs/locale/pt-br";
import "dayjs/locale/pt-br";
import { usePageStorage } from "../../helper";
import { useEffect } from "react";
import { DateView } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

type Props = {
  label: string;
  value: Dayjs;
  setValue: (value: Dayjs) => void;
  minDate?: dayjs.Dayjs;
  disabled?: boolean;
  keyLS?: string;
  pageLS?: string;
  views?: DateView[];
};

export default function DatePickerGeneric(props: Props) {
  const { value, setValue, label, minDate, disabled, keyLS, pageLS, views } =
    props;

  const [data, setData] = usePageStorage(
    pageLS ?? "GERAL",
    keyLS ?? "EdDateField",
    value?.format("DD/MM/YYYY")
  );

  useEffect(() => {
    if (keyLS && pageLS && data) {
      const obj = dayjs(data, "DD/MM/YYYY");

      if (value && value.isValid() && value.format("DD/MM/YYYY") === data) {
        return;
      }

      if (obj.isValid()) {
        setValue(obj);
        return;
      }
    }

    if (value && value.isValid()) {
      return;
    }

    setValue(dayjs());
  }, []);

  useEffect(() => {
    setData(value?.format("DD/MM/YYYY"));
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        disabled={disabled}
        format="DD/MM/YYYY"
        views={views ?? views}
        onChange={(newValue) => {
          if (newValue) setValue(newValue);
        }}
        minDate={minDate}
      />
    </LocalizationProvider>
  );
}
