import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import servicoApi from "../../api/servicoApi";
import { useNavigate } from "react-router-dom";
import TableGeneric from "../../components/Generics/TableGeneric";
import { formatarDinheiro } from "../../helper";
import { GridColDef } from "@mui/x-data-grid";
import ButtonGeneric from "../../components/Generics/ButtonGeneric";
import { DadosListagemServico } from "../../model/types/ServicoTypes";
import { useSnack } from "../../redux/snack/snackHooks";
import Container from "../../components/Generics/Container";

const columns: GridColDef<DadosListagemServico>[] = [
  {
    field: "id",
    headerName: "id",
    width: 5,
    editable: false,
  },
  {
    field: "titulo",
    headerName: "Titulo",
    width: 230,
    editable: false,
  },
  {
    field: "descricao",
    headerName: "Descrição",
    width: 650,
    editable: false,
  },
  {
    field: "valor",
    headerName: "Valor",
    width: 500,
    editable: false,
    renderCell: (params) => {
      return formatarDinheiro(params.row.valor);
    },
  },
];

type Props = {};

const ListagemServicoPage: React.FC<Props> = (props) => {
  const [servicos, setServicos] = useState<DadosListagemServico[]>([]);
  const [totalLinhas, setTotalLinhas] = useState(0);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [pagina, setPagina] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(5);
  const mostrarSnack = useSnack();

  useEffect(() => {
    buscarServicos();
  }, []);

  const buscarServicos = async () => {
    try {
      setLoading(true);
      const count = await servicoApi.count();
      setTotalLinhas(count);

      const res = await servicoApi.getMany(pagina, linhasPorPagina);
      setServicos(res);
      setLoading(false);
    } catch (erro: any) {
      mostrarSnack(
        "Ops); Aconteceu um problema ao buscar os servicos !!",
        "error"
      );
      console.log("erro ao buscar servicos", erro);
      setLoading(false);
    }
  };

  useEffect(() => {
    buscarServicos();
  }, [pagina, linhasPorPagina]);

  const navegarTelaServico = (servico: DadosListagemServico) => {
    navigate(`/servico/cadastro`, { state: servico });
  };

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <ButtonGeneric
          label="Cadastrar serviço"
          onClick={() => navigate(`/servico/cadastro`)}
        />
      </Grid>

      <Grid item xs={12}>
        <TableGeneric
          pageSize={linhasPorPagina}
          colunas={columns}
          linhas={servicos}
          loading={loading}
          totalLinhas={totalLinhas}
          selecionaLinha={false}
          onRowClick={(params) => {
            navegarTelaServico(params.row);
          }}
          formasDeListar={[5, 10]}
          setPaginacao={(model) => {
            setPagina(model.page);
            setLinhasPorPagina(model.pageSize);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ListagemServicoPage;
