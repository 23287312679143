import { useEffect, useState } from "react";
import Container from "../../components/Generics/Container";
import GridCenter from "../../components/Generics/GridCenter";
import TextFieldGeneric from "../../components/Generics/TextFieldGeneric";
import { Alert, Grid } from "@mui/material";
import cepApi from "../../api/cepApi";
import empresaApi from "../../api/empresaApi";
import { useEmpresa } from "../../redux/empresa/empresaHooks";
import { Empresa } from "../../model/entities/Empresa";
import ImageFieldSelector from "../../components/Generics/ImageFieldSelector";
import { useUpdateEffect } from "../../helper";
import ButtonGeneric from "../../components/Generics/ButtonGeneric";
import ModalGeneric from "../../components/Generics/ModalGeneric";
import Titulo2 from "../../components/Generics/Titulo2";
import { useSnack } from "../../redux/snack/snackHooks";

const EmpresaPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [empresa, setEmpresa] = useEmpresa();

  const mostrarSnack = useSnack();
  const [empresaEditar, setEmpresaEditar] = useState<Empresa>(
    empresa ?? new Empresa()
  );

  const [logo, setLogo] = useState<File | null>(null);
  const [logoBase64, setLogoBase64] = useState<string | undefined>(undefined); // Usando undefined inicialmente para controlar melhor a renderização

  useEffect(() => {
    const logoData = empresa?.logo?.toString();
    if (logoData) {
      setLogoBase64(logoData);
    } else {
      setLogoBase64(undefined); // Se não houver logo, definir como undefined para garantir que a renderização seja controlada corretamente
    }
  }, [empresa]);

  useUpdateEffect(() => {
    if (logo) {
      salvar();
    }
  }, [logo]);

  const salvar = async () => {
    try {
      setLoading(true);
      setEmpresa(empresaEditar);

      //em adição de campo novo, sempre altera dentro da api
      const res = await empresaApi.update(1, empresaEditar, logo);
      setEmpresa(res);
      mostrarSnack("Informações atualizadas", "success");
      setLoading(false);
    } catch (erro: any) {
      console.error("Erro ao atualizar informações da empresa", erro);
      mostrarSnack(`Erro ao atualizar informações da empresa.`, "error");
      setLoading(false);
    }
  };

  const buscarCep = async (value: string) => {
    try {
      const buscaCep = await cepApi.getCep(value);
      setEmpresaEditar({
        ...empresaEditar,
        cep: buscaCep.cep,
        bairro: buscaCep.bairro,
        cidade: buscaCep.cidade,
        logradouro: buscaCep.logradouro,
        numeroEndereco: "",
      });
    } catch (erro: any) {
      mostrarSnack(`Não consegui encontrar o CEP ${value}`, "info");
    }
  };

  const handleDeleteLogo = () => {
    setLogo(null);
    setLogoBase64(undefined); // Limpar a representação da imagem quando ela é deletada
  };

  return (
    <GridCenter>
      <Container page>
        {loading && (
          <ModalGeneric open={loading} onClose={() => {}}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Titulo2>Atulizando informações da empresa ...</Titulo2>
              </Grid>
            </Grid>
          </ModalGeneric>
        )}

        <Grid container spacing={2}>
          {empresa &&
            (!empresa.cnpj ||
              empresa.cnpj === "null" ||
              !empresa.nomeResposavel ||
              empresa.nomeResposavel === "null" ||
              !empresa.nomeFantasia ||
              empresa.nomeFantasia === "null") && (
              <Grid item xs={12}>
                <Alert elevation={6} variant="standard" severity="warning">
                  {"Preencha os dados da empresa para continuar"}
                </Alert>
              </Grid>
            )}

          <ImageFieldSelector
            title="Selecionar imagem"
            imagens={logoBase64 ? [`data:image/jpeg;base64,${logoBase64}`] : []} // Passando uma array com a imagem base64 se existir
            onUpload={(_, file) => {
              setLogo(file);
            }}
            onDelete={handleDeleteLogo}
          />

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"Razão Social *"}
              value={empresaEditar.razaoSocial}
              onChange={(value) =>
                setEmpresaEditar({ ...empresaEditar, razaoSocial: value })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"Nome fantasia"}
              value={empresaEditar.nomeFantasia}
              onChange={(value) =>
                setEmpresaEditar({ ...empresaEditar, nomeFantasia: value })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"CNPJ * "}
              placeholder="CNPJ *"
              mask={"99.999.999/9999-99"}
              value={empresaEditar.cnpj}
              onChange={(value) => {
                const texto = value.replace(/\D/g, "");
                setEmpresaEditar({ ...empresaEditar, cnpj: texto });
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              label={"Telefone"}
              value={empresaEditar.telefone}
              onChange={(value) =>
                setEmpresaEditar({ ...empresaEditar, telefone: value })
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              label={"E-mail"}
              value={empresaEditar.email}
              onChange={(value) =>
                setEmpresaEditar({ ...empresaEditar, email: value })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"Nome responsável *"}
              value={empresaEditar.nomeResposavel}
              onChange={(value) =>
                setEmpresaEditar({ ...empresaEditar, nomeResposavel: value })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldGeneric
              label={"Telefone responsável *"}
              value={empresaEditar.telefoneResponsavel}
              onChange={(value) =>
                setEmpresaEditar({
                  ...empresaEditar,
                  telefoneResponsavel: value,
                })
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              label="CEP"
              value={empresaEditar.cep}
              onChange={(value) => {
                setEmpresaEditar({ ...empresaEditar, cep: value });
                if (value.length === 8) {
                  buscarCep(value);
                }
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              label="Rua"
              value={empresaEditar.logradouro}
              onChange={(value) => {
                setEmpresaEditar({
                  ...empresaEditar,
                  logradouro: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              label="N endereço"
              value={empresaEditar.numeroEndereco}
              onChange={(value) => {
                setEmpresaEditar({
                  ...empresaEditar,
                  numeroEndereco: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              label="Bairro"
              value={empresaEditar.bairro}
              onChange={(value) => {
                setEmpresaEditar({
                  ...empresaEditar,
                  bairro: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              label="Cidade"
              value={empresaEditar.cidade}
              onChange={(value) => {
                setEmpresaEditar({
                  ...empresaEditar,
                  cidade: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldGeneric
              label="URL api Whats-App"
              value={empresaEditar.urlApiZap}
              onChange={(value) => {
                setEmpresaEditar({
                  ...empresaEditar,
                  urlApiZap: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <ButtonGeneric label={"Salvar"} onClick={salvar} />
          </Grid>
        </Grid>
      </Container>
    </GridCenter>
  );
};

export default EmpresaPage;
