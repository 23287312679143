import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Typography,
} from "@mui/material";

import TableGeneric from "../../../components/Generics/TableGeneric";
import orcamentoApi, { getStatusOrcamento } from "../../../api/orcamentoApi";
import dayjs from "dayjs";
import { DadosListagemOrcamento } from "../../../model/types/OrcamentoType";
import { formatarDinheiro } from "../../../helper";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import ModalInformacoesOrcamento from "../ModalInformacoesOrcamento";
import { STATUS_ORCAMENTO } from "../../../constants";
import MiniInfo from "../../../components/Generics/MiniInfo";
import InfoTabela from "../../../components/Generics/InfoTabela";
import GridCenter from "../../../components/Generics/GridCenter";
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import ModalGeneric from "../../../components/Generics/ModalGeneric";
import Titulo2 from "../../../components/Generics/Titulo2";
import { useSnack } from "../../../redux/snack/snackHooks";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ModalEnviarOrcamentoZap from "../ModalEnviarOrcamentoZap";

const ListagemOrcamentoPage: React.FC = () => {
  const [orcamentos, setOrcamentos] = useState<DadosListagemOrcamento[]>([]);
  const [totalLinhas, setTotalLinhas] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imprimindo, setImprimindo] = useState(false);
  const [openModalEnviarOrcamento, setOpenModalEnviarOrcamento] =
    useState(false);

  const [pagina, setPagina] = useState(1);
  const [linhasPorPagina, setLinhasPorPagina] = useState(5);
  const [orcamentoSelecionado, setOrcamentoSelecionado] =
    useState<DadosListagemOrcamento | null>(null);

  const mostrarSnack = useSnack();

  useEffect(() => {
    buscarOrcamentos();
  }, [pagina, linhasPorPagina]);

  const buscarOrcamentos = async () => {
    try {
      setLoading(true);
      const res = await orcamentoApi.getMany(pagina - 1, linhasPorPagina);

      setTotalLinhas(res.totalElements);
      setOrcamentos(res.content);
      setLoading(false);
    } catch (error: any) {
      mostrarSnack(
        `Ops, aconteceu um problema ao buscar os orcamentos!`,
        "error"
      );
      console.log("Erro ao buscar orcamentos", error);
      setLoading(false);
    }
  };

  const imprimir = async (idOrcamento: number) => {
    try {
      setImprimindo(true);
      await orcamentoApi.imprimir(idOrcamento);
      setImprimindo(false);
    } catch (erro: any) {
      setImprimindo(false);
      mostrarSnack(`Ops, Não foi possível geral o PDF!`, "error");
      console.log("Erro ao gerar PDF", erro);
    }
  };

  const columns: GridColDef<DadosListagemOrcamento>[] = [
    {
      field: "id",
      headerName: "id",
      width: 30,
      editable: false,
    },

    {
      field: "nomeCliente",
      headerName: "Nome cliente",
      width: 230,
      editable: false,
      renderCell: (params) => {
        return params.row.nomeCliente;
      },
    },
    {
      field: "dataOrcamento",
      headerName: "Data do orçamento",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return dayjs(params.row.dataOrcamento).format("DD/MM/YYYY");
      },
    },

    {
      field: "validadeProposta",
      headerName: "Validade da Proposta",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return dayjs(params.row.validadeProposta).format("DD/MM/YYYY");
      },
    },

    {
      field: "valorOrcamento",
      headerName: "Valor orçamento",
      width: 230,
      editable: false,
      renderCell: (params) => {
        return formatarDinheiro(params.row.valorOrcamento);
      },
    },

    {
      field: "status",
      headerName: "Status orçamento",
      width: 230,
      editable: false,
      renderCell: (params) => {
        const status = params.row.status;
        if (status >= 0)
          if (status === 0) {
            return (
              <MiniInfo color="#d6da01" style={{ fontSize: "14px" }}>
                {STATUS_ORCAMENTO[0].decricao}
              </MiniInfo>
            );
          }
        if (status === 1) {
          return (
            <MiniInfo color="#f74a05" style={{ fontSize: "14px" }}>
              {STATUS_ORCAMENTO[1].decricao}
            </MiniInfo>
          );
        }

        if (status === 2) {
          return (
            <MiniInfo color="#0083c0" style={{ fontSize: "14px" }}>
              {STATUS_ORCAMENTO[2].decricao}
            </MiniInfo>
          );
        }

        if (status === 3) {
          return (
            <MiniInfo color="#46c000" style={{ fontSize: "14px" }}>
              {STATUS_ORCAMENTO[3].decricao}
            </MiniInfo>
          );
        }
      },
    },

    {
      field: "acoes",
      headerName: "Ações",
      width: 230,
      editable: false,
      renderCell: (params) => {
        const orcamento = params.row;
        return (
          <>
            <IconButton onClick={(e) => e.stopPropagation()}>
              <WhatsAppIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setOrcamentoSelecionado(orcamento);
                  setOpenModalEnviarOrcamento(true);
                }}
              />
            </IconButton>

            <IconButton>
              <PrintIcon
                onClick={(e) => {
                  e.stopPropagation();
                  imprimir(orcamento.id);
                }}
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sx={{ display: { xs: "none", md: "flex" } }}>
        <InfoTabela mensagem="Para detalhes do orçamento clique no mesmo" />
      </Grid>

      {imprimindo && (
        <ModalGeneric open={imprimindo} onClose={() => {}}>
          <Grid item style={{ textAlign: "center" }}>
            <Titulo2>Gerando PDF . . .</Titulo2>
          </Grid>
          <LinearProgress />
        </ModalGeneric>
      )}
      <Grid item xs={12}>
        <TableGeneric
          sx={{ display: { xs: "none", md: "flex" } }}
          colunas={columns}
          linhas={orcamentos}
          loading={loading}
          getRowId={(value) => value.id}
          totalLinhas={totalLinhas}
          selecionaLinha={false}
          pageSize={5}
          formasDeListar={[5]}
          setPaginacao={(model) => {
            setPagina(model.page + 1);
            setLinhasPorPagina(model.pageSize);
          }}
          onRowClick={(value: GridRowParams<DadosListagemOrcamento>) => {
            setOrcamentoSelecionado(value.row);
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: { xs: "flex", md: "none" } }}
        style={{ flexDirection: "column" }}
      >
        {orcamentos.map((orcamento) => (
          <GridCenter>
            <Paper
              style={{
                margin: "12px 0px",
                padding: "12px",
                width: "100%",
              }}
              key={orcamento.id}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {getStatusOrcamento(orcamento)}
                </Grid>

                <Grid item xs={23}>
                  <Typography fontWeight={"bold"}>
                    Data do orçamento:{" "}
                    {dayjs(orcamento.dataOrcamento).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography fontWeight={"bold"}>
                    Validade :{" "}
                    {dayjs(orcamento.validadeProposta).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Typography>{orcamento.nomeCliente}</Typography>

                  <Typography>
                    R${formatarDinheiro(orcamento.valorOrcamento)}
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <IconButton>
                    <EditIcon
                      onClick={() => {
                        setOrcamentoSelecionado(orcamento);
                      }}
                    />
                  </IconButton>

                  <IconButton>
                    <PrintIcon
                      onClick={() => {
                        orcamentoApi.imprimir(orcamento.id);
                      }}
                    />
                  </IconButton>

                  <IconButton>
                    <WhatsAppIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setOrcamentoSelecionado(orcamento);
                        setOpenModalEnviarOrcamento(true);
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          </GridCenter>
        ))}

        <GridCenter>
          <Pagination
            size={"large"}
            defaultPage={1}
            count={totalLinhas}
            onChange={(event, number) => setPagina(number)}
            page={1}
          />
        </GridCenter>
      </Grid>

      {!openModalEnviarOrcamento && orcamentoSelecionado && (
        <ModalInformacoesOrcamento
          open={Boolean(!openModalEnviarOrcamento && orcamentoSelecionado)}
          onClose={() => {
            setOrcamentoSelecionado(null);
            buscarOrcamentos();
          }}
          dadosOrcamento={orcamentoSelecionado}
        />
      )}

      {openModalEnviarOrcamento && orcamentoSelecionado && (
        <ModalEnviarOrcamentoZap
          dadosOrcamento={orcamentoSelecionado}
          open={Boolean(openModalEnviarOrcamento)}
          onClose={() => {
            setOrcamentoSelecionado(null);
            setOpenModalEnviarOrcamento(false);
            buscarOrcamentos();
          }}
        />
      )}
    </Grid>
  );
};

export default ListagemOrcamentoPage;
