import {
  DadosEnviarMensagemZap,
  DadosEnviarOrcamentoZap,
} from "../model/types/zapTypes";
import api from "./api";

const ROUTE = "zap";

class zapApi {
  async getStatus() {
    try {
      const res = await api.get<boolean>(`/${ROUTE}/getStatus`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async generateQrCode() {
    try {
      const res = await api.get<string>(`/${ROUTE}/generateQrCode`);
      const data = res.data;

      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  // async sendMessage(dados: DadosEnviarMensagemZap) {
  //   try {
  //     const res = await api.post<string>(`/${ROUTE}/sendMessage`);
  //     const data = res.data;

  //     return data;
  //   } catch (error: any) {
  //     console.log(error);
  //     throw error;
  //   }
  // }

  async sendMessage(parametros: DadosEnviarOrcamentoZap, file: File | null) {
    try {
      const dados = new FormData();
      // dados.append("id", String(empresa.id || "")); // id é opcional, então trate como vazio se não houver
      dados.append("countryCode", parametros.countryCode);
      dados.append("number", parametros.number);
      dados.append("ddd", parametros.ddd);
      dados.append("message", parametros.message);

      if (file) {
        dados.append("file", file);
      }

      const res = await api.post<string>(`/${ROUTE}/sendMessage`, dados);

      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  async enviarOrcamento(parametros: DadosEnviarOrcamentoZap) {
    try {
      const res = await api.post<boolean>(
        `/${ROUTE}/enviarOrcamento`,
        parametros
      );

      const data = res.data;
      return data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }
}

export default new zapApi();
