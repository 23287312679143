import { NumericKeys } from "react-hook-form/dist/types/path/common";
import { Servico } from "../entities/Servico";

export type DadosServicoOS = {
  idOrdemServico: number;
  idOsServico: number;
  idServico: number;
  nomeServico: string;
  valorServico: number;
  observacao: string;
  dataInicio: Date;
  dataEntrega: Date;
  idResponsavel: number;
  nomeResponsavel: string;
  status: number;
  valorPago: number;
  pagamentoServico: number;
  dataTempoInicial: string;
  dataTempoPausado: string;
  tempoDecorrido: number;
  pausado: boolean;
  filhos: ServicoOsFilho[];
  idOsServicoFilho: number;
};

export class FiltroBuscaServicoByUsuario {
  dataInicioEntegra!: Date;
  dataFimEntegra!: Date;
  idServico!: number;
  idStatusServico!: number;
  idUsuario!: number;
  pagina!: number;
  linhasPorPagina!: number;
  jaInciados?: boolean;
  apenasNaoTerminados?: boolean;
}
export class ServicoOsFilho {
  id!: number;
  servicoOsFilhoId!: number;
  servicoPaiId!: number;
  servicoFilhoId!: number;

  servicoPai!: Servico;
  servicoFilho!: Servico;

  observacao!: string;
  dataInicio!: Date;
  dataEntrega!: Date;
  idResponsavel!: number;
  nomeResponsavel!: string;
  status!: number;
  tituloServicoFilho!: string;

  dataTempoInicial: string = "";
  dataTempoPausado: string = "";
  tempoDecorrido: number = 0;
  pausado: boolean = true;
}
